import { createApiMethodsSet } from './createApiMethodsSet';
import { httpService } from './httpService';

const api = createApiMethodsSet();

api.getByProcedureId = async ({ procedureId }) =>
  httpService({
    url: `/patient/note/get-by-procedure-id/${procedureId}`,
    options: {
      method: 'GET',
    },
  });

api.create = async ({ creatorId, description, procedureId }) =>
  httpService({
    url: '/patient/note/create',
    options: {
      method: 'POST',
      data: { creatorId, description, procedureId },
    },
  });

export default api;
