import styled from '@emotion/styled';
import { Toolbar as ToolbarMUI } from '@mui/material';

export const Toolbar = styled(ToolbarMUI)`
  font-size: 18px;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Version = styled.span`
  color: gray;
`;
