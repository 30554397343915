/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Button, TextField, Box } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import * as UI from 'UI';
import {
  SideLabelEnum,
  FileTypeEnum,
  ProcedureEnum,
  ProcedureStatusEnum,
  RoleEnum,
} from 'models/constants';
import { required } from 'models/validations';
import { useSurgery } from 'components/Procedure/Surgery/useSurgery';
import { ListOfFiles } from '../../ListOfFiles/ListOfFiles';

export const MasterAdminInfo = ({ hookData }) => {
  const { handleSurgeonStatusChange } = useSurgery({ hookData });
  const { push } = useHistory();
  const {
    id,
    surgeonListOptions,
    locationListOptions,
    activeRadiologyLocationListOptions,
    reloadProcedureFiles,
    watch,
    control,
    isUpdating,
    dispatchGlobalState,
    // getRadiologyListOptionsByLocationId,
    getUserNameById,
    row,
    handleUpdateUserIdOnUpload,
    isNorCurrenSurgeon,
    getAllowedStatusListOptions,
    globalState,
  } = hookData;

  const disabled = isUpdating || row?.value?.deleted;

  return (
    <Stack direction="column" width="100%" spacing={4}>
      <Stack direction="column" width="100%" spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          spacing={2}
        >
          <Box style={{ width: '200px' }}>
            <UI.FormStatusSelector
              label="Status"
              name="status"
              control={control}
              rules={{ required }}
              options={getAllowedStatusListOptions(ProcedureStatusEnum)}
              disabled={isUpdating || isNorCurrenSurgeon || watch()?.deleted}
              style={{ width: '200px' }}
              handleChange={e =>
                handleSurgeonStatusChange(
                  e,
                  watch,
                  RoleEnum.MASTER_ADMIN === globalState.user.value.role
                )
              }
            />
          </Box>
          <TextField
            label="Created"
            size="small"
            InputProps={{
              value: watch()?.creation ?? '',
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            disabled
          />
        </Stack>
        <Stack direction="row" width="100%" spacing={2}>
          <UI.FormSelectField
            label="Procedure"
            name="name"
            control={control}
            rules={{ required }}
            options={ProcedureEnum}
            disabled={disabled}
          />
          <Box style={{ width: '100px' }}>
            <UI.FormSelectField
              label="Side"
              name="sideSurgeon"
              control={control}
              rules={{ required }}
              options={SideLabelEnum}
              disabled={disabled}
              style={{ width: '100px' }}
            />
          </Box>
        </Stack>
        <Stack direction="row" width="100%" spacing={2}>
          <TextField
            size="small"
            label="Surgery Location"
            readOnly
            value={locationListOptions[watch()?.surgeryLocationId]?.name ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ flex: '1 1 auto' }}
            variant="filled"
            disabled
          />
          <TextField
            size="small"
            label="Surgeon"
            readOnly
            value={getUserNameById(watch()?.surgeonId) ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ flex: '1 1 auto' }}
            variant="filled"
            disabled
          />
          {/* <Button
            variant="outlined"
            title="Send message to Surgeon"
            onClick={() => {
              dispatchGlobalState({
                type: 'SET_newMessageReceiverId',
                payload: watch()?.surgeonId ?? '',
              });
              dispatchGlobalState({
                type: 'SET_newMessageRole',
                payload: RoleEnum.SURGEON,
              });
              dispatchGlobalState({
                type: 'SET_newMessageLocationId',
                payload: watch()?.surgeryLocationId ?? '',
              });
              dispatchGlobalState({
                type: 'SET_newMessageProcedureId',
                payload: watch()?.id ?? '',
              });
              push('/new_message');
            }}
            type="button"
          >
            <MailOutlineIcon />
          </Button> */}
        </Stack>
      </Stack>
      <UI.CtProtocolInfo />
      <Stack direction="column" width="100%" spacing={2}>
        <Stack direction="row" width="100%" spacing={2}>
          <UI.FormSelectField
            label="Radiology Location"
            name="radiologyLocationId"
            control={control}
            options={activeRadiologyLocationListOptions}
            disabled={disabled}
          />
          {/* <UI.FormSelectField
            label="Radiologist"
            name="radiologistId"
            control={control}
            options={getRadiologyListOptionsByLocationId(
              watch()?.radiologyLocationId
            )}
            disabled={isUpdating}
          /> */}
          {/* <Button
            variant="outlined"
            title="Send message to Radiologist"
            onClick={() => {
              dispatchGlobalState({
                type: 'SET_newMessageReceiverId',
                payload: watch()?.radiologistId ?? '',
              });
              dispatchGlobalState({
                type: 'SET_newMessageLocationId',
                payload: watch()?.radiologyLocationId ?? '',
              });
              dispatchGlobalState({
                type: 'SET_newMessageRole',
                payload: RoleEnum.RADIOLOGIST,
              });
              dispatchGlobalState({
                type: 'SET_newMessageProcedureId',
                payload: watch()?.id ?? '',
              });
              push('/new_message');
            }}
            type="button"
          >
            <MailOutlineIcon />
          </Button> */}
        </Stack>
        <ListOfFiles
          handleReload={reloadProcedureFiles}
          fileType={FileTypeEnum.SCAN}
          procedureId={id}
          procedure={row?.value}
          readOnly={watch()?.deleted}
          handleUpdateUserIdOnUpload={handleUpdateUserIdOnUpload}
        />
      </Stack>
    </Stack>
  );
};
