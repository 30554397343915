import styled from '@emotion/styled';
import * as SC from '../../SC/App.sc';

export const Container = styled(SC.FlexColumn)`
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1000;
  cursor: wait;
`;
