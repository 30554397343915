import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Button, TextField } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  ProcedureEnum,
  SideLabelEnum,
  FileTypeEnum,
  RoleEnum,
} from 'models/constants';
import * as UI from 'UI';
import * as FP from 'utils/fp-js';
import { required } from 'models/validations';
import { ListOfFiles } from '../ListOfFiles/ListOfFiles';
import { RadiologistInfo } from './RadiologistInfo/RadiologistInfo';
import { EngineerInfo } from './EngineerInfo/EngineerInfo';
import { MasterAdminInfo } from './MasterAdminInfo/MasterAdminInfo';
import { SurgeonInfo } from './SurgeonInfo/SurgeonInfo';

export const Information = ({ hookData, userRole }) => {
  const { push } = useHistory();
  const {
    isUpdating,
    id,
    control,
    getUserNameById,
    activeRadiologyLocationListOptions,
    row,
    reloadProcedureFiles,
    watch,
    dispatchGlobalState,
    isNotCurrentRadiologist,
    handleUpdateUserIdOnUpload,
  } = hookData;

  return FP.match(userRole)
    .on(RoleEnum.RADIOLOGIST, <RadiologistInfo hookData={hookData} />)
    .on(
      [RoleEnum.MASTER_ADMIN, RoleEnum.ADMIN],
      <MasterAdminInfo hookData={hookData} />
    )
    .on(RoleEnum.IMAGE_ENGINEER, <EngineerInfo hookData={hookData} />)
    .on(
      [RoleEnum.SURGEON, RoleEnum.SURGICAL_ASSISTANT],
      <SurgeonInfo hookData={hookData} />
    )
    .otherwise(
      <Stack direction="column" width="100%" spacing={4}>
        <Stack direction="column" width="100%" spacing={2}>
          <Stack direction="row" width="100%" spacing={2}>
            <UI.FormSelectField
              label="Procedure"
              name="name"
              control={control}
              rules={{ required }}
              options={ProcedureEnum}
              disabled={isUpdating}
            />
            <div style={{ width: '100px' }}>
              <UI.FormSelectField
                label="Side"
                name="sideSurgeon"
                control={control}
                rules={{ required }}
                options={SideLabelEnum}
                disabled={isUpdating}
                style={{ width: '100px' }}
              />
            </div>
            <div style={{ width: '130px' }}>
              <TextField
                label="Created"
                size="small"
                InputProps={{
                  value: watch()?.creation ?? '',
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                style={{ width: '130px' }}
              />
            </div>
          </Stack>
        </Stack>
        <Stack direction="column" width="100%" spacing={2}>
          <Stack direction="row" width="100%" spacing={2}>
            <UI.FormSelectField
              label="Radiology Location"
              name="radiologyLocationId"
              control={control}
              options={activeRadiologyLocationListOptions}
              disabled={isUpdating}
            />
            {watch()?.radiologistId && (
              <>
                <TextField
                  size="small"
                  label="Radiologist"
                  readOnly
                  value={getUserNameById(watch()?.radiologistId)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ flex: '1 1 auto' }}
                />
                <Button
                  variant="outlined"
                  title="Send message to Radiologist"
                  onClick={() => {
                    dispatchGlobalState({
                      type: 'SET_newMessageReceiverId',
                      payload: watch()?.radiologistId ?? '',
                    });
                    dispatchGlobalState({
                      type: 'SET_newMessageLocationId',
                      payload: watch()?.radiologyLocationId ?? '',
                    });
                    dispatchGlobalState({
                      type: 'SET_newMessageRole',
                      payload: RoleEnum.RADIOLOGIST,
                    });
                    dispatchGlobalState({
                      type: 'SET_newMessageProcedureId',
                      payload: watch()?.id ?? '',
                    });
                    push('/new_message');
                  }}
                  type="button"
                >
                  <MailOutlineIcon />
                </Button>
              </>
            )}
          </Stack>
          <ListOfFiles
            procedure={row?.value}
            handleReload={reloadProcedureFiles}
            fileType={FileTypeEnum.SCAN}
            procedureId={id}
            readOnly={isNotCurrentRadiologist}
            showOnlyLastDate
            isUpdating={isUpdating}
            handleUpdateUserIdOnUpload={handleUpdateUserIdOnUpload}
          />
        </Stack>
      </Stack>
    );
};
