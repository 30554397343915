import * as FP from 'utils/fp-js';

import { LogActionType, ProcedureStatusLabelEnum } from 'models/constants';

export const getValueByActionType = ({
  type,
  getLocationById,
  getUserNameById,
  locationsData,
}) =>
  FP.match(type)
    .on(LogActionType.PROCEDURE_UPDATED_STATUS, () => value => {
      return ProcedureStatusLabelEnum[value];
    })
    .on(
      [
        LogActionType.PROCEDURE_UPDATED_SURGERY_LOCATION,
        LogActionType.PROCEDURE_UPDATED_RADIOLOGY_LOCATION,
      ],
      () => value => getLocationById(locationsData, value)
    )
    .on(
      [
        LogActionType.PROCEDURE_UPDATED_ENGINEER,
        LogActionType.PROCEDURE_UPDATED_SURGEON,
        LogActionType.PROCEDURE_UPDATED_RADIOLOGIST,
      ],
      () => value => getUserNameById(value)
    )
    .otherwise(() => value => value);
