/* eslint-disable no-unused-vars */
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import * as UI from 'UI';
import * as SC from './AdminLog.sc';
import { useAdminLog } from './useAdminLog';

export const AdminLog = ({ error }) => {
  const {
    isUpdating,
    search,
    handleSearchCancel,
    handleSearchChange,
    sortModel,
    setSortModel,
    rows,
    columns,
    period,
    setPeriodAndSave,
  } = useAdminLog();

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 40px)" />
      <SC.Header>
        <h3>System Events Log</h3>
        <UI.GridSearchField
          search={search}
          handleSearchChange={handleSearchChange}
          handleSearchCancel={handleSearchCancel}
        />
      </SC.Header>
      <SC.Content elevation={3}>
        <DataGrid
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          rows={rows}
          columns={columns}
          // pageSize={25}
          rowsPerPageOptions={[25, 50, 100]}
          disableSelectionOnClick
          density="compact"
          // onSelectionModelChange={handleSelectionChange}
          disableColumnFilter
          disableColumnMenu
          className={SC.DataGridClass}
          loading={isUpdating}
          components={{
            Footer: () => (
              <UI.FooterWithPeriodSelector
                period={period}
                setPeriod={setPeriodAndSave}
                itemsName="Show action events"
              />
            ),
          }}
        />
      </SC.Content>
    </SC.Container>
  );
};
