/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useApi } from 'hooks/useApi';
/* TODO - select faster way of the file storage */
// import filesApi from 'api/DBFiles';
import procedureApi from 'api/procedure';
import { useUsersList } from 'hooks/useUsersList';
import { useAssignedSurgeons } from 'hooks/useAssignedSurgeons';
import { recalcAndSetAge } from 'utils/recalcAndSetAge';
import { getPermissionToProcedureAccess } from 'utils/getPermissionToProcedureAccess';
import { useLocationList } from 'hooks/useLocationList';
import { encodeIdData, decodeIdData } from 'utils/handleIdData';
import { encodeNumberData, decodeNumberData } from 'utils/handleNumberData';
import { convertToLocaleDate } from 'utils/dateTimeUtils';
import { getRecordById } from 'utils/getRecordById';
import { useSendNotification } from 'hooks/useSendNotification';
import * as FP from 'utils/fp-js';
import { getValueByActionType } from 'utils/getValueByActionType';

import { useConfirmExit } from 'hooks/useConfirmExit';
import {
  RoleEnum,
  RoleLabelEnum,
  ProcedureStatusLabelEnum,
  ProcedureStatusEnum,
  LogActionLabelType,
} from 'models/constants';

export const useProcedure = () => {
  const { confirmExit } = useConfirmExit();
  const {
    customData,
    handleCustomApiRequest,
    row,
    setRow,
    handleGetDataById,
    handleSaveData,
    isUpdating,
    setIsUpdating,
    error,
    showError,
    globalState,
    dispatchGlobalState,
  } = useApi(procedureApi, false);
  const { user, users } = globalState;
  const { assignedSurgeons, reloadAssignedSurgeons } = useAssignedSurgeons();
  const { sendNotification } = useSendNotification();
  const {
    getSurgeonListOptionsByLocationId,
    getRadiologyListOptionsByLocationId,
    engineerListOptions,
    surgeonListOptions,
    radiologyListOptions,
    getUserNameById,
  } = useUsersList(users);

  const {
    locationsData,
    locationListOptions,
    surgeryLocationListOptions,
    radiologyLocationListOptions,
    activeRadiologyLocationListOptions,
    getLocationById,
  } = useLocationList();

  const columns = [
    {
      field: 'time',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Time',
      flex: 1,
      minWidth: 170,
    },
    {
      field: 'user',
      headerName: 'User Name',
      sortable: true,
      minWidth: 100,
      maxWidth: 180,
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Role',
      sortable: true,
      minWidth: 80,
      maxWidth: 130,
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Event Action',
      sortable: true,
      minWidth: 260,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'oldValue',
      headerName: 'Old Value',
      sortable: true,
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'newValue',
      headerName: 'New Value',
      sortable: true,
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
  ];

  const filterRow = ({ type, oldValue, newValue }) => {
    if (type.includes('location')) {
      if (!oldValue && newValue === '') return false;
    }
    return oldValue !== newValue;
  };

  const mapRow = row => {
    const creationDate = new Date(row?.creation);

    const getValue = value =>
      getValueByActionType({
        type: row?.type,
        locationsData,
        getLocationById,
        getUserNameById,
      })(value);

    const oldValue = getValue(row?.oldValue);
    const newValue = getValue(row?.newValue);

    return {
      ...row,
      time: `${creationDate.toLocaleDateString(
        'en-US'
      )} ${creationDate.toLocaleTimeString('en-US')}`,
      user: `${row?.initiator?.nameFirst} ${row?.initiator?.nameSecond}`,
      role: RoleLabelEnum[row?.initiator?.role],
      type: LogActionLabelType[row?.type] ?? row?.type,
      newValue: getValue(row?.newValue),
      oldValue: getValue(row?.oldValue),
      creation: row?.creation ?? 0,
    };
  };

  const { id } = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    values,
    getValues,
    trigger,
    setValue,
    reset,
    watch,
    formState,
  } = useForm();

  const isNotCurrentEngineer =
    globalState.user.value.role !== RoleEnum.IMAGE_ENGINEER ||
    (!!watch()?.engineerId &&
      globalState.user.value.role === RoleEnum.IMAGE_ENGINEER &&
      globalState.user.value.userId !== watch()?.engineerId);

  const handleCancelChanges = () => {
    reset();
  };

  const isNotCurrentRadiologist =
    globalState.user.value.role !== RoleEnum.RADIOLOGIST ||
    (!!watch()?.radiologistId &&
      globalState.user.value.role === RoleEnum.RADIOLOGIST &&
      globalState.user.value.userId !== watch()?.radiologistId);

  const isNotCurrentSurgeon =
    globalState.user.value.role !== RoleEnum.SURGEON ||
    (!!watch()?.surgeonId &&
      globalState.user.value.role === RoleEnum.SURGEON &&
      globalState.user.value.userId !== watch()?.surgeonId);

  const reloadProcedureHistory = () => {
    handleCustomApiRequest({
      requestName: 'getHistoryById',
      requestData: id,
    });
  };

  const reloadProcedureFiles = async () => {
    if (id) {
      handleGetDataById(id);
      reloadProcedureHistory();
    }
  };

  const onSubmit = async ({
    id,
    status,
    name,
    dateOfSurgery,
    surgeonId,
    surgeryLocationId,
    radiologistId,
    radiologyLocationId,
    engineerId,
    anatomicAnteVersion,
    anatomicInclination,
    size,
    position,
    plannedAnteVersion,
    plannedInclination,
    operativeAnteVersion,
    operativeInclination,
    legLengthDifference,
    femoralOffsetDifference,
    anterior,
    superior,
    posterior,
    sideSurgeon,
    sideEngineer,
  }) => {
    const preparedData = {
      id,
      dateOfSurgery,
      name,
      surgeonId: encodeIdData(surgeonId),
      surgeryLocationId: encodeIdData(surgeryLocationId),
      radiologistId: encodeIdData(radiologistId),
      radiologyLocationId: encodeIdData(radiologyLocationId),
      engineerId: encodeIdData(engineerId),
      status,
      anatomicAnteVersion: encodeNumberData(anatomicAnteVersion),
      anatomicInclination: encodeNumberData(anatomicInclination),
      size: encodeNumberData(size),
      position,
      plannedAnteVersion: encodeNumberData(plannedAnteVersion),
      plannedInclination: encodeNumberData(plannedInclination),
      operativeAnteVersion: encodeNumberData(operativeAnteVersion),
      operativeInclination: encodeNumberData(operativeInclination),
      legLengthDifference: encodeNumberData(legLengthDifference) ?? 0,
      femoralOffsetDifference: encodeNumberData(femoralOffsetDifference) ?? 0,
      anterior: encodeNumberData(anterior),
      superior: encodeNumberData(superior),
      posterior: encodeNumberData(posterior),
      sideSurgeon,
      sideEngineer,
    };

    FP.match(true)
      .on(
        user?.value?.role === RoleEnum.IMAGE_ENGINEER &&
          status === ProcedureStatusEnum.MODEL_IN_WORK,
        () => {
          preparedData.engineerId = user.value.userId;
        }
      )
      .on(
        user?.value?.role === RoleEnum.IMAGE_ENGINEER &&
          status === ProcedureStatusEnum.SCAN_READY,
        () => {
          // reset id to the undefined
          preparedData.engineerId = encodeIdData();
        }
      )
      .otherwise();

    const result = await handleSaveData(preparedData);

    if (result) {
      sendNotification({
        formState,
        watch,
      });
      reloadProcedureHistory();
    }
  };

  const handleUpdateUserIdOnUpload = () => {
    // Additional update for storing the id of user that uploads files
    const currentData = watch();

    /* TODO - add update user id on upload for Admins -ARS-120 */
    if (
      user?.value?.role === RoleEnum.MASTER_ADMIN ||
      user?.value?.role === RoleEnum.ADMIN
    )
      return;

    const preparedData = {
      ...currentData,
      radiologistId: encodeIdData(currentData?.radiologistId),
      radiologyLocationId: getRecordById(user?.value?.userId, users?.value)
        ?.location?.id,
      engineerId: encodeIdData(currentData?.engineerId),
    };

    FP.match(user?.value?.role)
      .on(RoleEnum.RADIOLOGIST, () => {
        preparedData.radiologistId = user?.value?.userId;
      })
      .on(RoleEnum.IMAGE_ENGINEER, () => {
        preparedData.engineerId = user.value.userId;
      })
      // .on(RoleEnum.MASTER_ADMIN)
      // .on(RoleEnum.ADMIN)
      .otherwise();

    handleSaveData(preparedData);
  };

  const handleReload = () => {
    reloadAssignedSurgeons();
    handleGetDataById(id);
    reloadProcedureHistory();
  };

  const getAllowedStatusListOptions = statusEnum =>
    Object.keys(statusEnum).reduce((acc, next) => {
      acc[next] = ProcedureStatusLabelEnum[next];
      return acc;
    }, {});

  useEffect(async () => {
    if (row?.value?.id) {
      setRow({
        value: {},
      });
    }
    reloadAssignedSurgeons();
    handleGetDataById(id);
    reloadProcedureHistory();
  }, []);

  useEffect(() => {
    if (!Array.isArray(locationsData?.value)) {
      return;
    }

    const currentRow = row?.value;

    if (currentRow) {
      const {
        id,
        creation,
        status,
        name,
        dateOfSurgery,
        surgeonId,
        surgeryLocationId,
        radiologistId,
        radiologyLocationId,
        engineerId,
        anatomicAnteVersion,
        anatomicInclination,
        size,
        position,
        plannedAnteVersion,
        plannedInclination,
        operativeAnteVersion,
        operativeInclination,
        legLengthDifference,
        femoralNeckAnteVersion,
        femoralOffsetDifference,
        deleted,
        anterior,
        superior,
        posterior,
        sideSurgeon,
        sideEngineer,
      } = currentRow;

      const formDataValues = {
        id,
        creation: creation ? convertToLocaleDate(new Date(creation)) : '',
        status,
        name,
        dateOfSurgery: dateOfSurgery ? new Date(dateOfSurgery) : '',
        surgeonId: decodeIdData(surgeonId),
        surgeryLocationId: decodeIdData(surgeryLocationId),
        radiologistId: decodeIdData(radiologistId),
        radiologyLocationId: decodeIdData(radiologyLocationId),
        engineerId: decodeIdData(engineerId),
        anatomicAnteVersion: decodeNumberData(anatomicAnteVersion),
        anatomicInclination: decodeNumberData(anatomicInclination),
        size: decodeNumberData(size),
        position,
        plannedAnteVersion: decodeNumberData(plannedAnteVersion),
        plannedInclination: decodeNumberData(plannedInclination),
        operativeAnteVersion: decodeNumberData(operativeAnteVersion),
        operativeInclination: decodeNumberData(operativeInclination),
        legLengthDifference: decodeNumberData(legLengthDifference),
        femoralNeckAnteVersion: femoralNeckAnteVersion ?? '--',
        femoralOffsetDifference: decodeNumberData(femoralOffsetDifference),
        deleted,
        anterior: decodeNumberData(anterior),
        superior: decodeNumberData(superior),
        posterior: decodeNumberData(posterior),
        sideSurgeon: sideSurgeon ?? '',
        sideEngineer: sideEngineer ?? '',
      };
      reset(formDataValues);
    }
  }, [row, locationsData]);

  useEffect(() => {
    document.title = `Procedure #${id}`;

    if (isDirty !== globalState.isDataChanged) {
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: isDirty,
      });
    }
  });

  return {
    handleSubmit,
    onSubmit,
    errors,
    control,
    reset,
    trigger,
    isDirty,
    isValid,
    isUpdating,
    setIsUpdating,
    error,
    showError,
    values,
    getValues,
    setValue,
    watch,
    row,
    handleGetDataById,
    reloadProcedureFiles,
    handleReload,
    getSurgeonListOptionsByLocationId,
    getRadiologyListOptionsByLocationId,
    getUserNameById,
    surgeonListOptions,
    radiologyListOptions,
    engineerListOptions,
    locationListOptions,
    surgeryLocationListOptions,
    radiologyLocationListOptions,
    activeRadiologyLocationListOptions,
    recalcAge: date => recalcAndSetAge(date),
    id,
    handleCancelChanges,
    pathForReturn: `/patient/${row?.value?.patient?.id ?? ''}`,
    confirmExit,
    globalState,
    dispatchGlobalState,
    isNotCurrentEngineer,
    isNotCurrentRadiologist,
    isNotCurrentSurgeon,
    handleUpdateUserIdOnUpload,
    accessAllowed: getPermissionToProcedureAccess({
      userRole: globalState.user?.value?.role,
      procedureStatus: watch()?.status,
    }),
    getAllowedStatusListOptions,
    columns,
    rows: customData?.value
      ? customData?.value.map(mapRow).filter(filterRow)
      : [],
    assignedSurgeons,
    reloadAssignedSurgeons,
  };
};
