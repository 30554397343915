import { createApiMethodsSet } from './createApiMethodsSet';
import { httpService } from './httpService';

const api = createApiMethodsSet();

api.getAllAfterUnixDate = async ({ after }) => {
  return httpService({
    url: `/patient/procedure/all-deleted/?after=${after}`,
    options: {
      method: 'GET',
    },
  });
};

export default api;
