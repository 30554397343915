/* eslint-disable no-console */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Button, TextField, Box } from '@mui/material';
import * as UI from 'UI';
import { GenderLabelEnum } from 'models/constants';
import { useConfirmAction } from 'hooks/useConfirmAction';
import {
  medicalInsuranceIdValidation,
  firstNameValidation,
  lastNameValidation,
  dateOfBirthValidation,
  genderValidation,
  // patientEmailValidation,
  patientphoneNumberValidation,
  required,
} from 'models/validations';
import * as SC from './PatientCreator.sc';
import { usePatientCreator } from './usePatientCreator';

export const PatientCreator = ({
  returnToPreviousScreen,
  setNewProcedureId,
}) => {
  const { confirmAction } = useConfirmAction();
  const { goBack } = useHistory();
  const {
    handleSubmit,
    onSubmit,
    isUpdating,
    error,
    isDirty,
    trigger,
    reset,
    control,
    age,
    recalcAge,
    watch,
    surgeonListOptions,
  } = usePatientCreator({ returnToPreviousScreen, setNewProcedureId });

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 32px)" />
      <SC.Header>
        <h3>Create New Patient</h3>
      </SC.Header>
      {isUpdating && <UI.GlobalSpinner />}
      {!isUpdating && (
        <SC.Wrapper>
          <SC.Form>
            <SC.Paper elevation={3}>
              <Stack direction="column" width="100%" spacing={5}>
                <Stack direction="row" width="100%" spacing={3}>
                  <UI.FormTextField
                    label="First Name"
                    name="nameFirst"
                    control={control}
                    rules={firstNameValidation}
                  />
                  <UI.FormTextField
                    label="Last Name"
                    name="nameSecond"
                    control={control}
                    rules={lastNameValidation}
                  />
                </Stack>
                <Stack direction="row" width="100%" spacing={3}>
                  <Box style={{ width: '120px' }}>
                    <UI.FormSelectField
                      label="Gender"
                      name="gender"
                      control={control}
                      rules={genderValidation}
                      options={GenderLabelEnum}
                      className={SC.GenderClass}
                      style={{ width: '120px' }}
                    />
                  </Box>
                  <div className={SC.DateOfBirthClass}>
                    <UI.FormDateSelector
                      label="Date of Birth"
                      name="dateOfBirth"
                      control={control}
                      rules={dateOfBirthValidation}
                      className={SC.DateOfBirthClass}
                      onSelect={recalcAge}
                    />
                  </div>
                  <TextField size="small" label="Age" value={age} disabled />
                </Stack>
                <UI.FormTextField
                  label="Medical Record Number"
                  name="medicalInsuranceId"
                  control={control}
                  rules={medicalInsuranceIdValidation}
                />
                {/* <UI.FormTextField
                  label="E-mail"
                  name="email"
                  control={control}
                  rules={patientEmailValidation}
                /> */}
                <UI.FormPhoneNumber
                  label="Phone Number"
                  name="phoneNumber"
                  control={control}
                  rules={patientphoneNumberValidation}
                />
                <UI.FormSelectField
                  label="Assigned Surgeon"
                  name="surgeonId"
                  control={control}
                  options={surgeonListOptions}
                  disabled={isUpdating || watch()?.deleted}
                  rules={{ required }}
                />
              </Stack>
            </SC.Paper>
            <SC.Buttons>
              <Button
                onClick={isDirty ? () => reset() : goBack}
                type="button"
                variant="contained"
                color="primary"
              >
                {isDirty ? 'Cancel' : 'Back to previous page'}
              </Button>
              &nbsp;
              <Button
                disabled={!isDirty}
                type="button"
                variant="contained"
                color="secondary"
                onClick={async () => {
                  const isValid = await trigger();

                  if (isValid) {
                    confirmAction({
                      text: 'Do you confirm creation?',
                      callbackFn: handleSubmit(onSubmit),
                    });
                  }
                }}
              >
                Create New Patient
              </Button>
            </SC.Buttons>
          </SC.Form>
        </SC.Wrapper>
      )}
    </SC.Container>
  );
};
