import styled from '@emotion/styled';
import { FlexRow as FlexRowCSC } from 'SC/App.sc';

export const FlexRow = FlexRowCSC;

export const FooterContainer = styled(FlexRowCSC)`
  padding-left: 20px;
  justify-content: space-between;
  align-items: center;
`;
