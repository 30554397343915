import React from 'react';
import { GridFooter } from '@mui/x-data-grid';
import { timePeriodsEnum, timePeriodsLabelEnum } from 'models/constants';
import { StatusButton } from '../StatusButton/StatusButton';
import * as SC from './FooterWithPeriodSelector.sc';

export const FooterWithPeriodSelector = ({ period, setPeriod, itemsName }) => (
  <SC.FooterContainer>
    <SC.FlexRow>
      {itemsName} for&nbsp;
      {Object.values(timePeriodsEnum).map(value => (
        <StatusButton
          key={value}
          isActive={period === value}
          onClick={() => {
            setPeriod(value);
          }}
          title="Select displayed time period"
        >
          {timePeriodsLabelEnum[value]}
        </StatusButton>
      ))}
    </SC.FlexRow>
    <GridFooter />
  </SC.FooterContainer>
);
