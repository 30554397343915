/* eslint-disable no-unused-vars */
import React from 'react';
import { useConfirmAction } from 'hooks/useConfirmAction';
import { Button } from '@mui/material';
import * as UI from 'UI';
import { getAgeFromDateOfBirth } from 'utils/getAgeFromDateOfBirth';
import { GenderLabelEnum, RoleEnum } from 'models/constants';
import * as FP from 'utils/fp-js';
import { AdminProcedure } from 'components/MasterAdmin/Procedure/AdminProcedure';
import { SurgeonProcedure } from 'components/Surgeon/Procedure/Procedure';
import { RadiologistProcedure } from 'components/Radiologist/Procedure/Procedure';
import { ImageEngineerProcedure } from 'components/ImageEngineer/Procedure/Procedure';
import { StatusFlow } from 'components/Procedure/StatusFlow/StatusFlow';
import * as SC from './Procedure.sc';
import { useProcedure } from './useProcedure';

export const Procedure = () => {
  const { confirmAction } = useConfirmAction();
  const hookData = useProcedure();

  const {
    row,
    isUpdating,
    error,
    id,
    handleSubmit,
    handleCancelChanges,
    onSubmit,
    isDirty,
    globalState,
    watch,
    trigger,
    reset,
    accessAllowed,
    isNotCurrentSurgeon,
    historyData,
    assignedSurgeons,
  } = hookData;

  if (
    (isNotCurrentSurgeon &&
      globalState.user?.value?.role === RoleEnum.SURGEON) ||
    (globalState.user?.value?.role === RoleEnum.SURGICAL_ASSISTANT &&
      !assignedSurgeons.includes(row?.value?.surgeonId) &&
      !isUpdating)
  ) {
    return (
      <SC.Container>
        <UI.ErrorBox text={error} width="calc(100% - 32px)" />
        <SC.Header>
          <SC.FlexRow>
            <UI.BackToPrevPage />
            <h3>Procedure #{id}</h3>
          </SC.FlexRow>
        </SC.Header>
        {isUpdating && <UI.GlobalSpinner />}
        <SC.Wrapper>
          <UI.AccessDenied />
        </SC.Wrapper>
      </SC.Container>
    );
  }

  if (!row?.value?.id && !isUpdating) {
    return (
      <SC.Container>
        <UI.ErrorBox text={error} width="calc(100% - 32px)" />
        <SC.Header>
          <SC.FlexRow>
            <UI.BackToPrevPage />
            <h3>Procedure #{id}</h3>
          </SC.FlexRow>
        </SC.Header>
      </SC.Container>
    );
  }

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 32px)" />
      <SC.Header>
        <SC.FlexRow>
          <UI.BackToPrevPage isConfirmNeeded={isDirty} reset={reset} />
          <h3>
            Procedure #{id}
            {row?.value && accessAllowed && (
              <>
                , &nbsp;
                {row.value?.patient?.nameFirst} {row.value?.patient?.nameSecond}
                , &nbsp;
                {GenderLabelEnum[row.value?.patient?.gender]}, &nbsp;
                {getAgeFromDateOfBirth(row.value?.patient?.dateOfBirth)} y.o.
              </>
            )}
          </h3>
        </SC.FlexRow>
      </SC.Header>
      {isUpdating && <UI.GlobalSpinner />}
      <StatusFlow hookData={hookData} />
      <SC.Wrapper>
        {!accessAllowed && watch()?.id && <UI.AccessDenied />}
        {accessAllowed &&
          FP.match(globalState.user.value.role)
            .on(
              RoleEnum.IMAGE_ENGINEER,
              <ImageEngineerProcedure hookData={hookData} />
            )
            .on(
              RoleEnum.RADIOLOGIST,
              <RadiologistProcedure hookData={hookData} />
            )
            .on(
              [RoleEnum.MASTER_ADMIN, RoleEnum.ADMIN],
              <AdminProcedure hookData={hookData} />
            )
            .otherwise(<SurgeonProcedure hookData={hookData} />)}
      </SC.Wrapper>
      {isDirty && (
        <SC.Footer>
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={!isDirty || isUpdating}
            onClick={handleCancelChanges}
          >
            Cancel changes
          </Button>
          &nbsp;
          <Button
            disabled={!isDirty || isUpdating}
            type="button"
            variant="contained"
            color="secondary"
            onClick={async () => {
              const isValid = await trigger();
              if (isValid) {
                handleSubmit(onSubmit)();
              }
            }}
          >
            Save Changes
          </Button>
        </SC.Footer>
      )}
    </SC.Container>
  );
};
