import { createApiMethodsSet } from './createApiMethodsSet';
import { httpService } from './httpService';

const api = createApiMethodsSet();

api.getAllAssigned = async ({ id }) =>
  httpService({
    url: `/assign/by-surgeon/${id}`,
    options: {
      method: 'GET',
    },
  });

api.deleteUnassigned = async ({ surgeonId, assistantId }) =>
  httpService({
    url: `/assign/delete/${surgeonId}/${assistantId}`,
    options: {
      method: 'DELETE',
    },
  });

api.assign = async ({ surgeonAssistantId, surgeonId }) =>
  httpService({
    url: '/assign/create',
    options: {
      method: 'POST',
      data: {
        surgeonId,
        surgeonAssistantId,
      },
    },
  });

api.getAllAssignedByAssistantId = async ({ id }) =>
  httpService({
    url: `/assign/by-assistant/${id}`,
    options: {
      method: 'GET',
    },
  });

api.getAllAvailable = async () =>
  httpService({
    url: `/assign/all`,
    options: {
      method: 'GET',
    },
  });

export default api;
