/* eslint-disable no-console */
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Button } from '@mui/material';
import * as UI from 'UI';
import * as SC from './Login.sc';

import { useLogin } from './useLogin';
import { withGlobalStateUtils } from '../../utils/withGlobalStateUtils';

function Login(props) {
  const { error } = props;
  const {
    errors,
    apiState,
    usernameRegisterProps,
    passwordRegisterProps,
    onSubmit,
    version,
  } = useLogin(props);

  return (
    <div className="App-header">
      <SC.FlexColumn style={{ marginBottom: '10vh' }}>
        <h6>Enter your email and password:</h6>
        <form onSubmit={onSubmit} className="mui-form">
          <UI.ErrorBox text={error} width="calc(100% - 30px)" />
          <SC.LoginForm>
            <SC.Input
              type="text"
              placeholder="Email"
              autoComplete="new-username"
              {...usernameRegisterProps}
            />
            <ErrorMessage
              errors={errors}
              name="username"
              render={({ message }) => <UI.ErrorString text={message} />}
            />
            <SC.Input
              type="password"
              placeholder="Password"
              autoComplete="new-password"
              {...passwordRegisterProps}
            />
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => <UI.ErrorString text={message} />}
            />
            {apiState === 'loading' && (
              <UI.Spinner style={{ alignSelf: 'center' }} />
            )}
            {apiState !== 'loading' && (
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                Log in
              </Button>
            )}
            <SC.Link to="/password_recovery">forgot password</SC.Link>
            <SC.Version>v.{version}</SC.Version>
          </SC.LoginForm>
        </form>
      </SC.FlexColumn>
    </div>
  );
}

export default withGlobalStateUtils(Login);
