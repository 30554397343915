/* DO NOT FORGET to add export of this file to /src/store/reducers/index.js */
import * as FP from '../../utils/fp-js';

export const alert = (state, action) =>
  FP.match(action.type)
    .on('SHOW_ALERT', {
      type: action.payload?.type,
      text: action.payload?.text,
      isVisible: true,
    })
    .on('HIDE_ALERT', {
      type: 'warning',
      text: '',
      isVisible: false,
    })
    .otherwise(state);
