import styled from '@emotion/styled';
import { Button } from '@mui/material';

const CommonButton = styled(Button)`
  margin: 0 1px 0 1px;
  padding: 2px 4px;
  min-width: 30px;

  font-weight: normal;
  text-transform: none;

  font-size: 0.75em;
  letter-spacing: -0.03em;
`;

export const StatusButton = styled(CommonButton)`
  color: gray;
  background-color: white;
`;

export const ActiveStatusButton = styled(CommonButton)`
  color: white;
  background-color: ${({ mycolor = 'black' }) => mycolor};
`;
