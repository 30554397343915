import React from 'react';
import * as SC from './LabeledArea.sc';

export const LabeledArea = ({ label, children, width }) => {
  return (
    <SC.Container direction="column" width={width}>
      <SC.LabelHolder>
        <SC.Label>{label}</SC.Label>
      </SC.LabelHolder>
      {children}
    </SC.Container>
  );
};
