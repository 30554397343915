/* eslint-disable max-lines-per-function */
/* eslint-disable prettier/prettier */
import * as FP from 'utils/fp-js';
import { useGlobalStateUtils } from 'hooks/useGlobalStateUtils';
import {
  ProcedureStatusFlowEnum,
  ProcedureStatusLabelEnum,
  RoleEnum,
  EngineerProcedureStatusEnum,
  RadiologistProcedureStatusEnum,
  ProcedureStatusEnum,
  FileTypeEnum,
} from 'models/constants';

import {
  allowedStatusChangesForSurgeon,
  allowedStatusChangesForAdmin,
  disabledStatusChangesForSurgeon,
  disabledStatusChangesForAdmin,
} from 'models/statusChangeSequences';
import { checkIfStatusChangeAllowed } from 'utils/statusCheckUtils';

const statuses = Object.keys(ProcedureStatusFlowEnum);
const steps = statuses.map(item => ProcedureStatusLabelEnum[item]);

export const useStatusFlow = ({ hookData }) => {
  const { showAlert } = useGlobalStateUtils();

  const {
    globalState,
    setValue,
    getValues,
    row,
    isNotCurrentEngineer,
    isNotCurrentRadiologist,
    isNotCurrentSurgeon,
    watch,
  } = hookData;

  const currentRole = globalState?.user?.value?.role;
  const isConfirmVisible = globalState?.confirm?.isVisible;

  const currentStatus = getValues().status;
  const isProcedureDeleted = getValues().deleted;
  const currentIndex = statuses.indexOf(currentStatus) ?? 0;

  const readOnlyStatusFlow =
    (isNotCurrentEngineer && currentRole === RoleEnum.IMAGE_ENGINEER) ||
    (isNotCurrentRadiologist && currentRole === RoleEnum.RADIOLOGIST) ||
    (isNotCurrentSurgeon && currentRole === RoleEnum.SURGEON);

  const enabledStatuses = Object.keys(
    FP.match(globalState?.user?.value?.role)
      .on(RoleEnum.IMAGE_ENGINEER, EngineerProcedureStatusEnum)
      .on(RoleEnum.RADIOLOGIST, RadiologistProcedureStatusEnum)
      .otherwise(ProcedureStatusEnum)
  );

  const { files = [] } = row?.value ?? {};

  const isModelFilePresent = !!files?.filter(
    ({ type, deleted }) => !deleted && type === FileTypeEnum.MODEL
  )?.length;

  const isScanFilePresent = !!files?.filter(
    ({ type, deleted }) => !deleted && type === FileTypeEnum.SCAN
  )?.length;

  const isSurgeryDatePresent = !!row?.value?.dateOfSurgery;

  const values = getValues() ?? {};

  const {
    size,
    superior,
    posterior,
    anterior,
    sideEngineer,
    sideSurgeon,
    anatomicAnteVersion,
    anatomicInclination,
  } = values;

  const isValuePresent = value => value === 0 || value === '0' || !!value;

  const isAllVarsPresent = (...args) =>
    args.reduce((acc, next) => {
      const isNotNull = isValuePresent(next);
      return isNotNull ? acc : false;
    }, true);

  const isPriDataImported = isAllVarsPresent(
    size,
    superior,
    posterior,
    anterior,
    sideEngineer,
    anatomicAnteVersion,
    anatomicInclination
  );

  const helperText = FP.match(true)
    .on(
      isScanFilePresent &&
        currentRole === RoleEnum.RADIOLOGIST &&
        currentStatus === ProcedureStatusEnum.SCAN,
      'Next status can be assigned'
    )
    .on(
      isModelFilePresent &&
        isPriDataImported &&
        currentRole === RoleEnum.IMAGE_ENGINEER &&
        currentStatus === ProcedureStatusEnum.MODEL_IN_WORK,
      'Next status can be assigned'
    )
    .on(
      (currentRole === RoleEnum.SURGEON ||
        currentRole === RoleEnum.MASTER_ADMIN ||
        currentRole === RoleEnum.ADMIN) &&
        currentStatus === ProcedureStatusEnum.NEW,
      'Schedule a Scan'
    )
    .on(
      (currentRole === RoleEnum.SURGEON ||
        currentRole === RoleEnum.MASTER_ADMIN ||
        currentRole === RoleEnum.ADMIN) &&
        currentStatus === ProcedureStatusEnum.READY,
      'Schedule a Surgery'
    )
    .otherwise('');

  const getEngineerStatusChangePermission = newStatus => {
    if (
      isPriDataImported &&
      sideEngineer !== sideSurgeon &&
      newStatus === ProcedureStatusEnum.MODEL_READY
    ) {
      return { enabled: false, reason: "The side of the model doesn't match" };
    }

    if (
      !isPriDataImported &&
      !isModelFilePresent &&
      newStatus === ProcedureStatusEnum.MODEL_READY
    ) {
      return {
        enabled: false,
        reason: 'Please import PRI data and upload 3D model first',
      };
    }

    if (!isPriDataImported && newStatus === ProcedureStatusEnum.MODEL_READY) {
      return { enabled: false, reason: 'Please import PRI data first' };
    }

    if (!isModelFilePresent && newStatus === ProcedureStatusEnum.MODEL_READY) {
      return { enabled: false, reason: 'Please upload model first' };
    }

    return { enabled: true };
  };

  const getRadiologistStatusChangePermission = newStatus => {
    if (!isScanFilePresent && newStatus === ProcedureStatusEnum.SCAN_READY) {
      return { enabled: false, reason: 'Please upload scan files first' };
    }
    return { enabled: true };
  };

  const getStatusChangeSequencesPermission = newStatus => {
    const isAdmin =
      currentRole === RoleEnum.MASTER_ADMIN || currentRole === RoleEnum.ADMIN;
    const { radiologyLocationId, status } = watch();

    if (newStatus === ProcedureStatusEnum.CANCELED) {
      return {
        enabled: true,
      };
    }
    if (!radiologyLocationId) {
      return {
        enabled: false,
        reason: 'Please select Radiology Location first.',
      };
    }

    return FP.match(newStatus)
      .on(
        isAdmin
          ? disabledStatusChangesForAdmin
          : disabledStatusChangesForSurgeon,
        {
          enabled: false,
          reason: `You can't set status "${ProcedureStatusLabelEnum[newStatus]}"`,
        }
      )
      .on(!isSurgeryDatePresent && ProcedureStatusEnum.SCHEDULED, {
        enabled: false,
        reason: `Set date of surgery first`,
      })
      .otherwise(
        !checkIfStatusChangeAllowed(
          status,
          newStatus,
          isAdmin
            ? allowedStatusChangesForAdmin
            : allowedStatusChangesForSurgeon
        )
          ? {
              enabled: false,
              reason: `You can't set status "${ProcedureStatusLabelEnum[newStatus]}"`,
            }
          : {
              enabled: true,
            }
      );
  };

  const getStatusChangePermission = status => {
    return FP.match(currentRole)
      .on(RoleEnum.IMAGE_ENGINEER, () =>
        getEngineerStatusChangePermission(status)
      )
      .on(RoleEnum.RADIOLOGIST, () =>
        getRadiologistStatusChangePermission(status)
      )
      .otherwise(() => getStatusChangeSequencesPermission(status));
  };

  const handleClickStatus = newStatus => {
    const permission = getStatusChangePermission(newStatus);
    if (permission.enabled) {
      setValue('status', newStatus, { shouldDirty: true });
    } else {
      showAlert({
        type: 'warning',
        text: permission.reason,
      });
    }
  };

  return {
    steps,
    isProcedureDeleted,
    currentIndex,
    readOnlyStatusFlow,
    enabledStatuses,
    handleClickStatus,
    statuses,
    currentStatus,
    helperText,
    isConfirmVisible,
    getStatusChangePermission,
  };
};
