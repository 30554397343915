import { createApiMethodsSet } from './createApiMethodsSet';
import { httpService } from './httpService';

const api = createApiMethodsSet();

api.create = async data => {
  return httpService({
    url: '/admin/create-location',
    options: {
      method: 'POST',
      data,
    },
  });
};

api.getAll = async () =>
  httpService({ url: '/admin/all-location', options: { method: 'GET' } });

api.getById = async id =>
  httpService({ url: `/admin/get-location/${id}`, options: { method: 'GET' } });

api.save = async ({
  id,
  name,
  address,
  phoneNumber,
  hasSurgery,
  hasRadiology,
  active,
}) =>
  httpService({
    url: '/admin/update-location',
    options: {
      method: 'POST',
      data: {
        id,
        name,
        address,
        phoneNumber,
        hasSurgery,
        hasRadiology,
        active,
      },
    },
  });

api.remove = async id =>
  httpService({
    url: '/admin/delete-location',
    options: {
      method: 'DELETE',
      data: {
        locationId: id,
      },
    },
  });

export default api;
