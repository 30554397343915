import { createApiMethodsSet } from './createApiMethodsSet';
import { httpService } from './httpService';

const api = createApiMethodsSet();

api.getAll = async () =>
  httpService({ url: '/admin/user-all', options: { method: 'GET' } });

api.getById = async id =>
  httpService({ url: `/admin/user-get/${id}`, options: { method: 'GET' } });

api.save = async data =>
  httpService({
    url: '/admin/user-update',
    options: {
      method: 'POST',
      data,
    },
  });

api.create = async ({
  role,
  nameFirst,
  nameSecond,
  phoneNumber,
  email,
  password,
  status,
  locationId,
}) =>
  httpService({
    url: '/admin/user-save',
    options: {
      method: 'POST',
      data: {
        role,
        nameFirst,
        nameSecond,
        phoneNumber,
        email,
        password,
        status,
        locationId,
      },
    },
  });

api.remove = async id =>
  httpService({
    url: '/admin/user-delete',
    options: {
      method: 'DELETE',
      data: {
        userId: id,
      },
    },
  });
api.updatePasswordByToken = async ({ userId, token, newPassword }) =>
  httpService({
    url: '/admin/user-password',
    options: {
      method: 'POST',
      data: { userId, token, newPassword },
    },
  });

export default api;
