import React, { useState } from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import { DataGrid, GridFooter } from '@mui/x-data-grid';
import { withGlobalStateUtils } from 'utils/withGlobalStateUtils';
import * as UI from 'UI';
import * as SC from './Procedures.sc';
import { useProcedures } from './useProcedures';

import {
  ProcedureStatusLabelEnum,
  ProcedureStatusColorEnum,
} from '../../models/constants';

export const Procedures = withGlobalStateUtils(() => {
  const [onlyMyProcedures, setOnlyMyProcedures] = useState(false);
  const hookData = useProcedures({ onlyMyProcedures });
  const {
    data,
    columns,
    isUpdating,
    error,
    search,
    handleSearchChange,
    handleSearchCancel,
    sortModel,
    setSortModel,
    statusSet,
    handleChangeStatus,
    showAllStatuses,
    statusList,
    openProcedure,
    isOnlyMyProcedureSwitchsVisible,
    period,
    setPeriod,
    isDeletedRangeSelectorVisible,
  } = hookData;

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 40px)" />
      <SC.Header>
        <h3>Procedures</h3>
        {isOnlyMyProcedureSwitchsVisible && (
          <SC.SwitchHolder className={onlyMyProcedures ? 'activated' : 'off'}>
            <FormControlLabel
              control={
                <Switch
                  onChange={() => setOnlyMyProcedures(!onlyMyProcedures)}
                  defaultChecked={false}
                  color="warning"
                  size="small"
                />
              }
              label="show only my procedures"
            />
          </SC.SwitchHolder>
        )}
        <UI.GridSearchField
          search={search}
          handleSearchChange={handleSearchChange}
          handleSearchCancel={handleSearchCancel}
        />
      </SC.Header>
      <SC.ButtonSet>
        <UI.StatusButton
          customColor="#555"
          isActive={statusSet.length === 0}
          onClick={showAllStatuses}
          title="Show all procedures"
        >
          All
        </UI.StatusButton>
        {Object.values(statusList).map(value => (
          <UI.StatusButton
            key={value}
            customColor={ProcedureStatusColorEnum[value]}
            isActive={statusSet.includes(ProcedureStatusLabelEnum[value])}
            onClick={() => handleChangeStatus(ProcedureStatusLabelEnum[value])}
            title={
              statusSet.includes(ProcedureStatusLabelEnum[value])
                ? `Hide procedures with status "${ProcedureStatusLabelEnum[value]}"`
                : `Show procedures with status "${ProcedureStatusLabelEnum[value]}"`
            }
          >
            {ProcedureStatusLabelEnum[value]}
          </UI.StatusButton>
        ))}
      </SC.ButtonSet>
      <SC.Paper elevation={3}>
        <DataGrid
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          rows={data}
          columns={columns}
          // pageSize={25}
          rowsPerPageOptions={[25, 50, 100]}
          disableSelectionOnClick
          density="compact"
          // onSelectionModelChange={handleSelectionChange}
          disableColumnFilter
          disableColumnMenu
          className={SC.DataGridClass}
          loading={isUpdating}
          onRowClick={openProcedure}
          components={{
            Footer: () =>
              isDeletedRangeSelectorVisible ? (
                <UI.FooterWithPeriodSelector
                  period={period}
                  setPeriod={setPeriod}
                  itemsName="Show deleted procedures"
                />
              ) : (
                <GridFooter />
              ),
          }}
        />
      </SC.Paper>
    </SC.Container>
  );
});
