/* eslint-disable no-unused-vars */
import { useEffect, useRef } from 'react';
import { useGlobalState } from 'store/GlobalStateContext';
import { useMessagesApi } from 'hooks/useMessagesApi';

import {
  AUTOLOGOUT_DELAY_MIN,
  AUTOLOGOUT_FOR_MODEL_VIEW_DELAY_MIN,
  localStorageNameEnum,
  DEBOUNCE_TIME_FOR_ALERT_MS,
  MESSAGES_REFRESH_INTERVAL_MS,
  REFRESH_TIME_BEFORE_TOKEN_EXPIRED_IN_SEC,
} from 'models/constants';

import * as authApi from 'api/auth';

export const useAuthGate = () => {
  const [globalState, dispatchGlobalState] = useGlobalState();
  const { alert, user, isModelViewOpened } = globalState;
  const logoutTimeoutRef = useRef(setTimeout(() => null));
  // useUsersApi();
  const { reloadIncomingMessages, reloadSentMessages } = useMessagesApi();

  const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

  const logoutUser = () => {
    dispatchGlobalState({ type: 'LOGOUT' });
    window.location.reload();
  };

  const restoreAuthByRefreshToken = async () => {
    const storedRefreshToken = localStorage.getItem(
      localStorageNameEnum.REFRESH_AUTH_TOKEN
    );
    if (!storedRefreshToken) return;

    const result = await authApi.restoreAuthByToken(storedRefreshToken);

    if (result?.error) {
      logoutUser();
      return;
    }
    dispatchGlobalState({ type: 'SET_user', payload: result });
  };

  useEffect(() => {
    const setTimeoutLogout = () => {
      clearTimeout(logoutTimeoutRef.current);

      logoutTimeoutRef.current = setTimeout(
        logoutUser,
        (isModelViewOpened
          ? AUTOLOGOUT_FOR_MODEL_VIEW_DELAY_MIN
          : AUTOLOGOUT_DELAY_MIN) *
          60 *
          1000
      );
    };

    // Autologin timer hanler
    events.forEach(name => {
      document.addEventListener(name, setTimeoutLogout);
    });

    setTimeoutLogout();

    return () => {
      events.forEach(name => {
        document.removeEventListener(name, setTimeoutLogout);
      });
      clearTimeout(logoutTimeoutRef.current);
    };
  }, [isModelViewOpened]);

  useEffect(() => {
    // Close alert when it changes
    if (alert.isVisible) {
      clearTimeout(window.alertTimeout);
      window.alertTimeout = setTimeout(() => {
        dispatchGlobalState({ type: 'HIDE_ALERT' });
      }, DEBOUNCE_TIME_FOR_ALERT_MS);
    }
  }, [alert]);

  useEffect(() => {
    const token = user?.value?.access?.token;
    const expiresAt = user?.value?.access?.expiresAt;
    const storedRefreshToken = localStorage.getItem(
      localStorageNameEnum.REFRESH_AUTH_TOKEN
    );

    // check if there is stored auth present in localStorage and try to restore auth by it.
    if (!token && storedRefreshToken) {
      restoreAuthByRefreshToken();
    }

    if (token && storedRefreshToken) {
      // set next refresh request timeout if both tokens are present
      const delay =
        expiresAt -
        Date.now() -
        REFRESH_TIME_BEFORE_TOKEN_EXPIRED_IN_SEC * 1000;

      window.refreshTokensTimer = setInterval(() => {
        restoreAuthByRefreshToken();
      }, delay);

      return () => clearInterval(window?.refreshTokensTimer);
    }

    return () => null;
  }, [user]);

  useEffect(async () => {
    reloadIncomingMessages(user?.value?.userId);
    reloadSentMessages(user?.value?.userId);
  }, [user]);

  useEffect(() => {
    const refresh = setInterval(() => {
      if (!isModelViewOpened) {
        reloadIncomingMessages(user?.value?.userId);
        reloadSentMessages(user?.value?.userId);
      }
    }, MESSAGES_REFRESH_INTERVAL_MS);

    return () => clearInterval(refresh);
  }, [user, isModelViewOpened]);

  return {
    user,
  };
};
