/* eslint-disable prettier/prettier */
import locationApi from 'api/location';
import { RoleEnum, UserStatusEnum } from 'models/constants';
import { useApi } from './useApi';

export const useLocationList = (autoload = true) => {
  const {
    data,
    globalState: { users },
    handleReloadData,
  } = useApi(locationApi, autoload);

  const getLocationById = (locationsData, id) => {
    if (id < 0) return '';
    const location = locationsData?.value?.find(
      item => String(item.id) === String(id)
    );
    return location?.name ?? id;
  };

  const locationListOptions = Array.isArray(data?.value)
    ? data.value.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: {
            name: curr.name,
            disabled: curr?.active === false,
          },
        }),
        {}
      )
    : {};

  // list of radiology location with active radiologist users
  const activeRadiologyLocationListOptions = (() => {
    const activeRadiologistsLocations = Array.isArray(users?.value)
      ? users.value
          .filter(
            row => row?.role === RoleEnum.RADIOLOGIST && !!row?.location?.id
          )
          .map(({ location, deleted, status }) => ({
            id: location.id,
            name: location?.name ?? '',
            disabled:
              location?.active === false ||
              location?.deleted ||
              deleted ||
              status !== UserStatusEnum.ACTIVE,
          }))
      : [];

    const radiologyLocations = data?.value
      ? data.value
          .filter(row => row?.hasRadiology)
          .map(({ id, name }) => ({
            id,
            name,
            disabled: true,
          }))
      : [];

    const commonList = [...radiologyLocations, ...activeRadiologistsLocations];

    const activeLocations = commonList
      .map(row => ({
        id: row?.id,
        name: row?.name,
        active: row?.disabled === false,
      }))
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr.id]: { name: curr.name, disabled: curr?.active === false },
        };
      }, {});

    return activeLocations;
  })();

  const surgeryLocationListOptions = (() =>
    Array.isArray(data?.value)
      ? data.value.reduce((acc, curr) => {
          if (curr.hasSurgery) {
            return {
              ...acc,
              [curr.id]: {
                name: curr.name,
                disabled: curr?.active === false,
              },
            };
          }
          return acc;
        }, {})
      : {})();

  const radiologyLocationListOptions = (() =>
    Array.isArray(data?.value)
      ? data.value.reduce((acc, curr) => {
          if (curr.hasRadiology) {
            return {
              ...acc,
              [curr.id]: {
                name: curr.name,
                disabled: curr?.active === false || curr?.deleted,
              },
            };
          }
          return acc;
        }, {})
      : {})();

  return {
    locationsData: data,
    locationListOptions,
    surgeryLocationListOptions,
    radiologyLocationListOptions,
    activeRadiologyLocationListOptions,
    handleReloadLocationList: handleReloadData,
    getLocationById,
  };
};
