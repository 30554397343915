/* eslint-disable no-unreachable */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-console */
import React, { useMemo, useState } from 'react';
import { Stack, TextField, Button } from '@mui/material';
import { useGlobalStateUtils } from 'hooks/useGlobalStateUtils';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import filesApi from 'api/csvFile';
import * as UI from 'UI';
import {
  RoleEnum,
  MIN_INCLINATION_IN_DEGREE,
  MAX_INCLINATION_IN_DEGREE,
  MIN_ANTEVERSION_IN_DEGREE,
  MAX_ANTEVERSION_IN_DEGREE,
  SideLabelEnum,
  ProcedureStatusEnum,
} from 'models/constants';
import { getNumberRangeValidator } from 'models/validations';
import { InfoWindow } from './InfoWindow/InfoWindow';
import * as SC from './Pelvis.sc';

export const Pelvis = ({ hookData }) => {
  const { isUpdating, control, watch, id, handleReload, globalState } =
    hookData;
  const { showSpinner, hideSpinner } = useGlobalStateUtils();

  const [importResultText, setImportResultText] = useState('');

  const role = useMemo(
    () => globalState.user?.value?.role,
    [globalState?.user]
  );

  const values = watch();

  const isDisabled =
    (role !== RoleEnum.SURGEON &&
      role !== RoleEnum.MASTER_ADMIN &&
      role !== RoleEnum.ADMIN &&
      role !== RoleEnum.SURGICAL_ASSISTANT &&
      role !== RoleEnum.IMAGE_ENGINEER) ||
    isUpdating ||
    values?.deleted;

  const isPlannedDisabled =
    isDisabled ||
    // globalState.user?.value?.userId !== values?.surgeonId ||
    (values?.status !== ProcedureStatusEnum.MODEL_READY &&
      values?.status !== ProcedureStatusEnum.SCHEDULED &&
      values?.status !== ProcedureStatusEnum.READY);

  const isImportDisabled =
    role !== RoleEnum.IMAGE_ENGINEER &&
    role !== RoleEnum.ADMIN &&
    role !== RoleEnum.MASTER_ADMIN;

  const handleUpload = async event => {
    const input = event?.target;
    const filesForUpload = Object.values(input?.files);

    if (filesForUpload?.length === 0) return;
    setImportResultText('');
    showSpinner();

    const formData = new FormData();
    filesForUpload.forEach(file => {
      formData.append('file', file, file.name);
    });
    formData.append('procedureId', id);

    try {
      const response = await filesApi.upload(formData);
      const result = await response?.json();
      input.value = '';
      hideSpinner();
      if (result?.error) {
        setImportResultText(result?.message);
        return;
      }
      handleReload();
    } catch (error) {
      input.value = '';
      hideSpinner();
      setImportResultText(error);
    }
  };

  return (
    <SC.Container>
      <SC.LeftPanel>
        <UI.LabeledArea label="PRI Parameters">
          <UI.FormNumberField
            label="Size, mm"
            name="size"
            control={control}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
          <UI.LabeledArea label="Leg Positions">
            <Stack direction="column" spacing={1}>
              <UI.FormNumberField
                label="Superior"
                name="superior"
                control={control}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <UI.FormNumberField
                label="Anterior"
                name="anterior"
                control={control}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <UI.FormNumberField
                label="Posterior"
                name="posterior"
                control={control}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
          </UI.LabeledArea>
        </UI.LabeledArea>
      </SC.LeftPanel>
      <SC.RightPanel>
        <SC.Parameters>
          <UI.LabeledArea label="Anatomic Angles" width="100%">
            <Stack direction="column" spacing={1}>
              <UI.FormTextField
                label="Inclination,°"
                name="anatomicInclination"
                control={control}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <UI.FormTextField
                label="Anteversion,°"
                name="anatomicAnteVersion"
                control={control}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
          </UI.LabeledArea>
          <UI.LabeledArea label="Planned Angles" width="100%">
            <Stack direction="column" spacing={1}>
              <UI.FormNumberField
                label="Inclination,°"
                name="plannedInclination"
                control={control}
                disabled={isPlannedDisabled}
                min={MIN_INCLINATION_IN_DEGREE}
                max={MAX_INCLINATION_IN_DEGREE}
                InputLabelProps={{
                  shrink: true,
                }}
                rules={getNumberRangeValidator(
                  MIN_INCLINATION_IN_DEGREE,
                  MAX_INCLINATION_IN_DEGREE
                )}
              />
              <UI.FormNumberField
                label="Anteversion,°"
                name="plannedAnteVersion"
                control={control}
                disabled={isPlannedDisabled}
                min={MIN_ANTEVERSION_IN_DEGREE}
                max={MAX_ANTEVERSION_IN_DEGREE}
                InputLabelProps={{
                  shrink: true,
                }}
                rules={getNumberRangeValidator(
                  MIN_ANTEVERSION_IN_DEGREE,
                  MAX_ANTEVERSION_IN_DEGREE
                )}
              />
            </Stack>
          </UI.LabeledArea>
        </SC.Parameters>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          spacing={2}
        >
          <TextField
            size="small"
            label="Side"
            readOnly
            value={SideLabelEnum[watch()?.sideEngineer] ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '90px', flex: '1 0 auto' }}
            variant="filled"
            disabled
          />
          <UI.FormTextField
            label="Femoral Neck Anteversion,°"
            name="femoralNeckAnteVersion"
            control={control}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Stack>
        <UI.LabeledArea label="CSV File Parser" width="100%">
          <Stack>
            <label
              htmlFor="csv"
              style={{ flex: '1 1 auto', textAlign: 'center' }}
            >
              <input
                style={{ display: 'none' }}
                id="csv"
                name="csv"
                type="file"
                onChange={handleUpload}
                disabled={isImportDisabled}
              />

              <Button
                sx={{
                  width: '100%',
                }}
                variant="contained"
                color="success"
                component="span"
                onClick={() => null}
                type="button"
                size="small"
                disabled={isImportDisabled}
              >
                Import PRI from CSV <ImportExportIcon />
              </Button>
            </label>
          </Stack>
        </UI.LabeledArea>
      </SC.RightPanel>
      {importResultText && (
        <InfoWindow
          color="warning"
          severity="error"
          title="Import error"
          message={importResultText}
          handleClose={() => setImportResultText('')}
        />
      )}
    </SC.Container>
  );
};
