/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Checkbox } from '@mui/material';
import { ModelViewer } from 'UI';
import { convertToLocaleDate } from 'utils/dateTimeUtils';
import {
  FileTypeEnum,
  fileTypeLabelEnum,
  localStorageNameEnum,
  ProcedureStatusEnum,
  RoleEnum,
} from 'models/constants';
import { getNextIdKey } from 'utils/getNextIdKey';
import * as FP from 'utils/fp-js';
import { useListOfFiles } from './useListOfFiles';
import * as SC from './ListOfFiles.sc';
import { LoaderWindow } from './LoaderWindow/LoaderWindow';
import { StlLoaderWindow } from './StlLoaderWindow/StlLoaderWindow';

const CLOSE_STL_PROGRESS_BAR_DELAY_IN_MS = 100;

const Item = ({ file, onClick, selected }) => {
  const { creation, fileName, description } = file;

  return (
    <SC.Row id={file?.id} onClick={onClick}>
      <SC.Selector>
        <Checkbox checked={selected} />
      </SC.Selector>
      <SC.Date>{convertToLocaleDate(creation)}</SC.Date>
      <SC.FileName>{fileName}</SC.FileName>
      <SC.Description>{description}</SC.Description>
    </SC.Row>
  );
};

export const ListOfFiles = ({
  handleReload,
  fileType,
  procedureId,
  readOnly,
  handleUpdateUserIdOnUpload,
  showOnlyLastDate = false,
  isUpdating,
  watch,
  procedure,
}) => {
  const {
    list,
    selected,
    handleUpload,
    handleDownload,
    handleDelete,
    handleClickFileItem,
    openModelViewer,
    closeModelViewer,
    isModelViewerVisible,
    modelUrl,
    role,
    isLoaderVisible,
    setIsLoaderVisible,
    isStlLoaderVisible,
    setIsStlLoaderVisible,
    isStlLoadingCanceled,
    setIsStlLoadingCanceled,
    stlLoadingProgress,
    setStlLoadingProgress,
    loadingComplete,
    loadingError,
    setLoadingError,
    isUpload,
    handleCancelLoading,
  } = useListOfFiles(
    procedure?.files ? procedure.files : [],
    handleReload,
    fileType,
    procedureId,
    handleUpdateUserIdOnUpload
  );

  const onStlLoadingFinish = () => {
    setStlLoadingProgress(100);
    setTimeout(
      () => setIsStlLoaderVisible(false),
      CLOSE_STL_PROGRESS_BAR_DELAY_IN_MS
    );
  };

  if (isUpdating) {
    return <SC.Container>...</SC.Container>;
  }

  if (showOnlyLastDate) {
    return list.length > 0 ? (
      <SC.Container>
        Last scan date:&nbsp;
        {list[list.length - 1]?.creation
          ? convertToLocaleDate(list[list.length - 1]?.creation)
          : ''}
      </SC.Container>
    ) : (
      <SC.Container />
    );
  }

  list.sort((a, b) => (a?.fileName < b?.fileName ? -1 : 1));

  const storedToken = localStorage.getItem(localStorageNameEnum.AUTH_TOKEN);
  const headers = {
    // 'Content-Type': 'application/json',
    Authorization: `Bearer ${storedToken}`,
  };

  return (
    <SC.Container>
      <h5>{fileTypeLabelEnum[fileType]} files:</h5>
      {list.map((file, index) => (
        <Item
          // eslint-disable-next-line react/no-array-index-key
          key={`${file}${index}`}
          file={file}
          onClick={() => handleClickFileItem(file.id)}
          selected={selected.includes(file.id)}
        />
      ))}
      {!list?.length && <SC.NoFiles>No files</SC.NoFiles>}
      {isModelViewerVisible && (
        <ModelViewer
          url={modelUrl}
          handleClose={closeModelViewer}
          onFinishLoading={onStlLoadingFinish}
          onErrorLoading={() =>
            setLoadingError('Error loading of the STL model')
          }
          watch={watch}
          procedure={procedure}
          extraHeaders={headers}
        />
      )}
      {isLoaderVisible && (
        <LoaderWindow
          onClose={() => setIsLoaderVisible(false)}
          isUpload={isUpload}
          loadingComplete={loadingComplete}
          loadingError={loadingError}
          handleCancelLoading={handleCancelLoading}
        />
      )}
      {isStlLoaderVisible && (
        <StlLoaderWindow
          onClose={() => {
            setIsStlLoaderVisible(false);
            setTimeout(
              () => closeModelViewer(),
              CLOSE_STL_PROGRESS_BAR_DELAY_IN_MS
            );
          }}
          loadingError={loadingError}
          setLoadingError={setLoadingError}
          isLoadingCanceled={isStlLoadingCanceled}
          setIsLoadingCanceled={setIsStlLoadingCanceled}
          progressValue={stlLoadingProgress}
          setProgressValue={setStlLoadingProgress}
        />
      )}
      <SC.Buttons>
        {FP.match(role)
          .on(
            [RoleEnum.SURGEON, RoleEnum.SURGICAL_ASSISTANT],
            <Button
              disabled={selected.length === 0}
              type="button"
              variant="contained"
              color="secondary"
              onClick={openModelViewer}
              size="small"
              style={{ flex: '1 1 auto' }}
            >
              View 3D
            </Button>
          )
          .on(
            RoleEnum.IMAGE_ENGINEER,
            <>
              {fileType !== FileTypeEnum.MODEL && (
                <>
                  <Button
                    disabled={selected.length === 0}
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={handleDownload}
                    size="small"
                    style={{ flex: '1 1 auto' }}
                  >
                    Download
                  </Button>
                  {(procedure?.status === ProcedureStatusEnum.MODEL_IN_WORK ||
                    procedure?.status === ProcedureStatusEnum.MODEL_READY) && (
                    <>
                      &nbsp;
                      <Button
                        disabled={selected.length === 0 || readOnly}
                        type="button"
                        variant="contained"
                        color="warning"
                        onClick={handleDelete}
                        size="small"
                        style={{ flex: '1 1 auto' }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </>
              )}
              {fileType !== FileTypeEnum.SCAN && (
                <>
                  <Button
                    disabled={selected.length === 0 || readOnly}
                    type="button"
                    variant="contained"
                    color="warning"
                    onClick={handleDelete}
                    size="small"
                    style={{ flex: '1 1 auto' }}
                  >
                    Delete
                  </Button>
                  &nbsp;
                </>
              )}
              {fileType === FileTypeEnum.MODEL && (
                <>
                  <Button
                    disabled={selected.length === 0}
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={openModelViewer}
                    size="small"
                    style={{ flex: '1 1 auto' }}
                  >
                    View 3D
                  </Button>
                  &nbsp;
                </>
              )}
              {fileType !== FileTypeEnum.SCAN && (
                <label htmlFor={fileType} style={{ flex: '1 1 auto' }}>
                  <input
                    style={{ display: 'none' }}
                    id={fileType}
                    name={fileType}
                    type="file"
                    onChange={handleUpload}
                    disabled={readOnly}
                    multiple
                  />

                  <Button
                    color="success"
                    variant="contained"
                    component="span"
                    size="small"
                    style={{ width: '100%' }}
                    disabled={readOnly}
                  >
                    Upload {fileTypeLabelEnum[fileType]}
                  </Button>
                </label>
              )}
            </>
          )
          .on(
            RoleEnum.RADIOLOGIST,
            <>
              <Button
                disabled={selected.length === 0 || readOnly}
                type="button"
                variant="contained"
                color="warning"
                onClick={handleDelete}
                size="small"
                style={{ flex: '1 1 auto' }}
              >
                Delete
              </Button>
              &nbsp;
              {fileType === FileTypeEnum.MODEL && (
                <>
                  <Button
                    disabled={selected.length === 0}
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={openModelViewer}
                    size="small"
                    style={{ flex: '1 1 auto' }}
                  >
                    View 3D
                  </Button>
                  &nbsp;
                </>
              )}
              <label htmlFor={fileType} style={{ flex: '1 1 auto' }}>
                <input
                  style={{ display: 'none' }}
                  id={fileType}
                  name={fileType}
                  type="file"
                  onChange={handleUpload}
                  disabled={readOnly}
                  multiple
                />

                <Button
                  color="success"
                  variant="contained"
                  component="span"
                  size="small"
                  style={{ width: '100%' }}
                  disabled={readOnly}
                >
                  Upload {fileTypeLabelEnum[fileType]}
                </Button>
              </label>
            </>
          )
          .otherwise(
            <>
              <Button
                disabled={selected.length === 0}
                type="button"
                variant="contained"
                color="secondary"
                onClick={handleDownload}
                size="small"
                style={{ flex: '1 1 auto' }}
              >
                Download
              </Button>
              &nbsp;
              <Button
                disabled={selected.length === 0 || readOnly}
                type="button"
                variant="contained"
                color="warning"
                onClick={handleDelete}
                size="small"
                style={{ flex: '1 1 auto' }}
              >
                Delete
              </Button>
              &nbsp;
              {fileType === FileTypeEnum.MODEL && (
                <>
                  <Button
                    disabled={selected.length === 0}
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={openModelViewer}
                    size="small"
                    style={{ flex: '1 1 auto' }}
                  >
                    View 3D
                  </Button>
                  &nbsp;
                </>
              )}
              <label htmlFor={fileType} style={{ flex: '1 1 auto' }}>
                <input
                  style={{ display: 'none' }}
                  id={fileType}
                  name={fileType}
                  type="file"
                  onChange={handleUpload}
                  disabled={readOnly}
                  multiple
                />

                <Button
                  color="success"
                  variant="contained"
                  component="span"
                  size="small"
                  style={{ width: '100%' }}
                  disabled={readOnly}
                >
                  Upload {fileTypeLabelEnum[fileType]}
                </Button>
              </label>
            </>
          )}
      </SC.Buttons>
    </SC.Container>
  );
};
