/* eslint-disable no-unused-vars */
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Stack, TextField, Button, Checkbox, Box } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as UI from 'UI';
import { withGlobalStateUtils } from 'utils/withGlobalStateUtils';
import * as SC from './AssistantAssignment.sc';
import { useAssistantAssignment } from './useAssistantAssignment';

const UserRow = ({
  id,
  nameFirst,
  nameSecond,
  selected,
  handleClickRow,
  filled = false,
}) => (
  <Stack
    key={id}
    direction="row"
    spacing={1}
    justifyContent="space-between"
    alignItems="center"
    className="user-row"
    overflow="auto"
    onClick={() => handleClickRow(id)}
  >
    <SC.UserHolder>
      {filled ? (
        <AccountCircleIcon fontSize="large" />
      ) : (
        <PersonOutlineIcon fontSize="large" />
      )}
      {`${nameFirst} ${nameSecond}`}
    </SC.UserHolder>
    <Checkbox checked={selected.includes(id)} />
  </Stack>
);

export const AssistantAssignment = withGlobalStateUtils(() => {
  const hookData = useAssistantAssignment();
  const {
    isUpdating,
    error,
    assigned,
    available,
    availableSelected,
    handleClickAvailable,
    assignedSelected,
    handleClickAssigned,
    handleAssign,
    handleUnassign,
  } = hookData;

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 40px)" />
      <SC.Header>
        <h3>Assistant Assignment</h3>
      </SC.Header>
      <SC.Wrapper>
        <Stack direction="row" height="100%" spacing={4}>
          <UI.Panel title="Available Assistants">
            <Stack
              direction="column"
              justifyContent="space-between"
              width="100%"
              height="100%"
              spacing={2}
            >
              <Stack direction="column" height="100%" spacing={1}>
                {available.map(({ ...props }) => (
                  <UserRow
                    {...props}
                    selected={availableSelected}
                    handleClickRow={handleClickAvailable}
                  />
                ))}
              </Stack>
              <Button
                onClick={handleAssign}
                type="button"
                variant="contained"
                color="success"
              >
                Assign
              </Button>
            </Stack>
          </UI.Panel>
          <UI.Panel title="Your Assistants">
            <Stack
              direction="column"
              justifyContent="space-between"
              width="100%"
              height="100%"
              spacing={2}
            >
              <Stack direction="column" height="100%" spacing={1}>
                {assigned.map(({ ...props }) => (
                  <UserRow
                    {...props}
                    selected={assignedSelected}
                    handleClickRow={handleClickAssigned}
                    filled
                  />
                ))}
              </Stack>
              <Button
                onClick={handleUnassign}
                type="button"
                variant="contained"
                color="secondary"
              >
                Unassign
              </Button>
            </Stack>
          </UI.Panel>
        </Stack>
      </SC.Wrapper>
    </SC.Container>
  );
});
