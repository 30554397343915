/* eslint-disable prettier/prettier */
/* eslint-disable no-shadow */
import { getUsersListByRole } from '../utils/getUsersListByRole';
import { RoleEnum, RoleLabelEnum, UserStatusEnum } from '../models/constants';

export const useUsersList = users => {
  const filterRow = (user, id) => String(user?.location?.id) === String(id);

  const getUserNameById = id => {
    const user = users?.value
      ? users.value.find(user => String(user.id) === String(id))
      : id;

    return user ? `${user?.nameFirst} ${user?.nameSecond}` : '';
  };

  const getUserListByLocationId = (usersArray, id) =>
    Array.isArray(usersArray)
      ? usersArray.filter(user => filterRow(user, id))
      : [];

  const surgeonListOptions = getUsersListByRole({
    users: users?.value,
    searchRole: RoleEnum.SURGEON,
  });

  const getSurgeonListOptionsByLocationId = locationId =>
    getUsersListByRole({
      users: getUserListByLocationId(users?.value, locationId),
      searchRole: RoleEnum.SURGEON,
    });

  const radiologyListOptions = getUsersListByRole({
    users: users?.value,
    searchRole: RoleEnum.RADIOLOGIST,
  });

  const getRadiologyListOptionsByLocationId = locationId =>
    getUsersListByRole({
      users: getUserListByLocationId(users?.value, locationId),
      searchRole: RoleEnum.RADIOLOGIST,
    });

  const engineerListOptions = getUsersListByRole({
    users: users?.value,
    searchRole: RoleEnum.IMAGE_ENGINEER,
  });

  /**
   * Returns set of user options for Select Field filtered by role and location
   * @param {string} location - current location id
   * @param {string} locationListOptions - location list options
   * @param {string} role - current role id
   * @param {Array} users - array of users
   * @returns {{ [key]: value }} - set of user options sorted by name
   */
  const getUserListOptionsByRoleAndLocation = (
    location,
    locationListOptions,
    role,
    users
  ) => {
    let userList = [...users];
    const options = {};

    if (role !== '' && location !== '') {
      userList = userList.filter(
        user =>
          user.role === role && String(user?.location?.id) === String(location)
      );
    } else if (role === '' && location !== '') {
      userList = userList.filter(
        user => String(user?.location?.id) === String(location)
      );
    } else if (role !== '' && location === '') {
      userList = userList.filter(user => user.role === role);
    }

    userList.forEach(user => {
      const disabled =
        user?.location?.active === false ||
        user?.status === UserStatusEnum.BLOCKED;

      if (role !== '' && location !== '') {
        options[user.id] = {
          name: `${user.nameFirst} ${user.nameSecond}`,
          disabled,
        };
      } else if (role !== '' && location === '') {
        if (locationListOptions[Number(user.location?.id)]?.name) {
          options[user.id] = {
            name: `${user.nameFirst} ${user.nameSecond}, ${
              locationListOptions[Number(user.location?.id)]?.name
            }`,
            disabled,
          };
        } else {
          options[user.id] = {
            name: `${user.nameFirst} ${user.nameSecond}`,
            disabled,
          };
        }
      } else if (role === '' && location !== '') {
        options[user.id] = {
          name: `${user.nameFirst} ${user.nameSecond}, ${
            RoleLabelEnum[user.role]
          }`,
          disabled,
        };
      } else if (locationListOptions[Number(user.location?.id)]?.name) {
        options[user.id] = {
          name: `${user.nameFirst} ${user.nameSecond}, ${
            RoleLabelEnum[user.role]
          }, ${locationListOptions[Number(user.location?.id)]?.name}`,
          disabled,
        };
      } else {
        options[user.id] = {
          name: `${user.nameFirst} ${user.nameSecond}, ${
            RoleLabelEnum[user.role]
          }`,
          disabled,
        };
      }
    });

    return options;
  };

  return {
    surgeonListOptions,
    getSurgeonListOptionsByLocationId,
    radiologyListOptions,
    getRadiologyListOptionsByLocationId,
    engineerListOptions,
    getUserListOptionsByRoleAndLocation,
    getUserNameById,
  };
};
