/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { STLLoader } from 'three-stdlib';
import { Box3, Color } from 'three';
import { STLExporter } from './exporters/STLExplorer';
import Model3D from './SceneElements/Model3D';
import Floor from './SceneElements/Floor';
import Lights from './SceneElements/Lights';
import Camera from './SceneElements/Camera';
import OrbitControls from './SceneElements/OrbitControls';

const CAMERA_POSITION_DISTANCE_FACTOR = 5;
const FLOOR_DISTANCE = 0.4;
const BACKGROUND = new Color('white');

const INITIAL_LATITUDE = Math.PI / 8;
const INITIAL_LONGITUDE = -Math.PI / 8;

const SceneSetup = ({
  url,
  extraHeaders,
  shadows = false,
  showAxes = false,
  orbitControls = false,
  onFinishLoading = () => {},
  modelProps: {
    ref,
    scale = 1,
    positionX,
    positionY,
    rotationX = 0,
    rotationY = 0,
    rotationZ = 0,
    color = 'grey',
  } = {},
  floorProps: { gridWidth, gridLength } = {},
  ambientLightIntensity,
  spotLightIntensity,
  spotLightPosition,
  resetCamera,
}) => {
  const [mesh, setMesh] = useState();

  const [cameraInitialPosition, setCameraInitialPosition] = useState();

  const [meshDims, setMeshDims] = useState({
    width: 0,
    height: 0,
    length: 0,
    boundingRadius: 0,
  });

  const [modelCenter, setModelCenter] = useState([0, 0, 0]);
  const [sceneReady, setSceneReady] = useState(false);

  useEffect(() => {
    setSceneReady(false);
  }, [url]);

  const geometry = useLoader(STLLoader, url, loader =>
    loader.setRequestHeader(extraHeaders ?? {})
  );

  function onLoaded(dims, currentMesh) {
    setMesh(currentMesh);
    const { width, length, height, boundingRadius } = dims;

    setMeshDims(dims);
    setModelCenter([
      positionX ?? width / 2,
      positionY ?? length / 2,
      height / 2,
    ]);
    const maxGridDimension = Math.max(gridWidth ?? 0, gridLength ?? 0);
    const distance =
      maxGridDimension > 0
        ? maxGridDimension
        : boundingRadius * CAMERA_POSITION_DISTANCE_FACTOR;
    setCameraInitialPosition({
      latitude: INITIAL_LATITUDE,
      longitude: INITIAL_LONGITUDE,
      distance,
    });

    onFinishLoading(dims);
    setTimeout(() => setSceneReady(true), 200); // let the three.js render loop place things
  }

  useEffect(() => {
    if (ref == null || mesh == null) return;
    // eslint-disable-next-line no-param-reassign
    ref.current = {
      save: () =>
        new Blob([new STLExporter().parse(mesh, { binary: true })], {
          type: 'application/octet-stream',
        }),
      model: mesh,
    };
  }, [mesh, ref]);

  useFrame(({ scene }) => {
    const currentMesh = scene.getObjectByName('mesh');
    const group = scene.getObjectByName('group');
    const bbox = new Box3().setFromObject(currentMesh);
    const height = bbox.max.z - bbox.min.z;
    group.position.z = height / 2;
  });

  const modelPosition = [
    positionX ?? (meshDims.width * scale) / 2,
    positionY ?? (meshDims.length * scale) / 2,
    0,
  ];

  return (
    <>
      <scene background={BACKGROUND} />
      {sceneReady && showAxes && <axesHelper scale={[50, 50, 50]} />}
      {cameraInitialPosition != null && (
        <Camera initialPosition={cameraInitialPosition} center={modelCenter} />
      )}
      <Model3D
        name="group"
        meshProps={{ name: 'mesh' }}
        scale={scale}
        geometry={geometry}
        position={modelPosition}
        rotation={[rotationX, rotationY, rotationZ]}
        visible={sceneReady}
        materialProps={{ color }}
        // eslint-disable-next-line react/jsx-no-bind
        onLoaded={onLoaded}
      />
      <Floor
        width={gridWidth ?? gridLength}
        length={gridLength ?? gridWidth}
        visible={sceneReady}
        noShadow={!shadows}
        offset={FLOOR_DISTANCE}
      />
      <Lights
        spotLightPosition={spotLightPosition}
        ambientLightIntensity={ambientLightIntensity}
        spotLightIntensity={spotLightIntensity}
      />
      {sceneReady && orbitControls && (
        <OrbitControls shouldReset={resetCamera} target={modelCenter} />
      )}
    </>
  );
};

export default SceneSetup;
