import React from 'react';
import { useGlobalState } from '../../store/GlobalStateContext';
import { Alert } from '../Alert/Alert';
import { Confirm } from '../Confirm/Confirm';
import { GlobalSpinner } from '../GlobalSpinner/GlobalSpinner';

export const AlertConfirmSpinnerProvider = () => {
  const [globalState] = useGlobalState();
  const { alert, spinner, confirm } = globalState;
  return (
    <>
      <Alert data={alert} />
      <Confirm {...confirm} />
      {spinner && <GlobalSpinner />}
    </>
  );
};
