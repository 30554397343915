import styled from '@emotion/styled';
import { Paper as PaperMUI } from '@mui/material';
import * as SC from 'SC/App.sc';

export * from 'SC/App.sc';

const headerBorderRadius = 10;

export const Grid = styled(SC.FlexColumn)`
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const GridRow = styled(SC.FlexRow)`
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const GridItem = styled(SC.FlexRow)`
  align-items: stretch;
  padding: 24px;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
`;

export const ProcedureList = styled(PaperMUI)`
  border-top-left-radius: ${headerBorderRadius}px;
  border-top-right-radius: ${headerBorderRadius}px;
`;

export const ProcedureListHeader = styled.div`
  padding: 20px;
  border-top-left-radius: ${headerBorderRadius}px;
  border-top-right-radius: ${headerBorderRadius}px;
  background-color: #ffeecc;
`;
