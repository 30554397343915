/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, TextField, Button } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import * as UI from 'UI';
import { FileTypeEnum, RoleEnum } from 'models/constants';
import * as SC from 'components/Procedure/Procedure.sc';
import { Information } from 'components/Procedure/Information/Information';
import { Pelvis } from 'components/Procedure/Pelvis/Pelvis';
import { Notes } from 'components/Procedure/Notes/Notes';
import { ListOfFiles } from 'components/Procedure/ListOfFiles/ListOfFiles';

export const ImageEngineerProcedure = ({ hookData }) => {
  const { push } = useHistory();
  const {
    isUpdating,
    id,
    control,
    getUserNameById,
    engineerListOptions,
    handleSubmit,
    onSubmit,
    reloadProcedureFiles,
    globalState,
    dispatchGlobalState,
    watch,
    isNotCurrentEngineer,
    handleUpdateUserIdOnUpload,
    row,
  } = hookData;

  return (
    <SC.Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" width="100%" spacing={4}>
        <Stack direction="row" width="100%" spacing={4}>
          <UI.Panel title="Procedure Information">
            <Information
              hookData={hookData}
              userRole={globalState.user?.value?.role}
            />
          </UI.Panel>
          <UI.Panel title="Pelvis Registration Parameters">
            <Stack direction="column" width="100%" spacing={4}>
              <Pelvis hookData={hookData} />
              <Stack
                direction="column"
                width="100%"
                spacing={2}
                justifyContent="space-between"
              >
                {isNotCurrentEngineer && (
                  <Stack direction="row" width="100%" spacing={2}>
                    {isNotCurrentEngineer ? (
                      <TextField
                        size="small"
                        label="Assigned Imaging Engineer"
                        readOnly
                        value={getUserNameById(watch()?.engineerId) ?? ''}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ flex: '1 1 auto' }}
                      />
                    ) : (
                      <UI.FormSelectField
                        label="Imaging Engineer"
                        name="engineerId"
                        control={control}
                        options={engineerListOptions}
                        disabled={isUpdating}
                      />
                    )}
                    {/* <Button
                      variant="outlined"
                      title="Send message to Engineer"
                      onClick={() => {
                        dispatchGlobalState({
                          type: 'SET_newMessageReceiverId',
                          payload: watch()?.engineerId ?? '',
                        });
                        dispatchGlobalState({
                          type: 'SET_newMessageRole',
                          payload: RoleEnum.IMAGE_ENGINEER,
                        });
                        dispatchGlobalState({
                          type: 'SET_newMessageProcedureId',
                          payload: watch()?.id ?? '',
                        });
                        push('/new_message');
                      }}
                      type="button"
                    >
                      <MailOutlineIcon />
                    </Button> */}
                  </Stack>
                )}
                <ListOfFiles
                  handleReload={reloadProcedureFiles}
                  fileType={FileTypeEnum.MODEL}
                  procedureId={id}
                  handleUpdateUserIdOnUpload={handleUpdateUserIdOnUpload}
                  readOnly={isNotCurrentEngineer}
                  procedure={row?.value}
                />
              </Stack>
            </Stack>
          </UI.Panel>
        </Stack>
        <Stack direction="column" width="100%" spacing={4}>
          <UI.Panel title="Send Message">
            <Button
              variant="contained"
              color="success"
              title="Send message to other user"
              onClick={() => {
                dispatchGlobalState({
                  type: 'SET_newMessageReceiverId',
                  payload: '',
                });
                dispatchGlobalState({
                  type: 'SET_newMessageRole',
                  payload: '',
                });
                dispatchGlobalState({
                  type: 'SET_newMessageProcedureId',
                  payload: watch()?.id ?? '',
                });
                push('/new_message');
              }}
              type="button"
            >
              Send Message &nbsp; <MailOutlineIcon />
            </Button>
          </UI.Panel>
          <UI.Panel title="Notes">
            <Notes procedureId={id} />
          </UI.Panel>
        </Stack>
      </Stack>
    </SC.Form>
  );
};
