import React from 'react';
import { Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

import * as SC from '../../SC/App.sc';

export const GridSearchField = ({
  search,
  handleSearchChange,
  handleSearchCancel,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    <SearchIcon sx={{ mr: 1, my: 0.5 }} />
    <TextField
      id="input-with-sx"
      label="Search:"
      variant="standard"
      value={search}
      onChange={handleSearchChange}
    />
    {search && (
      <SC.VisibleCancelSearch onClick={handleSearchCancel}>
        <CancelIcon />
      </SC.VisibleCancelSearch>
    )}
    {!search && (
      <SC.UnvisibleCancelSearch onClick={handleSearchCancel}>
        <CancelIcon />
      </SC.UnvisibleCancelSearch>
    )}
  </Box>
);
