import styled from '@emotion/styled';
import * as SC from '../../../SC/App.sc';

export { Circle, FlexColumn, FlexRow } from '../../../SC/App.sc';

export const Container = styled(SC.FlexRow)`
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0 0 0;
  width: 100%;
  height: 100%;
  border-bottom: solid thin lightgray;
  border-radius: 10px;
  background-color: ${({ selected }) => (selected ? 'lightblue' : 'unset')};
  &:hover {
    background-color: ${({ selected }) => (selected ? '#7CF' : 'lightyellow')};
  }
  cursor: pointer;
`;

export const Info = styled(SC.FlexColumn)`
  flex: 1 1 auto;
  margin-left: 10px;
  justify-content: center;
  align-items: flex-start;
  label: Info;
`;

export const Name = styled.div`
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
`;
export const Role = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

export const Text = styled.div`
  margin: 0;
  font-family: Poppins;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.859533px;
`;

export const Data = styled(SC.FlexColumn)`
  flex: 0 0 auto;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  width: 80px;
  height: 100%;
  font-size: 12px;
  label: Data;
`;
