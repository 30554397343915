import { getAgeFromDateOfBirth } from './getAgeFromDateOfBirth';

/**
 * Sets new value of Age by setAge callback
 * @param {string} date - string representation of Date
 * @param {Function} setAge - array of record objects with id field
 */

export const recalcAndSetAge = (date, setAge) => {
  const newAge = getAgeFromDateOfBirth(new Date(date).getTime());
  if (newAge < 150) {
    // 150 here is the max patient's age... )))
    setAge(newAge.toString());
    return;
  }
  setAge('');
};
