import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';

import * as SC from 'SC/App.sc';

export { FlexRow } from 'SC/App.sc';

export const Overlay = styled(SC.Overlay)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
`;

export const Container = styled(SC.FlexColumn)`
  justify-content: center;
  align-items: center;

  width: calc(100% - 120px);
  max-width: 600px;
  z-index: 1001;
`;

export const DestinationInstruction = styled.div`
  padding-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
  color: ${grey[700]};
`;

export const Buttons = styled(SC.FlexRow)`
  margin-top: 20px;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 10px 5;
  width: 100%;
`;
