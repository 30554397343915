import styled from '@emotion/styled';
import { LinearProgress as LinearProgressMUI } from '@mui/material';
import { green, red } from '@mui/material/colors';

export const LinearProgress = styled(LinearProgressMUI)`
  height: 30px;
  border-radius: 15px;
  &.error {
    background-color: ${red[500]};
  }

  &.success .MuiLinearProgress-bar {
    background-color: ${green[300]};
  }
`;
