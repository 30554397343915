import styled from '@emotion/styled';
import { Paper as PaperMUI } from '@mui/material';

export * from 'SC/App.sc';

export const Paper = styled(PaperMUI)`
  padding: 20px;
  max-width: 800px;
  border-radius: 20px;
`;
