/* eslint-disable no-param-reassign */

/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useApi } from '../../../hooks/useApi';
import noteApi from '../../../api/note';

export const useNotes = procedureId => {
  const [newNote, setNewNote] = useState('');
  const {
    customData,
    handleCustomApiRequest,
    handleCreateData,
    isUpdating,
    error,
    showAlert,
    globalState,
  } = useApi(noteApi, false);

  const reloadNotesList = () =>
    handleCustomApiRequest({
      requestName: 'getByProcedureId',
      requestData: {
        procedureId,
      },
    });

  const handleAddNewNote = async () => {
    const result = await handleCreateData({
      description: newNote,
      creatorId: globalState?.user?.value?.userId,
      procedureId,
    });

    if (result) {
      showAlert({
        type: 'success',
        text: 'Note was added successfully',
      });
      setNewNote('');
      reloadNotesList();
      return;
    }
    showAlert({
      type: 'error',
      text: 'Note was not added',
    });
  };

  useEffect(() => {
    reloadNotesList();
  }, []);

  return {
    newNote,
    setNewNote,
    notes: customData?.value ?? [],
    handleAddNewNote,
    isUpdating,
    error,
  };
};
