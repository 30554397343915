import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { getOptionsForSelect } from '../../utils/getOptionsForSelect';

export const FormSelectField = ({
  label,
  name,
  control,
  rules = {},
  size = 'small',
  options,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { ref, value, ...inputProps },
        fieldState: { error },
      }) => (
        <FormControl fullWidth error={!!error}>
          <TextField
            select
            value={value ?? ''}
            size={size}
            label={label}
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            {...inputProps}
            {...props}
            variant={props.disabled ? 'filled' : 'outlined'}
          >
            {getOptionsForSelect(options)}
          </TextField>
        </FormControl>
      )}
    />
  );
};
