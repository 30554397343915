import React, { useState } from 'react';
import { Stack, Button } from '@mui/material';
import * as UI from 'UI';
import { AdminRoleLabelEnum, RoleEnum, RoleLabelEnum } from 'models/constants';
import * as FP from 'utils/fp-js';
import { roleValidation } from 'models/validations';
import { useConfirmAction } from 'hooks/useConfirmAction';
import * as SC from './UserCreator.sc';
import { AdminFields } from './FieldSets/AdminFields';
import { UserFields } from './FieldSets/UserFields';
import { EngineerFields } from './FieldSets/EngineerFields';
import { useUserCreator } from './useUserCreator';

export const UserCreator = () => {
  const { confirmAction } = useConfirmAction();
  const [selectedRole, setSelectedRole] = useState('');
  const userCreatorProps = useUserCreator();
  const {
    error,
    isUpdating,
    isDirty,
    trigger,
    handleSubmit,
    onSubmit,
    control,
    setValue,
    userRole,
  } = userCreatorProps;

  const handleChangeRole = e => {
    const role = e?.target?.value;
    setSelectedRole(role);
    setValue('role', role);
  };

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 32px)" />
      <SC.Header>
        <h3>Create New User</h3>
      </SC.Header>
      {isUpdating && <UI.GlobalSpinner />}
      {!isUpdating && (
        <SC.Wrapper>
          <SC.Form>
            <SC.Paper elevation={5}>
              <Stack direction="column" width="100%" spacing={3}>
                <UI.FormSelectField
                  label="Role"
                  name="role"
                  control={control}
                  rules={roleValidation}
                  options={
                    userRole === RoleEnum.ADMIN
                      ? AdminRoleLabelEnum
                      : RoleLabelEnum
                  }
                  onChange={handleChangeRole}
                />
                {selectedRole &&
                  FP.match(selectedRole)
                    .on(
                      [RoleEnum.MASTER_ADMIN, RoleEnum.ADMIN],
                      <AdminFields {...userCreatorProps} />
                    )
                    .on(
                      RoleEnum.IMAGE_ENGINEER,
                      <EngineerFields {...userCreatorProps} />
                    )
                    .otherwise(
                      <UserFields {...userCreatorProps} role={selectedRole} />
                    )}
              </Stack>
            </SC.Paper>
            <SC.Buttons>
              <Button
                disabled={!isDirty || !selectedRole}
                type="button"
                variant="contained"
                color="secondary"
                onClick={async () => {
                  const isValid = await trigger();

                  if (isValid) {
                    confirmAction({
                      text: 'Do you confirm creation?',
                      callbackFn: handleSubmit(onSubmit),
                    });
                  }
                }}
              >
                Create User
              </Button>
            </SC.Buttons>
          </SC.Form>
        </SC.Wrapper>
      )}
    </SC.Container>
  );
};
