import { createApiMethodsSet } from './createApiMethodsSet';
import { httpService } from './httpService';

const api = createApiMethodsSet();

api.create = async ({
  dateOfBirth,
  gender,
  medicalInsuranceId,
  nameFirst,
  nameSecond,
  email,
  phoneNumber,
  creatorId,
}) =>
  httpService({
    url: '/patient/register',
    options: {
      method: 'POST',
      data: {
        dateOfBirth,
        gender,
        medicalInsuranceId,
        nameFirst,
        nameSecond,
        email,
        phoneNumber,
        creatorId,
      },
    },
  });

api.getAll = async () => {
  return httpService({ url: '/patient/actual', options: { method: 'GET' } });
};

api.getDeletedAfterDate = async ({ after }) => {
  return httpService({
    url: `/patient/all-deleted?after=${after}`,
    options: { method: 'GET' },
  });
};

api.save = async data =>
  httpService({
    url: '/patient/update',
    options: {
      method: 'POST',
      data,
    },
  });

api.remove = async id =>
  httpService({
    url: `/patient/${id}`,
    options: {
      method: 'DELETE',
    },
  });

api.getById = async id =>
  httpService({ url: `/patient/get/${id}`, options: { method: 'GET' } });

api.getProceduresById = async id =>
  httpService({ url: `/patient/procedures/${id}`, options: { method: 'GET' } });

export default api;
