/**
 * Returns true if fields of row with the given set of keys contains the search text.
 * @param {{ row: { [x: string]: any; }; search: string; keys: string[]; }} props - object with search data
 * @returns {boolean}
 */
export const containsSearchInRowByKeys = ({ row, search, keys }) => {
  if (!search || search === '' || !keys || keys?.length === 0) return true;

  for (let i = 0; i < keys.length; i += 1) {
    if (Object.prototype.hasOwnProperty.call(row, keys[i])) {
      const text = String(row[keys[i]]).toLowerCase();
      if (text.indexOf(String(search).toLowerCase()) >= 0) return true;
    }
  }
  return false;
};
