import usersIcon from 'images/icon_users.svg';
import usersIconActive from 'images/icon_users_active.svg';
import createIcon from 'images/icon_create.svg';
import createIconActive from 'images/icon_create_active.svg';
import locationIcon from 'images/icon_locations.svg';
import locationIconActive from 'images/icon_locations_active.svg';
import { Procedures } from 'components/Procedures/Procedures';
import { Chats } from 'components/Chats/Chats';
import { SendMessage } from 'components/SendMessage/SendMessage';
import { PatientsManagement } from 'components/PatientsManagement/PatientsManagement';
import { Procedure } from 'components/Procedure/Procedure';
// import { DeletedProcedures } from 'components/DeletedProcedures/DeletedProcedures';
import { PatientRecord } from 'components/PatientRecord/PatientRecord';
import { LocationCreator } from './Locations/LocationCreator/LocationCreator';
import { UserManagement } from './UserManagement/UserManagement';
import { UserCreator } from './UserManagement/UserCreator/UserCreator';
import { Locations } from './Locations/Locations';
import { UserEditor } from './UserManagement/UserEditor/UserEditor';
import { LocationEditor } from './Locations/LocationEditor/LocationEditor';
import { AdminLog } from './AdminLog/AdminLog';

export const menuConfig = [
  {
    path: '/procedures',
    label: 'Procedures',
    activeIcon: usersIconActive,
    icon: usersIcon,
    component: Procedures,
  },
  // {
  //   path: '/deleted_procedures',
  //   label: 'Deleted Procedures',
  //   activeIcon: usersIconActive,
  //   icon: usersIcon,
  //   component: DeletedProcedures,
  // },
  {
    path: '/patients',
    label: 'Patient Management',
    activeIcon: usersIconActive,
    icon: usersIcon,
    component: PatientsManagement,
  },
  {
    path: '/users',
    label: 'User Management',
    activeIcon: usersIconActive,
    icon: usersIcon,
    component: UserManagement,
  },
  {
    path: '/new_user',
    label: 'Create New User',
    activeIcon: createIconActive,
    icon: createIcon,
    component: UserCreator,
  },
  {
    path: '/locations',
    label: 'Location Management',
    activeIcon: locationIconActive,
    icon: locationIcon,
    component: Locations,
  },
  {
    path: '/new_location',
    label: 'Create New Location',
    activeIcon: createIconActive,
    icon: createIcon,
    component: LocationCreator,
  },
  {
    path: '/messages',
    label: 'Messages',
    activeIcon: locationIconActive,
    icon: locationIcon,
    component: Chats,
  },
  {
    path: '/new_message',
    label: 'Send Message',
    activeIcon: locationIconActive,
    icon: locationIcon,
    component: SendMessage,
  },
  {
    path: '/admin_log',
    label: 'Admin Log',
    activeIcon: usersIconActive,
    icon: usersIcon,
    component: AdminLog,
  },
];

export const privateRoute = [
  {
    path: '/procedure/:id',
    component: Procedure,
  },
  {
    path: '/patient/:id',
    component: PatientRecord,
  },
  {
    path: '/edit_user/:id',
    component: UserEditor,
  },
  {
    path: '/edit_location/:id',
    component: LocationEditor,
  },
];
