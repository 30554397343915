import { createTheme } from '@mui/material/styles';
import * as colors from '@mui/material/colors';

// Ajust MUI Theme here:
const adjustedMuiTheme = createTheme({
  palette: {
    primary: {
      main: '#B1B1B1',
    },
    secondary: {
      main: '#343A40',
    },
    success: {
      main: '#B0D4A3',
    },
    text: {
      disabled: colors.grey[700],
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: 'black',
            backgroundColor: '#F1BB69',
          },
        },
      },
    },
  },
});

// Ajust/add User Theme here:
const userTheme = {
  color: {
    red: colors.red[300],
    blue: colors.blue[600],
    white: 'white',
    gray: 'darkgray',
    black: 'black',
    orange: '#F1BB69',
    datagridHeader: '#FEECD9',
  },
  fontSize: {
    default: '16px',
    small: '12px',
  },
  header: {
    height: '56px',
    backgroundColor: '#24292E',
  },
  sidebar: {
    width: '228px',
    backgroundColor: '#24292E',
  },
};

export const theme = { ...adjustedMuiTheme, ...userTheme };
