import styled from '@emotion/styled';
import * as SC from '../../../SC/App.sc';

export const Container = styled(SC.FlexColumn)`
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
`;

export const Row = styled(SC.FlexRow)`
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-top: 1px gray solid;
  cursor: pointer;
  &:hover {
    background-color: #ffffe0;
  }
  min-height: 25px;
`;

export const Selector = styled(SC.FlexColumn)``;

export const Field = styled.div`
  font-size: 12px;
`;
export const Date = styled(Field)`
  width: 80px;
`;

export const Name = styled(Field)`
  flex: 1 0 auto;
  text-align: right;
`;
export const Description = styled(Field)`
  margin: 0 10px;
  flex: 1 1 auto;
  text-align: left;
`;

export const Buttons = styled(SC.FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 10px 5;
  width: 100%;
`;

export const NoNotes = styled(Row)`
  justify-content: center;
  color: darkgray;
  font-size: 0.9em;
`;
