import { createApiMethodsSet } from './createApiMethodsSet';
import { httpService } from './httpService';

const api = createApiMethodsSet();

api.getAll = async ({ after }) =>
  httpService({
    url: after ? `/event/get/all/?after=${after}` : `/event/get/all`,
    options: {
      method: 'GET',
    },
  });

export default api;
