import styled from '@emotion/styled';
import { Box, Slider, Button } from '@mui/material';
import * as SC from 'SC/App.sc';

export { FlexRow } from 'SC/App.sc';

export const colors = [
  '#505050',
  '#707070',
  '#808080',
  '#909090',
  '#655538',
  '#756548',
  '#857558',
  '#a8946f',
  '#715D28',
  '#997f36',
  '#ac8c3b',
  '#c7a547',
  // 'green',
];

export const Overlay = styled(SC.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
`;

export const Container = styled(SC.FlexColumn)`
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 50px;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
`;

export const ModelView = styled(SC.FlexRow)`
  flex: 1 1 auto;
  margin-bottom: 20px;
  justify-content: 'space-between';
`;

export const Holder = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: yellow;
`;

export const Palette = styled(SC.FlexColumn)`
  margin: 0 10px;
  width: 30px;
  height: 100%;
  border-radius: 15px;
`;

export const ColorSample = styled(Box)`
  margin-bottom: 5px;
  width: 20px;
  height: 100%;
  max-height: 20px;
  border-radius: 5px;
  cursor: pointer;
`;

export const SpinnerHolder = styled(SC.FlexColumn)`
  position: absolute;
  width: 100vw;
  height: 100vh;
`;

export const Info = styled(SC.FlexRow)`
  flex: 0 0 auto;
  margin: 0px 50px 0 30px;
  justify-content: space-between;
  width: calc(100% - 80px);
`;

export const Section = styled(SC.FlexColumn)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 250px;
`;
export const Header = styled.h4`
  margin: 0;
`;

export const Inclination = styled(SC.FlexRow)`
  flex: 1 1 auto;
  justify-content: space-between;
  width: 100%;
  max-width: 250px;
`;

export const Anteversion = styled(SC.FlexRow)`
  flex: 1 1 auto;
  justify-content: space-between;
  width: 100%;
  max-width: 250px;
`;

export const Position = styled(SC.FlexRow)`
  flex: 1 1 auto;
  justify-content: space-between;
  width: 100%;
  max-width: 250px;
`;

export const SpotSlider = styled(Slider)``;

export const ResetButton = styled(Button)`
  margin-left: 10%;
  width: 90%;
  font-size: 0.6em !important;
`;
