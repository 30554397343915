import React, { useState } from 'react';
import { Button } from '@mui/material';
import * as FP from 'utils/fp-js';
import { Panel } from 'UI/Panel/Panel';
import * as SC from '../CommonLoaderWindow.sc';
import { LinearProgressWithLabel } from '../LinearProgressWithLabel/LinearProgressWithLabel';

export const LoaderWindow = ({
  loadingComplete = 0,
  isUpload,
  onClose = () => null,
  loadingError = '',
  handleCancelLoading,
}) => {
  const [isLoadingCanceled, setIsLoadingCanceled] = useState(false);
  const values = Object.values(loadingComplete);
  const progressValue = Math.round(values.reduce((acc, next) => acc + next, 0));

  const isLoadingFinished = progressValue === 100;

  const title = FP.match(true)
    .on(isLoadingCanceled && isUpload, 'Upload canceled by the user')
    .on(isLoadingCanceled && !isUpload, 'Download canceled by the user')
    .on(!isLoadingFinished && isUpload && !!loadingError, 'Upload error')
    .on(!isLoadingFinished && isUpload, 'Upload progress')
    .on(isLoadingFinished && isUpload, 'Upload complete')
    .on(!isLoadingFinished && !isUpload && !!loadingError, 'Download error')
    .on(!isLoadingFinished && !isUpload, 'Download progress')
    .on(isLoadingFinished && !isUpload, 'Download complete')
    .otherwise();

  return (
    <SC.Overlay>
      <SC.Container>
        <Panel title={title}>
          <LinearProgressWithLabel
            value={progressValue}
            loadingError={loadingError}
            isLoadingFinished={isLoadingFinished}
          />
          {isLoadingFinished && !isUpload && (
            <SC.DestinationInstruction>
              Please check the default downloads destination in your home
              directory.
            </SC.DestinationInstruction>
          )}
          <SC.Buttons>
            <Button
              disabled={isLoadingFinished || loadingError}
              type="button"
              variant="contained"
              color="warning"
              onClick={() => {
                setIsLoadingCanceled(true);
                handleCancelLoading();
              }}
            >
              Cancel Loading
            </Button>
            &nbsp;
            <Button
              disabled={!isLoadingFinished && !loadingError}
              type="button"
              variant="contained"
              color={loadingError ? 'warning' : 'success'}
              onClick={onClose}
            >
              Close Window
            </Button>
          </SC.Buttons>
        </Panel>
      </SC.Container>
    </SC.Overlay>
  );
};
