/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Button, TextField } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import * as UI from 'UI';
import {
  SideLabelEnum,
  FileTypeEnum,
  RadiologistProcedureStatusEnum,
  RoleEnum,
} from 'models/constants';
import { required } from 'models/validations';
import { ListOfFiles } from '../../ListOfFiles/ListOfFiles';
import { useRadiologistInfo } from './useRadiologistInfo';

export const RadiologistInfo = ({ hookData }) => {
  const { push } = useHistory();
  const {
    id,
    getUserNameById,
    locationListOptions,
    reloadProcedureFiles,
    watch,
    control,
    isUpdating,
    globalState,
    dispatchGlobalState,
    handleUpdateUserIdOnUpload,
    getAllowedStatusListOptions,
    row,
  } = hookData;

  const { handleStatusChange } = useRadiologistInfo({ hookData });

  const isNotCurrentRadiologist =
    !!watch()?.radiologistId &&
    globalState.user.value.userId !== watch().radiologistId;

  return (
    <Stack direction="column" width="100%" spacing={4}>
      <Stack direction="column" width="100%" spacing={2}>
        <Stack direction="row" width="100%" spacing={2}>
          <div style={{ width: '200px' }}>
            <UI.FormStatusSelector
              label="Status"
              name="status"
              control={control}
              rules={{ required }}
              options={getAllowedStatusListOptions(
                RadiologistProcedureStatusEnum
              )}
              disabled={isUpdating || isNotCurrentRadiologist}
              style={{ width: '200px' }}
              handleChange={e =>
                handleStatusChange(e, row?.value?.files, watch)
              }
            />
          </div>
          <TextField
            size="small"
            label="Procedure"
            readOnly
            value={watch()?.name ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ flex: '1 1 auto' }}
            variant="filled"
            disabled
          />
          <TextField
            size="small"
            label="Side"
            readOnly
            value={SideLabelEnum[watch()?.sideSurgeon] ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '100px' }}
            variant="filled"
            disabled
          />
          <TextField
            label="Created"
            size="small"
            readOnly
            InputProps={{
              value: watch()?.creation ?? '',
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '130px' }}
            variant="filled"
            disabled
          />
        </Stack>
        <Stack direction="row" width="100%" spacing={2}>
          <TextField
            size="small"
            label="Surgery Location"
            readOnly
            value={locationListOptions[watch()?.surgeryLocationId]?.name ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ flex: '1 1 auto' }}
            variant="filled"
            disabled
          />
          <TextField
            size="small"
            label="Surgeon"
            readOnly
            value={getUserNameById(watch()?.surgeonId) ?? ''}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ flex: '1 1 auto' }}
            variant="filled"
            disabled
          />
          {/* <Button
            variant="outlined"
            title="Send message to Surgeon"
            onClick={() => {
              dispatchGlobalState({
                type: 'SET_newMessageReceiverId',
                payload: watch()?.surgeonId ?? '',
              });
              dispatchGlobalState({
                type: 'SET_newMessageRole',
                payload: RoleEnum.SURGEON,
              });
              dispatchGlobalState({
                type: 'SET_newMessageLocationId',
                payload: watch()?.surgeryLocationId ?? '',
              });
              dispatchGlobalState({
                type: 'SET_newMessageProcedureId',
                payload: watch()?.id ?? '',
              });
              push('/new_message');
            }}
            type="button"
          >
            <MailOutlineIcon />
          </Button> */}
        </Stack>
        <UI.CtProtocolInfo />
        {isNotCurrentRadiologist && (
          <Stack direction="row" width="100%" spacing={2}>
            <TextField
              size="small"
              label="Radiology Location"
              readOnly
              value={
                locationListOptions[watch()?.radiologyLocationId]?.name ?? ''
              }
              InputLabelProps={{
                shrink: true,
              }}
              style={{ flex: '1 1 auto' }}
              variant="filled"
              disabled
            />
            <TextField
              size="small"
              label="Radiologist"
              readOnly
              value={getUserNameById(watch()?.radiologistId) ?? ''}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ flex: '1 1 auto' }}
              variant="filled"
              disabled
            />
            {/* <Button
              variant="outlined"
              title="Send message to Radiologist"
              onClick={() => {
                dispatchGlobalState({
                  type: 'SET_newMessageReceiverId',
                  payload: watch()?.radiologistId ?? '',
                });
                dispatchGlobalState({
                  type: 'SET_newMessageLocationId',
                  payload: watch()?.radiologyLocationId ?? '',
                });
                dispatchGlobalState({
                  type: 'SET_newMessageRole',
                  payload: RoleEnum.RADIOLOGIST,
                });
                dispatchGlobalState({
                  type: 'SET_newMessageProcedureId',
                  payload: watch()?.id ?? '',
                });
                push('/new_message');
              }}
              type="button"
            >
              <MailOutlineIcon />
            </Button> */}
          </Stack>
        )}
      </Stack>
      <Stack direction="column" width="100%" spacing={2}>
        <ListOfFiles
          handleReload={reloadProcedureFiles}
          handleUpdateUserIdOnUpload={handleUpdateUserIdOnUpload}
          fileType={FileTypeEnum.SCAN}
          procedureId={id}
          readOnly={isNotCurrentRadiologist}
          procedure={row?.value}
        />
      </Stack>
    </Stack>
  );
};
