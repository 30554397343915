/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as FP from 'utils/fp-js';

import { useApi } from 'hooks/useApi';
import locationApi from 'api/location';
import { useGlobalState } from 'store/GlobalStateContext';

export const useLocationEditor = () => {
  const {
    handleSaveData,
    row,
    handleGetDataById,
    isUpdating,
    setIsUpdating,
    error,
    showError,
  } = useApi(locationApi, false);

  const { goBack } = useHistory();
  const [globalState, dispatchGlobalState] = useGlobalState();
  const { id } = useParams();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
    trigger,
  } = useForm({
    id: '',
    name: '',
    address: '',
    phoneNumber: '',
    location: '',
    active: 'active',
  });

  const resetForm = () => {
    const preparedData = {
      id: row?.value?.id || '',
      name: row?.value?.name || '',
      address: row?.value?.address || '',
      phoneNumber: row?.value?.phoneNumber || '',
      location: FP.match(true)
        .on(row?.value?.hasSurgery && row?.value?.hasRadiology, 'both')
        .on(row?.value?.hasSurgery && !row?.value?.hasRadiology, 'surgery')
        .on(!row?.value?.hasSurgery && row?.value?.hasRadiology, 'radiology')
        .otherwise(''),
      active: row?.value?.active ? 'active' : 'disabled',
    };
    reset(preparedData);
  };

  const handleCancelChanges = () => {
    resetForm();
  };

  const onSubmit = async newData => {
    const preparedData = {
      id: newData?.id,
      name: newData?.name,
      address: newData?.address,
      phoneNumber: newData?.phoneNumber,
      hasRadiology:
        newData?.location === 'radiology' || newData?.location === 'both',
      hasSurgery:
        newData?.location === 'surgery' || newData?.location === 'both',
      active: newData?.active === 'active',
    };

    handleSaveData(preparedData);
    dispatchGlobalState({
      type: 'SET_users',
      payload: {},
    });
  };

  useEffect(async () => {
    if (row?.error) {
      showError('No location data');
      return;
    }

    if (!row?.value) {
      await handleGetDataById(id);
    }

    resetForm();
  }, [row]);

  useEffect(() => {
    if (isDirty !== globalState.isDataChanged) {
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: isDirty,
      });
    }
  });

  return {
    handleSubmit,
    onSubmit,
    errors,
    control,
    isDirty,
    trigger,
    isUpdating,
    setIsUpdating,
    error,
    showError,
    handleCancelChanges,
  };
};
