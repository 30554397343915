import React from 'react';
import styled from '@emotion/styled';
import { Paper as PaperMUI, Typography, StepLabel, Box } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import PendingIconMUI from '@mui/icons-material/Pending';
import CheckCircleIconMUI from '@mui/icons-material/CheckCircle';
import CircleIconMUI from '@mui/icons-material/Circle';
import CancelIconMUI from '@mui/icons-material/Cancel';
import ArrowCircleRightIconMUI from '@mui/icons-material/ArrowCircleRight';
import { grey, red } from '@mui/material/colors';
import { ProcedureStatusColorEnum } from 'models/constants';

export const color = {
  disabled: grey[400],
  done: grey[600],
  doneHovered: grey[700],
  previous: grey[700],
  next: grey[700],
  nextHovered: grey[900],
  canceled: red[900],
  warning: grey[600],
};

export const Container = styled(PaperMUI)`
  margin-top: 5px;
  margin-left: 20px;
  padding: 15px 20px 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: calc(100% - 80px);
  min-height: 75px;
  border-radius: 20px;
  z-index: 10;
`;

export const StatusTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    top: '-8px',
    padding: '10px',
    backgroundColor: color.warning,
    borderRadius: '10px',
    color: '#fff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: `1px solid ${color.warning}`,
    '& .MuiTooltip-arrow': {
      color: color.warning,
    },
    cursor: 'pointer',
    userSelect: 'none',
  },
}));

export const Label = styled(Typography)`
  font-size: 1em !important;
  font-weight: bold;
  color: ${({ status }) => ProcedureStatusColorEnum[status]};
`;

export const DoneLabel = styled(Typography)`
  color: ${color.done};

  &.selectable:hover {
    color: ${color.doneHovered};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const PrevLabel = styled(Typography)`
  font-size: 1em !important;
  color: ${color.previous};
`;

export const NextLabel = styled(Typography)`
  font-size: 1em !important;
  color: ${color.next};

  &:hover {
    color: ${color.nextHovered};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const DisabledLabel = styled(Typography)`
  color: ${color.disabled};
`;

export const CanceledLabel = styled(Typography)`
  color: ${color.canceled};
`;

export const CommonStepLabel = styled(StepLabel)`
  user-select: none;

  & .MuiStepLabel-label {
    margin-top: 5px !important;
  }

  & p {
    font-size: 1em;
  }
`;

export const DisabledStepLabel = styled(CommonStepLabel)`
  color: ${color.disabled};
  cursor: default;
`;

export const PrevStepLabel = styled(CommonStepLabel)`
  color: ${color.previous};

  &:hover {
    color: black;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const NextStepLabel = styled(CommonStepLabel)`
  color: ${color.next};

  &:hover {
    color: ${color.nextHovered};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ActiveStepLabel = styled(CommonStepLabel)`
  color: ${({ status }) => ProcedureStatusColorEnum[status]};
  cursor: default;
`;

export const DoneStepLabel = styled(CommonStepLabel)`
  color: ${color.done};
  cursor: default;

  &.selectable:hover {
    color: black;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const CanceledStepLabel = styled(CommonStepLabel)`
  color: ${color.canceled};
  cursor: default;
`;

export const PendingIcon = styled(PendingIconMUI)`
  font-size: 28px;
`;

export const NextIcon = styled(CircleIconMUI)`
  font-size: 28px;
`;

export const CheckCircleIcon = styled(CheckCircleIconMUI)`
  font-size: 28px;
`;

export const CancelIcon = styled(CancelIconMUI)`
  font-size: 28px;
`;

export const ArrowCircleRightIcon = styled(ArrowCircleRightIconMUI)`
  font-size: 28px;
`;

export const Warning = styled(Box)`
  margin-bottom: 5px;
  font-size: 1em;
  color: ${color.next};
`;
