/* eslint-disable no-unused-vars */
import { purgeNullProps } from 'utils/purgeNullProps';
import { createApiMethodsSet } from './createApiMethodsSet';
import { httpService } from './httpService';

const api = createApiMethodsSet();

api.getAll = async ({ role = null }) => {
  return httpService({
    url: '/patient/procedure/all-actual',
    options: { method: 'GET' },
  });
};

api.getById = async id =>
  httpService({
    url: `/patient/procedure/get/${id}`,
    options: {
      method: 'GET',
    },
  });

api.getHistoryById = async id =>
  httpService({
    url: `/event/get/proc/${id}`,
    options: {
      method: 'GET',
    },
  });

api.save = async ({
  id,
  dateOfSurgery,
  name,
  surgeonId,
  surgeryLocationId,
  radiologistId,
  radiologyLocationId,
  engineerId,
  status,
  anatomicAnteVersion,
  anatomicInclination,
  size,
  position,
  plannedAnteVersion,
  plannedInclination,
  operativeAnteVersion,
  operativeInclination,
  legLengthDifference,
  femoralNeckAnteVersion,
  femoralOffsetDifference,
  anterior,
  superior,
  posterior,
  sideSurgeon,
  sideEngineer,
}) =>
  httpService({
    url: '/patient/procedure/update',
    options: {
      method: 'POST',
      data: {
        id,
        dateOfSurgery,
        name,
        surgeonId,
        surgeryLocationId,
        radiologistId,
        radiologyLocationId,
        engineerId,
        status,
        anatomicAnteVersion,
        anatomicInclination,
        size,
        position,
        plannedAnteVersion,
        plannedInclination,
        operativeAnteVersion,
        operativeInclination,
        legLengthDifference,
        femoralNeckAnteVersion,
        femoralOffsetDifference,
        anterior,
        superior,
        posterior,
        sideSurgeon,
        sideEngineer: sideEngineer === '' ? null : sideEngineer,
      },
    },
  });

api.create = async ({
  status,
  patientId,
  creatorId,
  surgeonId,
  surgeryLocationId,
}) =>
  httpService({
    url: '/patient/procedure/plan',
    options: {
      method: 'POST',
      data: {
        status,
        patientId,
        creatorId,
        surgeonId,
        surgeryLocationId,
      },
    },
  });

api.remove = async id =>
  httpService({
    url: `/patient/procedure/delete/${id}`,
    options: {
      method: 'DELETE',
      data: {
        locationId: id,
      },
    },
  });

export default api;
