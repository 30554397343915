import { useGlobalStateUtils } from './useGlobalStateUtils';

export const useConfirmAction = () => {
  const { showConfirm, globalState, dispatchGlobalState } =
    useGlobalStateUtils();

  /**
   * Opens confirmation windowwith given text for action confirmation.
   * @param {{
   *  text?: string;
   *  callbackFn: () => void;
   * }} props
   */
  const confirmAction = ({
    text = 'Do you really want to continue?',
    callbackFn = () =>
      // eslint-disable-next-line no-console
      console.error(
        'Please assign the callback function in the useConfirmAction'
      ),
  }) => {
    showConfirm({
      text,
      no: () => null,
      yes: () => {
        callbackFn();
      },
    });
  };
  return {
    confirmAction,
    globalState,
    dispatchGlobalState,
  };
};
