import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import * as colors from '@mui/material/colors';
import * as SC from '../../SC/App.sc';

export * from '../../SC/App.sc';

export const Holder = styled(SC.FlexRow)`
  padding: 10px;
  align-items: flex-start;
  border-radius: 10px;
  background-color: ${colors.blue[100]};
  user-select: none;
`;

export const Row = styled(SC.FlexRow)`
  justify-content: space-between;
  width: 100%;
`;

const Field = styled.div`
  padding: 5px 20px;
`;

export const Card = styled(Field)``;

export const Name = styled(Field)`
  flex: 1;
  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
`;

export const Age = styled(Field)`
  width: 120px;
  text-align: right;
`;

export const Insurance = styled(Field)``;

export const Email = styled(Field)``;

export const Phone = styled(Field)``;

export const EditButton = styled(IconButton)`
  cursor: pointer;
  & :hover {
    color: green;
  }
`;
