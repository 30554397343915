import styled from '@emotion/styled';
import { Paper as PaperMUI } from '@mui/material';

export * from 'SC/App.sc';

export const Paper = styled(PaperMUI)`
  padding: 20px;
  padding-bottom: 40px;
  width: calc(100% - 40px);
  max-width: 600px;
  border-radius: 20px;
  overflow: hidden;
  overflow-y: auto;
`;
