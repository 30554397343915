/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import styled from '@emotion/styled';
import { Paper as PaperMUI } from '@mui/material';
import { grey } from '@mui/material/colors';
import * as SC from 'SC/App.sc';

export * from 'SC/App.sc';

export const Paper = styled(PaperMUI)`
  margin: 10px 20px 20px 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  label: grid;
`;

export const StatusString = styled(SC.FlexColumn)`
  width: 100%;
  height: 90%;

  font-size: 0.8em;
  letter-spacing: -0.03em;

  color: white;
  background-color: ${({ color = 'lightgray' }) => color};
  border-radius: 5px;
  cursor: default;
`;

export const ButtonSet = styled(SC.FlexRow)`
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 5px 20px 0 20px;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(118, 118, 128, 0.12);
`;

export const SwitchHolder = styled(SC.FlexRow)`
  margin-bottom: 4px;
  justify-content: center;
  align-self: flex-end;

  font-size: 1em;
  color: ${grey[400]};

  border: solid thin white;
  border-radius: 10px;

  &.off:hover {
    color: ${grey[700]};
    border-color: ${grey[400]};
  }

  &.activated {
    color: ${({ theme }) => theme.palette.warning.main};
  }

  &.activated:hover {
    color: ${({ theme }) => theme.palette.warning.dark};
  }
`;
