/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import { useApi } from 'hooks/useApi';
import assistantApi from 'api/assistant';

export const useAssignedSurgeons = () => {
  const { globalState } = useApi(assistantApi, false);
  const { user } = globalState;
  const [assignedSurgeons, setAssignedSurgeons] = useState([]);

  const reloadAssignedSurgeons = async () => {
    const newAssigned = await assistantApi.getAllAssignedByAssistantId({
      id: user?.value?.userId,
    });
    if (newAssigned?.value?.surgeons) {
      setAssignedSurgeons(newAssigned.value.surgeons.map(({ id }) => id));
    }
  };

  useEffect(() => {
    reloadAssignedSurgeons();
  }, [user]);

  return {
    assignedSurgeons,
    reloadAssignedSurgeons,
  };
};
