/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useGlobalState } from 'store/GlobalStateContext';
import { useApi } from 'hooks/useApi';
import patientApi from 'api/patient';
import procedureApi from 'api/procedure';
import { RoleEnum, ProcedureStatusEnum } from 'models/constants';
import { recalcAndSetAge } from 'utils/recalcAndSetAge';
import { getUsersListByRole } from 'utils/getUsersListByRole';

const defaultFormData = {
  nameFirst: '',
  nameSecond: '',
  gender: '',
  dateOfBirth: '',
  age: '',
  medicalInsuranceId: '',
  phoneNumber: '',
};

export const usePatientCreator = () => {
  const [globalState, dispatchGlobalState] = useGlobalState();
  const { user, users } = globalState;
  const [age, setAge] = useState('');
  const [isCommited, setIsCommited] = useState(false);
  const history = useHistory();

  const {
    handleCreateDataAndGetCreated,
    isUpdating,
    setIsUpdating,
    error,
    showError,
  } = useApi(patientApi, false);
  const procedureApiSet = useApi(procedureApi, false);

  const surgeonListOptions = getUsersListByRole({
    users: users?.value,
    searchRole: RoleEnum.SURGEON,
  });

  const radiologyListOptions = getUsersListByRole({
    users: users?.value,
    searchRole: RoleEnum.RADIOLOGIST,
  });

  const engineerListOptions = getUsersListByRole({
    users: users?.value,
    searchRole: RoleEnum.IMAGE_ENGINEER,
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    trigger,
    values,
    getValues,
    reset,
  } = useForm(defaultFormData);

  const onSubmit = async newData => {
    setIsCommited(true);
    setIsUpdating(true);

    // create New Patient and get their id
    const requestData = {
      ...newData,
      dateOfBirth: newData.dateOfBirth.toISOString(),
      creatorId: user?.value?.userId,
    };
    const newPatientResult = await handleCreateDataAndGetCreated(requestData);

    if (newPatientResult?.error) {
      setIsUpdating(false);
      setIsCommited(false);
      return;
    }

    const newPatientId = newPatientResult?.value?.origin?.id;

    // create New Procedure for Patient
    const newProcedureData = {
      status: ProcedureStatusEnum.NEW,
      patientId: newPatientId,
      creatorId: user?.value?.userId,
      surgeonId: user?.value?.userId,
      surgeryLocationId: user?.value?.locationId,
    };
    const newProcedureResult =
      await procedureApiSet.handleCreateDataAndGetCreated(newProcedureData);
    const newProcedureId = newProcedureResult?.value?.id;

    // open newly created procedure to edit
    if (newProcedureId) {
      history.push(`/procedure/${newProcedureId}`);
    }
    setIsUpdating(false);
  };

  useEffect(() => {
    // reset this form to default values with the current user id depends on the current user role
    reset(defaultFormData);
  }, []);

  useEffect(() => {
    document.title = 'Create Patient';
  }, []);

  useEffect(() => {
    if (isCommited && globalState.isDataChanged === true) {
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: false,
      });
    } else if (isDirty !== globalState.isDataChanged && !isCommited) {
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: isDirty,
      });
    }
  });

  useEffect(() => {
    if (isDirty !== globalState.isDataChanged) {
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: isDirty,
      });
    }
  });

  return {
    handleSubmit,
    onSubmit,
    errors,
    register,
    control,
    isDirty,
    trigger,
    reset: () => reset(defaultFormData),
    isUpdating: isUpdating || procedureApiSet?.isUpdating,
    setIsUpdating,
    error: error || procedureApiSet?.error,
    showError,
    values,
    getValues,
    age,
    surgeonListOptions,
    radiologyListOptions,
    engineerListOptions,
    recalcAge: date => recalcAndSetAge(date, setAge),
  };
};
