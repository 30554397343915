/* eslint-disable no-console */
import React from 'react';
import * as COLORS from '@mui/material/colors';
import * as SC from './ActiveStatus.sc';

export const ActiveStatus = props => {
  const {
    row: { active },
  } = props;

  return (
    <SC.Container>
      {active && <span style={{ color: COLORS.green[900] }}>Active</span>}
      {!active && <span style={{ color: COLORS.red[900] }}>Disabled</span>}
    </SC.Container>
  );
};
