/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Button } from '@mui/material';
import * as UI from 'UI';
import { withGlobalStateUtils } from 'utils/withGlobalStateUtils';
import * as SC from './PasswordRecovery.sc';
import { usePasswordRecovery } from './usePasswordRecovery';

function PasswordRecovery(props) {
  const { error } = props;
  const {
    errors,
    apiState,
    usernameRegisterProps,
    onSubmit,
    handleSubmit,
    apiAnswer,
  } = usePasswordRecovery(props);

  if (apiAnswer) {
    return (
      <div className="App-header">
        <SC.FlexColumn style={{ marginBottom: '10vh' }}>
          <UI.ErrorBox text={error} width="calc(100% - 30px)" />
          <SC.LoginForm>
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <UI.ErrorString text={message} />}
            />
            {apiState === 'loading' && (
              <UI.Spinner style={{ alignSelf: 'center' }} />
            )}
            {apiState !== 'loading' && (
              <>
                <SC.Message>
                  <h3>Link was sent.</h3>
                  Check your email for the password reset instructions.
                </SC.Message>
                <SC.Link to="/login">
                  <Button
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                  >
                    Ok
                  </Button>
                </SC.Link>
              </>
            )}
          </SC.LoginForm>
        </SC.FlexColumn>
      </div>
    );
  }

  return (
    <div className="App-header">
      <SC.FlexColumn style={{ marginBottom: '10vh' }}>
        <h6>Enter your email for password recovery:</h6>
        <form onSubmit={handleSubmit(onSubmit)} className="mui-form">
          <UI.ErrorBox text={error} width="calc(100% - 30px)" />
          <SC.LoginForm>
            <SC.Input
              type="text"
              placeholder="Email"
              autoComplete="new-username"
              {...usernameRegisterProps}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <UI.ErrorString text={message} />}
            />

            {apiState === 'loading' && (
              <UI.Spinner style={{ alignSelf: 'center' }} />
            )}
            {apiState !== 'loading' && (
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                Request recovery link
              </Button>
            )}
            <SC.Link to="/login">Log in</SC.Link>
          </SC.LoginForm>
        </form>
      </SC.FlexColumn>
    </div>
  );
}

export default withGlobalStateUtils(PasswordRecovery);
