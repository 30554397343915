/* eslint-disable no-console */
import React from 'react';
import * as COLORS from '@mui/material/colors';
import * as SC from './UserStatusCell.sc';
import {
  UserStatusLabelEnum,
  UserStatusEnum,
} from '../../../../models/constants';

export const UserStatusCell = props => {
  const {
    row: { status },
  } = props;

  return (
    <SC.Container>
      {!status && (
        <span style={{ color: COLORS.lightBlue[900] }}>No status</span>
      )}
      {status === UserStatusEnum.ACTIVE && (
        <span style={{ color: COLORS.green[900] }}>
          {UserStatusLabelEnum[status]}
        </span>
      )}
      {status === UserStatusEnum.BLOCKED && (
        <span style={{ color: COLORS.red[900] }}>
          {UserStatusLabelEnum[status]}
        </span>
      )}
      {status === UserStatusEnum.PENDING && (
        <span style={{ color: COLORS.orange[700] }}>
          {UserStatusLabelEnum[status]}
        </span>
      )}
    </SC.Container>
  );
};
