import { useGlobalStateApiProperty } from '../store/useGlobalStateApiProperty';
import userApi from '../api/user';

export const useUsersApi = () => {
  const [users, setUsers, apiUsersState] = useGlobalStateApiProperty({
    key: 'users',
    apiCallFn: () => userApi.getAll(),
  });

  const handleReloadUsers = () => {
    setUsers({});
  };

  return {
    users,
    setUsers,
    apiUsersState,
    handleReloadUsers,
  };
};
