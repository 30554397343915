import usersIcon from 'images/icon_users.svg';
import usersIconActive from 'images/icon_users_active.svg';
import locationIcon from 'images/icon_locations.svg';
import locationIconActive from 'images/icon_locations_active.svg';
import { Procedures } from 'components/Procedures/Procedures';
import { Chats } from 'components/Chats/Chats';
import { SendMessage } from 'components/SendMessage/SendMessage';
import { Procedure } from 'components/Procedure/Procedure';

export const menuConfig = [
  {
    path: '/procedures',
    label: 'Procedures',
    activeIcon: usersIconActive,
    icon: usersIcon,
    component: Procedures,
  },
  {
    path: '/messages',
    label: 'Messages',
    activeIcon: locationIconActive,
    icon: locationIcon,
    component: Chats,
  },
  {
    path: '/new_message',
    label: 'Send Message',
    activeIcon: locationIconActive,
    icon: locationIcon,
    component: SendMessage,
  },
];

export const privateRoute = [
  {
    path: '/procedure/:id',
    component: Procedure,
  },
];
