/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Stack, Button } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import * as UI from 'UI';
import { FileTypeEnum, RoleEnum } from 'models/constants';
import * as SC from '../../Procedure/Procedure.sc';
import { Information } from '../../Procedure/Information/Information';
import { Pelvis } from '../../Procedure/Pelvis/Pelvis';
import { Surgery } from '../../Procedure/Surgery/Surgery';
import { Notes } from '../../Procedure/Notes/Notes';
import { ListOfFiles } from '../../Procedure/ListOfFiles/ListOfFiles';

export const AdminProcedure = ({ hookData }) => {
  const { push } = useHistory();
  const {
    row,
    isUpdating,
    id,
    control,
    engineerListOptions,
    handleSubmit,
    onSubmit,
    reloadProcedureFiles,
    dispatchGlobalState,
    watch,
    handleUpdateUserIdOnUpload,
    columns,
    rows,
  } = hookData;

  return (
    <SC.Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" width="100%" spacing={3} paddingBottom={2}>
        <Stack direction="row" width="100%" spacing={3}>
          <Stack direction="column" width="100%" spacing={2}>
            <UI.Panel title="Procedure Information">
              <Information
                hookData={hookData}
                userRole={RoleEnum.MASTER_ADMIN}
              />
            </UI.Panel>
            <UI.Panel title="Send Message">
              <Button
                variant="contained"
                color="success"
                title="Send message to other user"
                onClick={() => {
                  dispatchGlobalState({
                    type: 'SET_newMessageReceiverId',
                    payload: '',
                  });
                  dispatchGlobalState({
                    type: 'SET_newMessageRole',
                    payload: '',
                  });
                  dispatchGlobalState({
                    type: 'SET_newMessageProcedureId',
                    payload: watch()?.id ?? '',
                  });
                  push('/new_message');
                }}
                type="button"
              >
                Send Message &nbsp; <MailOutlineIcon />
              </Button>
            </UI.Panel>
          </Stack>
          <UI.Panel title="Pelvis Registration Parameters">
            <Stack direction="column" width="100%" spacing={4}>
              <Stack direction="column" width="100%" spacing={2}>
                <Stack direction="row" width="100%" spacing={2}>
                  <UI.FormSelectField
                    label="Assigned Imaging Engineer"
                    name="engineerId"
                    control={control}
                    options={engineerListOptions}
                    disabled={isUpdating || watch()?.deleted}
                  />
                </Stack>
                <Pelvis hookData={hookData} />
                <ListOfFiles
                  handleReload={reloadProcedureFiles}
                  fileType={FileTypeEnum.MODEL}
                  procedureId={id}
                  handleUpdateUserIdOnUpload={handleUpdateUserIdOnUpload}
                  procedure={row?.value}
                  readOnly={watch()?.deleted}
                />
              </Stack>
            </Stack>
          </UI.Panel>
        </Stack>
        <Stack direction="row" width="100%" spacing={3}>
          <UI.Panel title="Surgery">
            <Surgery hookData={hookData} />
          </UI.Panel>
          <Stack direction="column" width="100%" spacing={3}>
            <UI.Panel title="Notes">
              <Notes procedureId={id} isDisabled={watch()?.deleted} />
            </UI.Panel>
          </Stack>
        </Stack>
        <UI.Panel title="Procedure History" sx={{ height: '500px' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            // pageSize={25}
            rowsPerPageOptions={[25, 50, 100]}
            disableSelectionOnClick
            density="compact"
            // onSelectionModelChange={handleSelectionChange}
            disableColumnFilter
            disableColumnMenu
            className={SC.DataGridClass}
            loading={isUpdating}
          />
        </UI.Panel>
      </Stack>
    </SC.Form>
  );
};
