/* eslint-disable react/no-unknown-property */
import React, { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { Vector3 } from 'three';

const FOCAL_LENGTH = 35;

function clamp(min, value, max) {
  return Math.max(Math.min(value, max), min);
}

const EPS = 0.01;

function polarToCartesian(polar) {
  const latitude = clamp(-Math.PI / 2 + EPS, polar.latitude, Math.PI / 2 - EPS);
  const longitude = clamp(-Math.PI + EPS, polar.longitude, Math.PI - EPS);
  return [
    polar.distance * Math.cos(latitude) * Math.sin(longitude),
    -polar.distance * Math.cos(longitude) * Math.cos(latitude),
    polar.distance * Math.sin(latitude),
  ];
}

const Camera = ({ initialPosition, center, ...otherProps }) => {
  const { camera } = useThree();
  useEffect(() => {
    camera.setFocalLength(FOCAL_LENGTH);
    camera.up.applyAxisAngle(new Vector3(1, 0, 0), Math.PI / 2);
  }, [camera]);

  useEffect(() => {
    const coords = polarToCartesian(initialPosition);
    camera.position.set(
      coords[0] + center[0],
      coords[1] + center[1],
      coords[2] + center[2]
    );
    camera.lookAt(...center);
  }, [camera, center]);

  return <perspectiveCamera near={1} far={1000} {...otherProps} />;
};

export default Camera;
