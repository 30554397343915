import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import * as UI from 'UI';
import { convertToLocaleDate } from 'utils/dateTimeUtils';
import { sortRowsByIdDesc } from 'utils/sortRowsByIdDesc';
import {
  SideLabelEnum,
  ProcedureStatusLabelEnum,
  ProcedureStatusColorEnum,
} from 'models/constants';
import * as SC from './Procedures.sc';

export const Procedures = ({
  procedureList,
  handleCreateNewProcedure,
  isNewProcedureAllowed,
  isUpdating,
  isAllowedToCreateNew,
}) => {
  return (
    <UI.Panel
      title={
        <SC.FlexRow style={{ justifyContent: 'space-between', width: '100%' }}>
          Procedures
        </SC.FlexRow>
      }
    >
      {procedureList.length > 0 && (
        <SC.Content>
          {sortRowsByIdDesc(procedureList).map(row => {
            return (
              <SC.Item key={row?.id}>
                <SC.Name>
                  {row.name
                    ? `${SideLabelEnum[row?.sideSurgeon]} ${row.name}`
                    : 'Procedure has no data'}
                </SC.Name>
                <SC.Date>
                  {!!row.dateOfSurgery &&
                    convertToLocaleDate(row.dateOfSurgery)}
                </SC.Date>
                <SC.StatusWrapper>
                  <SC.StatusIndicator
                    color={ProcedureStatusColorEnum[row.status]}
                  >
                    {ProcedureStatusLabelEnum[row.status]}
                  </SC.StatusIndicator>
                </SC.StatusWrapper>
                <Link to={`/procedure/${row.id}`}>
                  <SC.PlayArrowIcon />
                </Link>
              </SC.Item>
            );
          })}
        </SC.Content>
      )}
      {isNewProcedureAllowed(procedureList) && !isUpdating && (
        <SC.ButtonHolder>
          {procedureList.length === 0 && (
            <SC.FlexColumn>This patient has no procedures yet.</SC.FlexColumn>
          )}
          {isAllowedToCreateNew && (
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleCreateNewProcedure}
            >
              Create new procedure
            </Button>
          )}
        </SC.ButtonHolder>
      )}
    </UI.Panel>
  );
};
