import React from 'react';
import { Stack, Button, TextField } from '@mui/material';
import { RoleLabelEnum } from 'models/constants';
import { convertToLocaleDate } from 'utils/dateTimeUtils';
import * as SC from './Notes.sc';

import { useNotes } from './useNotes';

const Item = ({ note }) => {
  return (
    <SC.Row>
      <SC.Date>{convertToLocaleDate(note.creation)}</SC.Date>
      <SC.Description>{note.description}</SC.Description>
      <SC.Name>
        {RoleLabelEnum[note.user.role]}: {note.user.nameFirst}{' '}
        {note.user.nameSecond}
      </SC.Name>
    </SC.Row>
  );
};

export const Notes = ({ procedureId, isDisabled }) => {
  const { newNote, setNewNote, handleAddNewNote, notes } =
    useNotes(procedureId);

  return (
    <Stack direction="column" width="100%" spacing={2}>
      <SC.Container>
        {notes?.length === 0 && <SC.NoNotes>No notes</SC.NoNotes>}
        {notes.map(note => (
          <Item key={note?.id} note={note} />
        ))}
      </SC.Container>
      <Stack direction="column" width="100%" spacing={2}>
        <TextField
          label="New note text"
          multiline
          value={newNote}
          onChange={event => setNewNote(event?.target?.value)}
          disabled={isDisabled}
        />
        <SC.Buttons>
          <Button
            color="success"
            variant="contained"
            component="span"
            size="small"
            style={{ width: '100%' }}
            onClick={handleAddNewNote}
            disabled={newNote === '' || isDisabled}
          >
            Add note
          </Button>
        </SC.Buttons>
      </Stack>
    </Stack>
  );
};
