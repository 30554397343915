/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react';
import { Box3, DoubleSide, Matrix4 } from 'three';
import { useFrame } from '@react-three/fiber';

const Model3D = ({
  scale = 1,
  visible = false,
  geometry,
  meshProps,
  materialProps: { opacity = 1, ...otherMaterialProps },
  onLoaded,
  ...otherProps
}) => {
  const mesh = useRef(null);
  const group = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, [geometry]);

  useFrame(() => {
    if (
      !loading ||
      geometry.boundingSphere == null ||
      mesh.current == null ||
      group.current == null
    )
      return;
    new Box3().setFromObject(mesh.current); // this appears to set the correct property on geometry.boundingBox
    const { min, max } = geometry.boundingBox ?? {
      min: { x: 0, y: 0, z: 0 },
      max: { x: 0, y: 0, z: 0 },
    };
    geometry.computeVertexNormals();
    const dims = {
      width: max.x - min.x,
      length: max.y - min.y,
      height: max.z - min.z,
    };
    geometry.applyMatrix4(
      new Matrix4().makeTranslation(
        -min.x - dims.width / 2,
        -min.y - dims.length / 2,
        -min.z - dims.height / 2
      )
    );
    onLoaded(
      {
        ...dims,
        boundingRadius: geometry.boundingSphere.radius,
      },
      mesh.current,
      group.current
    );
    setLoading(false);
  });

  return (
    <group ref={group} {...otherProps}>
      <mesh ref={mesh} scale={[scale, scale, scale]} castShadow {...meshProps}>
        <primitive object={geometry} attach="geometry" />
        <meshStandardMaterial
          side={DoubleSide}
          opacity={visible ? opacity : 0}
          {...otherMaterialProps}
        />
      </mesh>
    </group>
  );
};

export default Model3D;
