import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export const Button = styled(IconButton)`
  cursor: pointer;
`;

export const EditButton = styled(IconButton)`
  cursor: pointer;
  & :hover {
    color: green;
  }
`;

export const DeleteButton = styled(IconButton)`
  cursor: pointer;
  & :hover {
    color: red;
  }
`;
