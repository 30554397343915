/* eslint-disable no-console */
/* eslint-disable prefer-template */
import { config } from '../config/config';
import { localStorageNameEnum } from '../models/constants';

/*    
    This service returns only two variants of the result:
    
      1) In case of successful result: 
      {
        value: <returned value>,
      }

      2) When something goes wrong: 
      {
        error: <error message>,
      }
 */

/**
 * @param {{
 *  url: string;
 *  options?: any;
 *  token?: string;
 *  dispatchGlobalState: () => void | undefined;
 * }} props
 */
export const httpService = async ({
  url,
  options,
  token,
  dispatchGlobalState,
}) => {
  const storedToken = localStorage.getItem(localStorageNameEnum.AUTH_TOKEN);
  const { data } = options;

  const headers = {
    'Content-Type': 'application/json',
  };

  if (token !== undefined) {
    headers.Authorization = `Bearer ${token}`;
  } else if (storedToken) {
    headers.Authorization = `Bearer ${storedToken}`;
  }

  try {
    const response = await fetch(`${config.API_URL}${url}`, {
      ...options,
      headers,
      body: JSON.stringify(data),
    });

    const isJson = response.headers.get('Content-Type') === 'application/json';

    if (response.status !== 200) {
      if (response.status === 401) {
        if (dispatchGlobalState) {
          dispatchGlobalState({ type: 'LOGOUT' });
        }

        return {
          error: 'Session expired',
        };
      }
      if (isJson) {
        try {
          const json = await response.json();
          throw new Error(json?.message || '');
        } catch (error) {
          throw new Error(`${response.status ?? ''} ${error.message}`);
        }
      }
      throw new Error(JSON.stringify(response));
    }

    const json = await response.json();
    if (json?.error) {
      throw new Error(json.error.message);
    }
    return {
      value: json,
    };
  } catch (error) {
    return {
      error: String(error.message).replace(/Error:/g, ''),
      // error: 'Network error'
    };
  }
};
