/* eslint-disable no-console */
import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useApi } from 'hooks/useApi';
import userApi from 'api/user';
import { useLocationList } from 'hooks/useLocationList';
import { RoleEnum } from 'models/constants';

export const useUserCreator = () => {
  const { goBack } = useHistory();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    globalState,
    dispatchGlobalState,
    error,
    showError,
    isUpdating,
    setIsUpdating,
    handleCreateData,
  } = useApi(userApi, false);
  const {
    locationsData,
    locationListOptions,
    radiologyLocationListOptions,
    surgeryLocationListOptions,
  } = useLocationList();

  const defaultValue = useMemo(
    () => ({
      role: '',
      nameFirst: '',
      nameSecond: '',
      LocationId: '',
      address: '',
      phoneNumber: '',
      email: '',
      password: '',
      status: 'ACTIVE',
    }),
    []
  );

  const {
    register,
    control,
    reset,
    handleSubmit,
    values,
    getValues,
    setValue,
    formState: { errors, isDirty },
    trigger,
    watch,
  } = useForm({
    defaultValue,
  });

  const onSubmit = async requestData => {
    const postData = requestData;
    if (postData?.role === RoleEnum.MASTER_ADMIN) {
      delete postData.LocationId;
      delete postData.phoneNumber;
    }

    if (postData?.role === RoleEnum.IMAGE_ENGINEER) {
      delete postData.LocationId;
    }

    const result = await handleCreateData(postData);
    if (!result) return false;

    reset();

    dispatchGlobalState({
      type: 'SET_users',
      payload: {},
    });
    goBack();

    return true;
  };

  useEffect(() => {
    reset(defaultValue);
  }, []);

  useEffect(() => {
    document.title = 'Create New User';
  }, []);

  useEffect(() => {
    if (isDirty !== globalState.isDataChanged) {
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: isDirty,
      });
    }
  });

  return {
    isUpdating,
    setIsUpdating,
    error,
    showError,
    handleSubmit,
    onSubmit,
    isPasswordVisible,
    setIsPasswordVisible,
    errors,
    isDirty,
    trigger,
    values,
    getValues,
    setValue,
    register,
    reset,
    control,
    watch,
    locationsData,
    locationListOptions,
    radiologyLocationListOptions,
    surgeryLocationListOptions,
    userRole: globalState?.user?.value?.role,
  };
};
