/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useGlobalStateUtils } from 'hooks/useGlobalStateUtils';
import { useStatusFlow } from 'components/Procedure/StatusFlow/useStatusFlow';

export const useEngineerInfo = ({ hookData }) => {
  const { showAlert } = useGlobalStateUtils();
  const { getStatusChangePermission } = useStatusFlow({ hookData });

  const handleStatusChange = (e, files = [], watch) => {
    const { status } = watch();
    const newStatus = e.target.value;

    const permission = getStatusChangePermission(newStatus);
    if (!permission.enabled) {
      showAlert({
        type: 'warning',
        text: permission.reason,
      });
      e.target.value = status;
    }
  };

  return {
    handleStatusChange,
  };
};
