import styled from '@emotion/styled';
import * as SC from '../../../SC/App.sc';

export const Container = styled(SC.FlexColumn)`
  & h5 {
    margin: 0;
    width: 100%;
  }
`;

export const Row = styled(SC.FlexRow)`
  justify-content: space-between;

  margin: 0;
  width: 100%;
  border-top: 1px gray solid;
  cursor: pointer;
  &:hover {
    background-color: #ffffe0;
  }
`;

export const Selector = styled(SC.FlexColumn)`
  flex: 0 0 auto;
  width: 30xp;
`;

export const Field = styled.div`
  margin: 0 3px 0 3px;
  font-size: 12px;
`;
export const Date = styled(Field)`
  flex: 0 0 auto;
  width: 80px;
`;

export const FileName = styled(Field)`
  flex: 1 1 auto;
  text-align: left;
  word-wrap: break-word;
`;
export const Description = styled(Field)`
  flex: 1 1 auto;
  justify-self: flex-end;
  text-align: left;
  word-wrap: break-word;
  text-align: right;
`;

export const Buttons = styled(SC.FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 10px 5;
  width: 100%;
`;

export const NoFiles = styled(Row)`
  justify-content: center;
  color: darkgray;
  font-size: 0.9em;
`;
