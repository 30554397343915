/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGlobalStateApiProperty } from 'store/useGlobalStateApiProperty';
import * as authApi from 'api/auth';
import { config } from 'config/config';
import { emailValidation } from 'models/validations';

export const usePasswordRecovery = props => {
  const { showError, hideError, showSpinner, hideSpinner } = props;
  const [apiAnswer, setApiAnswer] = useState('');
  const [data, setData] = useState();
  const { register, handleSubmit, formState } = useForm();
  const { errors } = formState;
  const [user, setUser, apiState] = useGlobalStateApiProperty({
    key: 'user',
    apiCallFn: () => authApi.login(data),
  });

  const usernameRegisterProps = register('email', emailValidation);

  const onSubmit = async newData => {
    showSpinner();
    hideError();

    const result = await authApi.requestPasswordRecoveryLink(newData);

    if (result?.value?.message) {
      setApiAnswer(result?.value?.message);
    } else {
      setApiAnswer('Something went wrong');
    }
    hideSpinner();
  };

  useEffect(() => {
    if (apiState === 'error') {
      showError(user?.error);
    }
  }, [user]);

  return {
    errors,
    apiState,
    usernameRegisterProps,
    version: config.version,
    handleSubmit,
    onSubmit,
    apiAnswer,
    setApiAnswer,
  };
};
