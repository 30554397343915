/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Panel } from 'UI/Panel/Panel';
import { useGlobalState } from 'store/GlobalStateContext';
import { getRecordById } from 'utils/getRecordById';
import { RoleLabelEnum } from 'models/constants';

import * as SC from './Preview.sc';

export const Preview = ({ closePreview, newMessages }) => {
  const [globalState] = useGlobalState();
  const { push } = useHistory();
  const { users } = globalState;
  const MAX_LENGTH_OF_LAST_TEXT_MESSAGE = 20;

  const shorterText = text =>
    text?.length > 15
      ? `${text.substr(0, MAX_LENGTH_OF_LAST_TEXT_MESSAGE)}...`
      : text;

  const autors = newMessages.reduce(
    (acc, next) =>
      next?.senderId && acc.includes(next?.senderId)
        ? acc
        : [...acc, next?.senderId],
    []
  );

  return (
    <SC.Overlay onClick={closePreview}>
      <SC.Container
        onClick={e => {
          if (e?.target?.tagName?.toLowerCase() === 'div') e.stopPropagation();
        }}
      >
        <Panel
          title={
            <SC.PanelTitle>
              New Messages
              <SC.Circle>{newMessages.length}</SC.Circle>
            </SC.PanelTitle>
          }
          handleCloseIcon={closePreview}
        >
          <SC.Content>
            <SC.Messages>
              {autors.map(senderId => {
                const sender = getRecordById(senderId, users?.value);
                const authorMessages = newMessages.filter(
                  row => row.senderId === senderId
                );
                const message = authorMessages.reduce(
                  (acc, next) => next.text,
                  ''
                );

                const fullName = `${sender?.nameFirst} ${sender?.nameSecond}`;

                return (
                  <SC.Message key={`${senderId}-${message}`}>
                    <AccountCircleIcon sx={{ fontSize: 35 }} />
                    <SC.Info>
                      <SC.Name>
                        <div>{fullName ? `${fullName}, ` : ''}</div>
                        &nbsp;
                        <SC.Role>{RoleLabelEnum[sender?.role]}</SC.Role>
                        <SC.SmallCircle>{authorMessages.length}</SC.SmallCircle>
                      </SC.Name>
                      <SC.Text>{shorterText(message)}</SC.Text>
                    </SC.Info>
                  </SC.Message>
                );
              })}
            </SC.Messages>
            <br />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => push('/messages')}
            >
              Open Chats
            </Button>
          </SC.Content>
        </Panel>
      </SC.Container>
    </SC.Overlay>
  );
};
