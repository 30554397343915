import styled from '@emotion/styled';
import * as SC from '../../SC/App.sc';

export const Container = styled(SC.FlexRow)`
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 16px 26px 12px;
  width: calc(100% - 32px);

  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.sidebar.backgroundColor};
`;

export const Info = styled(SC.FlexColumn)`
  justify-content: center;
  align-items: flex-start;
`;

export const Name = styled.div`
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.8595330119132996px;
  text-align: left;
`;

export const Role = styled.div`
  margin: 0;
  font-family: Poppins;

  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.859533px;
  color: #b7b7b7;
`;
