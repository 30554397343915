import { httpService } from './httpService';
import { localStorageNameEnum } from '../models/constants';

export const login = async ({ username, password }) =>
  httpService({
    url: '/auth/access',
    options: {
      method: 'POST',
      data: {
        username,
        password,
      },
    },
  });

export const restoreAuthByToken = async () =>
  httpService({
    url: '/auth/refresh',
    options: {
      method: 'GET',
    },
    token: localStorage.getItem(localStorageNameEnum.REFRESH_AUTH_TOKEN),
  });

export const requestPasswordRecoveryLink = async ({ email }) =>
  httpService({
    url: '/user/request-reset',
    options: {
      method: 'POST',
      data: {
        email,
      },
    },
  });

export const getRecoveryAuthByToken = async ({ token }) =>
  httpService({
    url: `/user/forgot-password?token=${token}`,
    options: {
      method: 'GET',
    },
  });
