import styled from '@emotion/styled';
import { Paper as PaperMUI } from '@mui/material';

export * from 'SC/App.sc';

export const Paper = styled(PaperMUI)`
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  label: grid;
`;
