import React from 'react';
import { PCFSoftShadowMap } from 'three';
import { Canvas } from '@react-three/fiber';
import ErrorBoundary from 'UI/ErrorBoundary/ErrorBoundary';
import SceneSetup from './SceneSetup';

export const StlViewer = ({
  url,
  modelProps,
  floorProps,
  children,
  onError,
  extraHeaders,
  onFinishLoading,
  canvasId,
  shadows,
  showAxes,
  orbitControls,
  ambientLightIntensity,
  spotLightPosition,
  spotLightIntensity,
  resetCamera,
  ...otherProps
}) => {
  const sceneProps = {
    url,
    modelProps,
    floorProps,
    extraHeaders,
    onFinishLoading,
    shadows,
    showAxes,
    orbitControls,
    ambientLightIntensity,
    spotLightPosition,
    spotLightIntensity,
    resetCamera,
  };

  return (
    <div {...otherProps}>
      <ErrorBoundary onError={onError}>
        <React.Suspense fallback={null}>
          <Canvas
            shadows
            gl={{
              preserveDrawingBuffer: true,
              shadowMapType: PCFSoftShadowMap,
              antialias: true,
            }}
            id={canvasId}
            style={{ width: '100%', height: '100%' }}
          >
            <SceneSetup {...sceneProps} />
            {children}
          </Canvas>
        </React.Suspense>
      </ErrorBoundary>
    </div>
  );
};
