import styled from '@emotion/styled';
import * as SC from 'SC/App.sc';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  width: 50px;
  cursor: pointer;
`;

export const Circle = styled(SC.Circle)`
  position: relative;
  top: -10px;
  left: -10px;
`;
