/**
 * Returns state of API global state property
 * @param {{ value?: any; error?: string}} state - state object
 * @returns {'error'|'ready'|'loading'}
 */
export const getApiDataState = state => {
  if (state?.error) return 'error';
  if (!state?.error && !Object.prototype.hasOwnProperty.call(state, 'value'))
    return 'loading';
  return 'ready';
};

/**
 * Loads data from the API call function to the global state using setState callback.
 * @param {Function} apiCall - API call function.
 * @param {{ value?: any; error?: string}} state - state object
 * @param {Function} setState - state object set function
 */
export const syncApiData = async (apiCall, state, setState) => {
  if (getApiDataState(state) === 'loading') {
    await apiCall().then(setState, setState);
  }
};

/**
 * Loads data from the API call function to the local state using setState callback with error handling callbacks
 * @param {{
 * apiCall: Function;
 * requestData: any;
 * setData: Function;
 * showError: Function;
 * hideError: Function;
 * setIsUpdating: Function;
 * }} - set/reset isUpdating flag
 */
export const loadApiData = async ({
  apiCall = () => undefined,
  requestData = {},
  setData = () => undefined,
  showError = () => undefined,
  hideError = () => undefined,
  setIsUpdating = () => undefined,
  showAlert = () => undefined,
}) => {
  hideError();
  setIsUpdating(true);

  const result = await apiCall(requestData);

  if (result?.value) {
    setData({
      value: result?.value,
    });
    setIsUpdating(false);
    return true;
  }

  showError(result?.error);
  setData({
    error: result?.error ?? 'Something went wrong',
  });
  setIsUpdating(false);
  showAlert();
  return false;
};
