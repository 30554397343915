import styled from '@emotion/styled';
import { Paper as PaperMUI, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import * as SC from 'SC/App.sc';

export { Buttons, Wrapper } from 'SC/App.sc';

export const Form = styled(SC.Form)`
  width: calc(100% - 5px);
  height: 100%;
`;

export const Container = styled(SC.Container)`
  & .highlighted {
    margin: -10px -20px;
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    background-color: ${grey[400]};
  }
  & .highlighted.active {
    background-color: #ffd255;
  }

  & .filter {
    margin: 0;
    padding: 5px 20px 5px 20px;
    color: ${grey[400]};
    background-color: ${grey[200]};
    border-radius: 20px;
  }

  & .filter.active {
    color: #cdb0b0;
    background-color: #ffe3e1;
  }
`;

export const Paper = styled(PaperMUI)`
  padding: 20px;
  padding-bottom: 40px;
  width: calc(100% - 40px);
  border-radius: 20px;
  overflow: hidden;
  overflow-y: auto;
`;

export const List = styled(SC.FlexColumn)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const Message = styled.span`
  margin: 0 10px;
  flex: 1 1 auto;
  text-align: left;
`;

export const Sender = styled.span`
  flex: 1 0 auto;
  text-align: right;
  &:hover {
    font-weight: bold;
    color: blue;
  }
  cursor: pointer;
`;

export const Time = styled.span`
  flex: 1 0 auto;
  max-width: 70px;
  font-size: 10px;
  text-align: left;
`;

export const Grid = styled(SC.FlexColumn)`
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const GridColumn = styled(SC.FlexColumn)`
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const GridRow = styled(SC.FlexRow)`
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ProcedureLabel = styled(SC.FlexColumn)`
  margin: 0;
  padding: 0;
  max-width: 280px;
  background-color: #ffaaff;
  border-radius: 20px;
`;

export const Label = styled(Box)`
  margin: 0;
  font-size: 1.6em;
  font-weight: bold;
  color: ${grey[400]};
`;

export const FilterLabel = styled(Box)`
  font-size: 1.2em;
  font-weight: bold;
`;

export const ProcedureTitle = styled(Box)`
  font-size: 1.4em;
  font-weight: bold;
  color: white;
`;
