/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Button,
  Modal,
  Stack,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  List,
  ListItem,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Panel } from 'UI/Panel/Panel';
import { Container } from 'SC/App.sc';
import InfoIcon from '@mui/icons-material/Info';
import { getNextIdKey } from 'utils/getNextIdKey';

const rows = [
  {
    id: getNextIdKey(),
    parameter: 'Body Position',
    specification: 'Supine, feet first, legs parallel in neutral rotation.',
  },
  {
    id: getNextIdKey(),
    parameter: 'Region of Interest (ROI)',
    specification:
      'The width and height (x, y) of both ROIs must be equal therefore the areas have the same center points.',
  },
  {
    id: getNextIdKey(),
    parameter: 'Main ROI',
    specification:
      'Pelvis Region - the entire pelvis and the upperpart of the femurs (approximately 5 cm below the lesser trochanters).',
  },
  {
    id: getNextIdKey(),
    parameter: 'Second ROI',
    specification:
      'Femoral Condyles – the left and right distal femurs (50mm to left and right).',
  },
  {
    id: getNextIdKey(),
    parameter: 'Image/Pixel Size',
    specification: 'Pixel size must be the same for both ROIs.',
  },
  {
    id: getNextIdKey(),
    parameter: 'Slice Thickness',
    specification: 'Thickness and interslice distances should both be 2.5mm.',
  },
  {
    id: getNextIdKey(),
    parameter: 'Scan Technique',
    specification:
      'Sequential Scans: continuous or overlapping slices with no gaps.***Helical Scans: pitch (table : scan ratio) = 1:1 recommended.',
  },
  {
    id: getNextIdKey(),
    parameter: 'Scan Store',
    specification: 'Store scans into 1 zip file.',
  },
];

const columns = [
  {
    field: 'parameter',
    align: 'left',
    headerAlign: 'center',
    headerName: 'CT Parameter',
    width: 200,
  },
  {
    field: 'specification',
    align: 'left',
    headerAlign: 'center',
    headerName: 'Specification',
    flex: 1,
  },
];

export const CtProtocolInfo = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        size="small"
        type="button"
        variant="contained"
        color="primary"
        title="Click to open ArthroSight CT Protocol"
        onClick={() => setOpen(true)}
      >
        ArthroSight CT Protocol &nbsp; <InfoIcon />
      </Button>
      <Modal
        open={open}
        sx={{
          zIndex: 1500,
        }}
      >
        <Container>
          <Stack
            width="100%"
            height="100%"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ margin: 'auto', height: '80%', width: '80%' }}>
              <Stack
                width="100%"
                height="100%"
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Panel
                  title="Recommended CT Protocol"
                  handleCloseIcon={() => setOpen(false)}
                >
                  {/* <DataGrid
                    rows={rows}
                    columns={columns}
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnMenu
                    hideFooter
                  /> */}
                  <Stack
                    width="100%"
                    height="100%"
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      sx={{ flex: '1 1 auto', overflow: 'auto', width: '100%' }}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {columns.map(({ headerName }) => (
                              <TableCell
                                sx={{
                                  padding: '20px 16px',
                                  verticalAlign: 'top',
                                  textAlign: 'center',
                                  backgroundColor: '#feecd9',
                                }}
                              >
                                {headerName}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map(({ parameter, specification }) => (
                            <TableRow
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'lightgray',
                                },
                              }}
                            >
                              <TableCell
                                sx={{
                                  padding: '12px 26px',
                                  verticalAlign: 'top',
                                }}
                              >
                                {parameter}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: '0 16px',
                                }}
                              >
                                <List
                                  sx={{
                                    padding: '4px 16px',
                                  }}
                                >
                                  {specification.split('***').map(text => (
                                    <ListItem>{text}</ListItem>
                                  ))}
                                </List>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      sx={{
                        marginTop: 1,
                        width: 200,
                      }}
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </Button>
                  </Stack>
                </Panel>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Modal>
    </>
  );
};
