import { ProcedureStatusEnum } from 'models/constants';

export const allowedStatusChangesForSurgeon = {
  [ProcedureStatusEnum.NEW]: [
    ProcedureStatusEnum.SCAN,
    ProcedureStatusEnum.CANCELED,
  ],
  [ProcedureStatusEnum.SCAN]: [
    ProcedureStatusEnum.CANCELED,
    ProcedureStatusEnum.NEW,
  ],
  [ProcedureStatusEnum.SCAN_READY]: [
    // ProcedureStatusEnum.MODEL_ORDERED,
    ProcedureStatusEnum.CANCELED,
    ProcedureStatusEnum.SCAN,
    ProcedureStatusEnum.NEW,
  ],
  [ProcedureStatusEnum.MODEL_READY]: [
    ProcedureStatusEnum.READY,
    ProcedureStatusEnum.SCHEDULED,
    ProcedureStatusEnum.CANCELED,
  ],
  [ProcedureStatusEnum.READY]: [
    ProcedureStatusEnum.MODEL_READY,
    ProcedureStatusEnum.SCHEDULED,
    ProcedureStatusEnum.CANCELED,
  ],
  [ProcedureStatusEnum.SCHEDULED]: [
    ProcedureStatusEnum.DONE,
    ProcedureStatusEnum.CANCELED,
    ProcedureStatusEnum.READY,
  ],
  [ProcedureStatusEnum.DONE]: [
    ProcedureStatusEnum.SCHEDULED,
    ProcedureStatusEnum.CANCELED,
  ],
  [ProcedureStatusEnum.CANCELED]: [
    ProcedureStatusEnum.DONE,
    ProcedureStatusEnum.SCHEDULED,
  ],
};

export const allowedStatusChangesForAdmin = {
  ...allowedStatusChangesForSurgeon,
  [ProcedureStatusEnum.SCAN]: [
    ProcedureStatusEnum.CANCELED,
    ProcedureStatusEnum.SCAN_READY,
    ProcedureStatusEnum.NEW,
  ],
  [ProcedureStatusEnum.MODEL_IN_WORK]: [
    ProcedureStatusEnum.READY,
    ProcedureStatusEnum.MODEL_READY,
    ProcedureStatusEnum.SCAN_READY,
  ],
  [ProcedureStatusEnum.MODEL_READY]: [
    ProcedureStatusEnum.READY,
    ProcedureStatusEnum.MODEL_IN_WORK,
    ProcedureStatusEnum.SCAN_READY,
  ],
  [ProcedureStatusEnum.READY]: [
    ProcedureStatusEnum.SCHEDULED,
    ProcedureStatusEnum.MODEL_READY,
    ProcedureStatusEnum.MODEL_IN_WORK,
  ],
  [ProcedureStatusEnum.SCHEDULED]: [
    ProcedureStatusEnum.DONE,
    ProcedureStatusEnum.CANCELED,
    ProcedureStatusEnum.READY,
    ProcedureStatusEnum.MODEL_READY,
    ProcedureStatusEnum.MODEL_IN_WORK,
  ],
  [ProcedureStatusEnum.DONE]: [
    ProcedureStatusEnum.MODEL_READY,
    ProcedureStatusEnum.MODEL_IN_WORK,
    ProcedureStatusEnum.READY,
    ProcedureStatusEnum.SCHEDULED,
  ],
  [ProcedureStatusEnum.CANCELED]: [ProcedureStatusEnum.NEW],
};

export const disabledStatusChangesForAdmin = [
  // ProcedureStatusEnum.MODEL_IN_WORK,
  // ProcedureStatusEnum.MODEL_READY,
];

export const disabledStatusChangesForSurgeon = [
  ProcedureStatusEnum.MODEL_IN_WORK,
  // ProcedureStatusEnum.MODEL_READY,
  ProcedureStatusEnum.SCAN_READY,
];
