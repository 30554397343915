/* eslint-disable no-unused-vars */
import React from 'react';
import { Stack, Button, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import * as UI from 'UI';
import { useConfirmAction } from 'hooks/useConfirmAction';
import { GenderLabelEnum, RoleEnum } from 'models/constants';
import {
  medicalInsuranceIdValidation,
  firstNameValidation,
  lastNameValidation,
  dateOfBirthValidation,
  genderValidation,
  patientEmailValidation,
  phoneNumberValidation,
} from 'models/validations';
import * as SC from '../PatientRecord.sc';

export const PersonalData = ({ patient }) => {
  const { confirmAction } = useConfirmAction();

  const {
    control,
    age,
    recalcAge,
    isDirty,
    trigger,
    isUpdating,
    handleSubmit,
    onSubmit,
    handleCancelChanges,
    isDeletionAllowedForSurgeon,
    handleDeletePatient,
    globalState,
    row,
  } = patient;

  const isDisabled =
    globalState?.user?.value?.role === RoleEnum.MASTER_ADMIN ||
    globalState?.user?.value?.role === RoleEnum.ADMIN ||
    row?.value?.deleted ||
    isUpdating;

  return (
    <UI.Panel
      title={
        <SC.FlexRow style={{ justifyContent: 'space-between', width: '100%' }}>
          <div>Patient Information</div>
          {!isDisabled && <EditIcon />}
        </SC.FlexRow>
      }
    >
      <SC.Form>
        <Stack direction="column" width="100%" spacing={2}>
          <Stack direction="row" width="100%" spacing={2}>
            <UI.FormTextField
              label="First Name"
              name="nameFirst"
              control={control}
              rules={firstNameValidation}
              disabled={isDisabled}
              variant={isDisabled ? 'filled' : 'outlined'}
            />
            <UI.FormTextField
              label="Last Name"
              name="nameSecond"
              control={control}
              rules={lastNameValidation}
              disabled={isDisabled}
              variant={isDisabled ? 'filled' : 'outlined'}
            />
          </Stack>
          <Stack direction="row" width="100%" spacing={2}>
            <UI.FormSelectField
              label="Gender"
              name="gender"
              control={control}
              rules={genderValidation}
              options={GenderLabelEnum}
              disabled={isDisabled}
              variant={isDisabled ? 'filled' : 'outlined'}
            />
            <div className={SC.DateOfBirthClass}>
              <UI.FormDateSelector
                label="Date of Birth"
                name="dateOfBirth"
                control={control}
                rules={dateOfBirthValidation}
                className={SC.DateOfBirthClass}
                onSelect={recalcAge}
                disabled={isDisabled}
                variant={isDisabled ? 'filled' : 'outlined'}
              />
            </div>
            <TextField
              size="small"
              label="Age"
              value={age}
              disabled
              variant={isDisabled ? 'filled' : 'outlined'}
            />
          </Stack>
          <Stack direction="row" width="100%" spacing={2}>
            <UI.FormTextField
              label="Medical Record Number"
              name="medicalInsuranceId"
              control={control}
              rules={medicalInsuranceIdValidation}
              disabled={isDisabled}
              variant={isDisabled ? 'filled' : 'outlined'}
            />
            <UI.FormTextField
              label="E-mail"
              name="email"
              control={control}
              rules={patientEmailValidation}
              disabled={isDisabled}
              variant={isDisabled ? 'filled' : 'outlined'}
            />
          </Stack>
          <UI.FormPhoneNumber
            label="Phone Number"
            name="phoneNumber"
            control={control}
            rules={phoneNumberValidation}
            disabled={isDisabled}
            variant={isDisabled ? 'filled' : 'outlined'}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Stack>
        {row?.value?.deleted && (
          <SC.FlexColumn style={{ marginTop: 20 }}>
            This patient is deleted
          </SC.FlexColumn>
        )}
        <SC.Buttons>
          {isDeletionAllowedForSurgeon() && !row?.value?.deleted && (
            <>
              <Button
                type="button"
                variant="contained"
                color="warning"
                disabled={isUpdating}
                onClick={handleDeletePatient}
              >
                Delete Patient
              </Button>
              &nbsp;
            </>
          )}
          {isDirty && (
            <>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={!isDirty || isDisabled}
                onClick={handleCancelChanges}
              >
                Cancel changes
              </Button>
              &nbsp;
              <Button
                disabled={!isDirty || isDisabled}
                type="button"
                variant="contained"
                color="secondary"
                onClick={async () => {
                  const isValid = await trigger();

                  if (isValid) {
                    confirmAction({
                      text: 'Do you confirm saving?',
                      callbackFn: handleSubmit(onSubmit),
                    });
                  }
                }}
              >
                Save Changes
              </Button>
            </>
          )}
        </SC.Buttons>
      </SC.Form>
    </UI.Panel>
  );
};
