/* eslint-disable no-unused-vars */
import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const AccessDenied = () => {
  return (
    <Alert severity="error">
      <AlertTitle>Access Denied</AlertTitle>
      <p>Please ask your administrator for permission.</p>
    </Alert>
  );
};
