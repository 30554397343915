/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useGlobalStateUtils } from './useGlobalStateUtils';
import { loadApiData as loadApiDataUtil } from '../utils/apiDataUtils';

export const useApi = (apiSet, autoload = true) => {
  if (!apiSet) {
    throw Error('Please provide API set parameter for useApi hook call');
  }

  const [data, setData] = useState({});
  const [customData, setCustomData] = useState({});
  const [row, setRow] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const {
    globalState,
    dispatchGlobalState,
    error,
    showAlert,
    showError,
    hideError,
    showConfirm,
    hideConfirm,
    showSpinner,
    hideSpinner,
  } = useGlobalStateUtils();

  const role = globalState.user?.value?.role;

  const loadApiData = async ({
    apiCall,
    requestData = {
      role,
    },
    setData = () => undefined,
  }) => {
    const result = await loadApiDataUtil({
      apiCall,
      requestData,
      setData,
      showError,
      hideError,
      setIsUpdating,
    });
    return result;
  };

  const handleReloadData = requestData => {
    loadApiData({
      apiCall: apiSet.getAll,
      requestData: {
        ...requestData,
        role,
      },
      setData,
    });
  };

  const handleGetDataById = async id => {
    loadApiData({
      apiCall: () => apiSet.getById(id),
      setData: setRow,
    });
  };

  const handleCreateData = async requestData => {
    const result = await loadApiData({
      apiCall: apiSet.create,
      requestData,
    });
    if (!result) return false;

    showAlert({
      type: 'success',
      text: 'Record was created successfully',
    });
    if (autoload) handleReloadData();
    return true;
  };

  const handleCreateDataAndGetCreated = async requestData => {
    hideError();
    setIsUpdating(true);
    const result = await apiSet.create(requestData);

    if (result?.error) {
      showError(result.error);
    }
    setIsUpdating(false);
    return result;
  };

  const handleSaveData = async requestData => {
    const result = await loadApiData({
      apiCall: apiSet.save,
      requestData,
    });
    if (!result) return false;

    // showAlert({
    //   type: 'success',
    //   text: 'Record was updated successfully',
    // });

    handleGetDataById(requestData.id);
    if (autoload) handleReloadData();
    return true;
  };

  const handleDeleteData = async requestData => {
    const result = await loadApiData({
      apiCall: apiSet.remove,
      requestData,
    });
    if (!result) return false;

    showAlert({
      type: 'success',
      text: 'Record was deleted successfully',
    });
    if (autoload) handleReloadData();
    return true;
  };

  /**
   * Returns data from the custom API request with any data
   * @param {{ requestName: string; requestData: { [key]: any }}}
   * @return {{ [key]: any }}
   */
  const handleCustomApiRequest = async ({ requestName, requestData }) => {
    setCustomData({});
    const result = await loadApiData({
      apiCall: apiSet[requestName],
      requestData,
      setData: setCustomData,
    });
    return result;
  };

  useEffect(() => {
    if (!autoload) return;
    hideError();
    handleReloadData();
  }, []);

  return {
    data,
    setData,
    row,
    setRow,
    isUpdating,
    setIsUpdating,
    error,
    showAlert,
    showError,
    hideError,
    showConfirm,
    hideConfirm,
    showSpinner,
    hideSpinner,
    globalState,
    dispatchGlobalState,
    loadApiData,
    handleReloadData,
    handleGetDataById,
    handleCreateData,
    handleCreateDataAndGetCreated,
    handleSaveData,
    handleDeleteData,
    customData,
    setCustomData,
    handleCustomApiRequest,
  };
};
