/* eslint-disable react/no-unknown-property */
import React from 'react';

const BIG_NUM = 2 ** 16;

const Floor = ({
  visible = false,
  width,
  length,
  noShadow = false,
  offset = 0,
  ...otherProps
}) => {
  const position = [(width ?? 0) / 2, (length ?? 0) / 2, -offset];
  const planeArgs = [
    width ?? BIG_NUM,
    length ?? BIG_NUM,
    Math.floor((width ?? 20) / 20),
    Math.floor((length ?? 20) / 20),
  ];
  if (!visible) return null;
  return (
    <group {...otherProps}>
      {width != null && length != null && (
        <mesh receiveShadow position={position}>
          <planeGeometry args={planeArgs} />
          <meshStandardMaterial wireframe color="#777" />
        </mesh>
      )}
      {!noShadow && (
        <mesh receiveShadow position={position}>
          <planeGeometry args={planeArgs} />
          <shadowMaterial opacity={0.2} />
        </mesh>
      )}
    </group>
  );
};

export default Floor;
