/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { UserStatusEnum, RoleEnum } from 'models/constants';

export const getUsersListByRole = ({ users, searchRole }) => {
  const usersArray = Array.isArray(users)
    ? users
        .filter(({ role }) => searchRole === role)
        .map(({ id, nameFirst, nameSecond, status, deleted }) => ({
          id,
          nameFirst,
          nameSecond,
          disabled: deleted || status === UserStatusEnum.BLOCKED,
        }))
    : [];

  const usersObject = {};
  usersArray.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
  usersArray.forEach(({ id, nameFirst, nameSecond, disabled }) => {
    usersObject[id] = {
      name: !!nameFirst && !!nameSecond ? `${nameFirst} ${nameSecond}` : '',
      disabled,
    };
  });

  return usersObject;
};

export const getSurgeonListByAssistantId = ({ users, assistantId }) => {
  const usersArray = Array.isArray(users)
    ? users
        .filter(({ assistants }) =>
          assistants.map(({ id }) => id).includes(assistantId)
        )
        .map(({ id, nameFirst, nameSecond, status, deleted }) => ({
          id,
          nameFirst,
          nameSecond,
          disabled: deleted || status === UserStatusEnum.BLOCKED,
        }))
    : [];

  const usersObject = {};
  usersArray.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
  usersArray.forEach(({ id, nameFirst, nameSecond, disabled }) => {
    usersObject[id] = {
      name: !!nameFirst && !!nameSecond ? `${nameFirst} ${nameSecond}` : '',
      disabled,
    };
  });

  return usersObject;
};
