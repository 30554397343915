import React, { useRef } from 'react';
import { Stack, Button } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as UI from 'UI';
import { UserStatusLabelEnum } from 'models/constants';
import * as SC from 'SC/App.sc';
import {
  fullNameValidation,
  emailValidation,
  passwordValidation,
  getPasswordConfirmValidation,
  statusValidation,
} from 'models/validations';

export const AdminFields = ({
  isPasswordVisible,
  setIsPasswordVisible,
  control,
  watch,
}) => {
  const password = useRef();
  password.current = watch('password', '');

  return (
    <Stack direction="column" width="100%" spacing={3}>
      <UI.FormTextField
        label="First name"
        name="nameFirst"
        control={control}
        rules={fullNameValidation}
      />
      <UI.FormTextField
        label="Last name"
        name="nameSecond"
        control={control}
        rules={fullNameValidation}
      />
      <UI.FormTextField
        label="Email"
        name="email"
        control={control}
        rules={emailValidation}
      />
      <SC.FlexRow style={{ width: '100%', alignItems: 'flex-start' }}>
        <UI.FormTextField
          label="Password"
          name="password"
          control={control}
          rules={passwordValidation}
          autoComplete="new-password"
          type={isPasswordVisible ? 'text' : 'password'}
        />
        <UI.EmptySpace />
        <UI.FormTextField
          label="Repeat Password"
          name="password_repeat"
          control={control}
          rules={getPasswordConfirmValidation(password.current)}
          autoComplete="new-password"
          type={isPasswordVisible ? 'text' : 'password'}
        />
        <Button onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
          {isPasswordVisible && <VisibilityOffIcon />}
          {!isPasswordVisible && <VisibilityIcon />}
        </Button>
      </SC.FlexRow>
      <UI.FormSelectField
        label="Status"
        name="status"
        control={control}
        rules={statusValidation}
        defaultValue="ACTIVE"
        options={UserStatusLabelEnum}
      />
    </Stack>
  );
};
