/* eslint-disable no-unused-vars */
import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { phoneNumberValidation } from 'models/validations';

export const FormPhoneNumber = ({
  label,
  name,
  control,
  defaultValue = '',
  type = 'text',
  size = 'small',
  options,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, value, ...inputProps },
        fieldState: { error },
      }) => (
        <InputMask
          value={value}
          {...inputProps}
          mask="+1 (999) 999 9999"
          maskChar="_"
        >
          {() => (
            <FormControl fullWidth error={!!error}>
              <TextField
                value={value}
                size={size}
                type={type}
                label={label}
                error={!!error}
                helperText={error?.message}
                inputRef={ref}
                {...inputProps}
                {...props}
                variant={props.disabled ? 'filled' : 'outlined'}
              />
            </FormControl>
          )}
        </InputMask>
      )}
      rules={phoneNumberValidation}
    />
  );
};
