import React from 'react';
import { useHistory } from 'react-router-dom';
import { useConfirmAction } from 'hooks/useConfirmAction';
import { Box } from '@mui/material';
import * as SC from './TabLabel.sc';

export const TabLabel = ({ icon, label, path, isActive }) => {
  const { push } = useHistory();
  const { confirmAction, globalState, dispatchGlobalState } =
    useConfirmAction();

  const checkLostChanges = () => {
    if (globalState.isDataChanged === true) {
      confirmAction({
        text: 'You have unsaved changes. Discard changed data?',
        callbackFn: () => {
          dispatchGlobalState({ type: 'SET_isDataChanged', payload: false });
          dispatchGlobalState({ type: 'HIDE_CONFIRM' });
          push(path);
        },
      });
    } else {
      push(path);
    }
  };

  return (
    <SC.Link
      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
      tabIndex="1"
      className={isActive ? 'active' : ''}
      onClick={() => checkLostChanges()}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          checkLostChanges();
        }
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <img src={icon} alt="icon" />
        <span style={{ marginLeft: '12px' }}>{label}</span>
      </Box>
    </SC.Link>
  );
};
