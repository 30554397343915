/* eslint-disable no-unused-vars */
import React from 'react';
import * as SC from './StatusButton.sc';

/**
 * @param {{ title: string; onClick: Function; children: JSX.Element[] | JSX.Element; isActive: boolean; customColor: string; }} props
 */
export const StatusButton = ({
  title,
  onClick,
  children,
  isActive,
  customColor,
}) => (
  <>
    {isActive && (
      <SC.ActiveStatusButton
        variant="outlined"
        size="small"
        onClick={onClick}
        title={title}
        mycolor={customColor}
      >
        {children}
      </SC.ActiveStatusButton>
    )}
    {!isActive && (
      <SC.StatusButton
        variant="outlined"
        size="small"
        onClick={onClick}
        title={title}
        mycolor={customColor}
      >
        {children}
      </SC.StatusButton>
    )}
  </>
);
