import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Paper as PaperMUI, Box } from '@mui/material';
import { grey } from '@mui/material/colors';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Container = styled(FlexColumn)`
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: auto;
  & .MuiDataGrid-columnHeaders {
    background-color: ${({ theme }) => theme.color.datagridHeader};
  }
  & .MuiDataGrid-row:hover {
    background-color: #ddd !important;
  }
  & .MuiFormControl-root {
    width: unset;
    flex: 1 1 auto;
  }
`;

export const Header = styled(FlexRow)`
  padding: 10px 20px 0 20px;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  & h3 {
    margin: 5px 10px;
  }
`;

export const Content = styled(PaperMUI)`
  flex: 1 1 auto;
  margin: 20px;
  align-self: center;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
`;

export const Wrapper = styled(FlexColumn)`
  justify-content: flex-start;
  align-items: stretch;
  padding: 10px 20px 20px 25px;
  flex: 1 1 auto;
  width: calc(100% - 50px);
  overflow-y: auto;
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
`;

export const FieldSet = styled(FlexColumn)`
  flex: 1 1 auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

export const Footer = styled(FlexRow)`
  flex: 0 0 auto;
  margin: 0;
  padding: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
  width: calc(100% - 40px);
`;

export const Buttons = styled(FlexRow)`
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 20px 0;
  width: 100%;

  user-select: none;
`;

export const VisibleCancelSearch = styled.div`
  color: gray;
  cursor: pointer;
  & :hover {
    color: black;
  }
`;

export const UnvisibleCancelSearch = styled.div`
  color: white;
`;

export const DataGridClass = css`
  & .MuiDataGrid-cell {
    font-family: sans-serif;
    font-size: 14px;
  }
  & .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
    font-family: sans-serif;
    font-size: 14px;
  }
`;

export const EmptySpace = styled.div`
  flex: 1 1 auto;
  margin: auto;
`;

export const GenderClass = css`
  width: 120px;
`;

export const DateOfBirthClass = css`
  width: 180px;
`;

export const Circle = styled.div`
  justify-self: flex-start;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
  color: white;
  background-color: red;
`;

export const NewLabel = styled(FlexColumn)`
  position: absolute;
  top: -23px;
  left: -30px;
  width: 18px;
  height: 18px;
  padding: 5px;
  font-size: 10px;
  font-weight: 600;
  color: white;
  background-color: #ce3535;
  border-radius: 30px;
`;

export const StatusIndicator = styled.div`
  padding: 2px;
  border: 1px solid ${({ color = 'lightgray' }) => color};
  border-radius: 2px;
  color: ${({ color = 'lightgray' }) => color};
`;

export const StatusWrapper = styled.div`
  flex: 0 0 auto;
  text-align: center;
  width: 80px;
  font-size: 12px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0);
  z-index: 1000;
  cursor: default;
`;

export const BackIconHolder = styled(Box)`
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: ${grey[400]};

  font-size: 1.1em;
  font-weight: bold;

  & > svg {
    margin-right: -10px;
  }

  &:hover {
    color: ${grey[700]};
  }
`;

export const LongText = styled(Box)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  cursor: default;
`;
