import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Button } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import * as UI from 'UI';
import { RoleEnum } from 'models/constants';
import * as SC from 'components/Procedure/Procedure.sc';
import { Information } from 'components/Procedure/Information/Information';
import { Notes } from 'components/Procedure/Notes/Notes';

export const RadiologistProcedure = ({ hookData }) => {
  const { push } = useHistory();
  const { id, handleSubmit, onSubmit, dispatchGlobalState, watch } = hookData;

  return (
    <SC.Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" width="100%" spacing={4}>
        <Stack direction="row" width="100%" spacing={4}>
          <UI.Panel title="Procedure Information">
            <Information hookData={hookData} userRole={RoleEnum.RADIOLOGIST} />
          </UI.Panel>
        </Stack>
        <UI.Panel title="Send Message">
          <Button
            variant="contained"
            color="success"
            title="Send message to other user"
            onClick={() => {
              dispatchGlobalState({
                type: 'SET_newMessageReceiverId',
                payload: '',
              });
              dispatchGlobalState({
                type: 'SET_newMessageRole',
                payload: '',
              });
              dispatchGlobalState({
                type: 'SET_newMessageProcedureId',
                payload: watch()?.id ?? '',
              });
              push('/new_message');
            }}
            type="button"
          >
            Send Message &nbsp; <MailOutlineIcon />
          </Button>
        </UI.Panel>
        <Stack direction="row" width="100%" spacing={4}>
          <UI.Panel title="Notes">
            <Notes procedureId={id} />
          </UI.Panel>
        </Stack>
      </Stack>
    </SC.Form>
  );
};
