/* eslint-disable no-unused-vars */
import { useApi } from './useApi';
import procedureApi from '../api/procedure';

export const usePatientAndProceduresList = () => {
  /* TODO - change procedureApi to NON-deleted procedures */
  const { data } = useApi(procedureApi);

  // console.log(`===== procedureList`, data?.value);

  const reduceToOptions = (
    acc,
    { id, deleted, patient: { nameFirst, nameSecond } }
  ) => ({
    ...acc,
    [id]: {
      name: `${nameFirst} ${nameSecond}, Procedure #${id}`,
      disabled: deleted,
    },
  });

  const sorted = Array.isArray(data?.value)
    ? data.value?.sort((a, b) =>
        a.patient.nameFirst > b.patient.nameFirst ? 1 : -1
      )
    : [];

  return {
    procedureListOptions: sorted
      // .filter(row => !row.deleted)
      .reduce(reduceToOptions, {}),
  };
};
