/* eslint-disable no-unused-vars */
import React from 'react';
import { useUsersApi } from 'hooks/useUsersApi';
import { AppLayout } from '../AppLayout/AppLayout';
import { menuConfig, privateRoute } from './appConfig';

function Surgeon() {
  useUsersApi();

  return <AppLayout config={menuConfig} privateRoute={privateRoute} />;
}

export default Surgeon;
