import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useGlobalState } from '../../../../store/GlobalStateContext';
import { useApi } from '../../../../hooks/useApi';
import locationApi from '../../../../api/location';

export const useLocationCreator = () => {
  const { goBack } = useHistory();
  const [globalState, dispatchGlobalState] = useGlobalState();
  const { handleCreateData, isUpdating, setIsUpdating, error, showError } =
    useApi(locationApi, false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    trigger,
  } = useForm({
    name: '',
    address: '',
    phoneNumber: '',
    // hasSurgery: false,
    // hasRadiology: false,
    location: 'both',
    active: 'active',
  });

  const onSubmit = async newData => {
    const preparedData = {
      id: newData?.id,
      name: newData?.name,
      address: newData?.address,
      phoneNumber: newData?.phoneNumber,
      hasRadiology:
        newData?.location === 'radiology' || newData?.location === 'both',
      hasSurgery:
        newData?.location === 'surgery' || newData?.location === 'both',
      active: newData?.active === 'active',
    };

    const result = await handleCreateData({
      ...preparedData,
      userCreatorId: globalState?.user?.value?.userId,
    });

    if (result) {
      reset();
      dispatchGlobalState({
        type: 'SET_users',
        payload: {},
      });
      goBack();
    }
  };

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    document.title = 'Create New Location';
  }, []);

  useEffect(() => {
    if (isDirty !== globalState.isDataChanged) {
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: isDirty,
      });
    }
  });

  return {
    handleSubmit,
    onSubmit,
    errors,
    control,
    isDirty,
    trigger,
    isUpdating,
    setIsUpdating,
    error,
    showError,
  };
};
