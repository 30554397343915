import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import { useGlobalState } from 'store/GlobalStateContext';
import * as SC from './NewMessageIcon.sc';
import { Preview } from './Preview/Preview';

export const NewMessageIcon = () => {
  const [globalState] = useGlobalState();
  const { push } = useHistory();
  const { messages, user } = globalState;
  const [isPreviewOpened, setIsPreviewOpened] = useState(false);

  const newMessages = useMemo(
    () =>
      Array.isArray(messages.value)
        ? messages.value.filter(
            row => !row.isRead && row.receiverId !== row.senderId
          )
        : [],
    [messages, user]
  );

  const openMessageTab = () => push('/messages');

  const handleIconClick = () => {
    if (newMessages.length > 0) {
      setIsPreviewOpened(true);
      return;
    }
    openMessageTab();
  };

  const handlePreviewClose = e => {
    e.stopPropagation();
    setIsPreviewOpened(false);
  };

  return newMessages.length > 0 ? (
    <SC.Container
      onClick={newMessages.length > 0 ? handleIconClick : openMessageTab}
    >
      <ChatIcon />
      <SC.Circle>{newMessages.length}</SC.Circle>
      {isPreviewOpened && (
        <Preview
          openMessageTab={openMessageTab}
          closePreview={handlePreviewClose}
          newMessages={newMessages}
        />
      )}
    </SC.Container>
  ) : null;
};
