/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useState } from 'react';
import { useGlobalStateUtils } from './useGlobalStateUtils';
import messageApi from '../api/message';

export const useMessagesApi = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    globalState,
    dispatchGlobalState,
    error,
    showAlert,
    showError,
    hideError,
  } = useGlobalStateUtils();
  const { user, messages, sentMessages } = globalState;

  const reloadIncomingMessages = async id => {
    if (!id) return;
    const result = await messageApi.getIncomingList({
      receiverId: id,
    });

    if (result?.error === 'Session expired') {
      showError(result.error);
      dispatchGlobalState({ type: 'LOGOUT' });
      return;
    }

    if (!(messages?.value?.length === result?.value?.length)) {
      dispatchGlobalState({ type: 'SET_messages', payload: result });
    }
  };

  const forceReloadIncomingMessages = async () => {
    if (!user?.value?.userId) return;
    const result = await messageApi.getIncomingList({
      receiverId: user?.value?.userId,
    });

    if (result?.error) {
      showError(result.error);
    }

    dispatchGlobalState({ type: 'SET_messages', payload: result });
  };

  const getNumberNotRead = messages =>
    Array.isArray(messages)
      ? messages.filter(({ isRead }) => isRead).length
      : 0;

  const reloadSentMessages = async id => {
    if (!id) return;
    const result = await messageApi.getSentList({
      senderId: id,
    });

    if (result?.error === 'Session expired') {
      showError(result.error);
      dispatchGlobalState({ type: 'LOGOUT' });
      return;
    }

    if (
      !(
        sentMessages?.value?.length === result?.value?.length &&
        getNumberNotRead(sentMessages?.value) ===
          getNumberNotRead(result?.value)
      )
    ) {
      dispatchGlobalState({ type: 'SET_sentMessages', payload: result });
    }
  };

  const handleSetAsRead = async messageId => {
    if (!messageId) return;
    const result = await messageApi.setAsRead(messageId);
    if (result?.error) {
      showError(result.error);
    } else {
      forceReloadIncomingMessages();
    }
  };

  const handleCreateData = async ({
    procedureId,
    receiverId,
    receivers,
    replyToId,
    senderId,
    text,
    showSuccess = false,
  }) => {
    setIsUpdating(true);
    const result = await messageApi.create({
      procedureId,
      receiverId,
      receivers,
      replyToId,
      senderId,
      text,
    });
    setIsUpdating(false);

    if (result.error) {
      showError(result.error);
      showAlert({
        type: 'error',
        text: 'Message was not sent!',
      });
      return false;
    }
    if (showSuccess) {
      showAlert({
        type: 'success',
        text: 'Message was sent successfully',
      });
    }
    await reloadIncomingMessages();
    await reloadSentMessages();
    return true;
  };

  return {
    messages,
    reloadIncomingMessages,
    sentMessages,
    reloadSentMessages,
    globalState,
    dispatchGlobalState,
    error,
    isUpdating,
    handleCreateData,
    handleSetAsRead,
  };
};
