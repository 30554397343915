/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useApi } from 'hooks/useApi';
import userApi from 'api/user';
import { useLocationList } from 'hooks/useLocationList';
import { purgeNullProps } from 'utils/purgeNullProps';

import { RoleEnum } from 'models/constants';

export const useUserEditor = () => {
  const [selectedRole, setSelectedRole] = useState('');
  const {
    locationsData,
    locationListOptions,
    surgeryLocationListOptions,
    radiologyLocationListOptions,
  } = useLocationList();
  const {
    showAlert,
    globalState,
    dispatchGlobalState,
    isUpdating,
    handleSaveData,
    handleGetDataById,
    row,
  } = useApi(userApi, false);

  const { id } = useParams();
  const users = () => globalState?.users?.value || [];

  const {
    register,
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    trigger,
    watch,
    getValues,
  } = useForm();

  const isAdmin = () =>
    getValues()?.role === RoleEnum.MASTER_ADMIN ||
    getValues()?.role === RoleEnum.ADMIN;
  const isEngineer = () => getValues()?.role === RoleEnum.IMAGE_ENGINEER;
  const isRadiologist = () => getValues()?.role === RoleEnum.RADIOLOGIST;
  const isSurgeon = () => getValues()?.role === RoleEnum.SURGEON;

  const onSubmit = async requestData => {
    const result = await handleSaveData(
      purgeNullProps({
        ...requestData,
        locationId: requestData.locationId,
      })
    );
    if (!result) return;

    dispatchGlobalState({
      type: 'SET_users',
      payload: {},
    });
    showAlert({
      type: 'success',
      text: 'User was updated successfully',
    });
  };

  const handleReset = () => {
    const { value } = row;
    if (isAdmin()) {
      reset({
        id: value?.id,
        role: value?.role || '',
        nameFirst: value?.nameFirst || '',
        nameSecond: value?.nameSecond || '',
        email: value?.email || '',
        status: value?.status || '',
      });
      return;
    }

    reset({
      id: value?.id,
      role: value?.role || '',
      nameFirst: value?.nameFirst || '',
      nameSecond: value?.nameSecond || '',
      phoneNumber: value?.phoneNumber || '',
      email: value?.email || '',
      status: value?.status || '',
      locationId: value?.location?.id || '',
    });
  };

  const handleRoleChanged = e => {
    const role = e?.target?.value;
    setSelectedRole(role);
    setValue('role', role, { shouldDirty: true });
  };

  useEffect(() => {
    if (locationsData?.value?.length) handleGetDataById(id);
  }, [locationsData]);

  useEffect(() => {
    handleReset(row);
    setSelectedRole(row?.value?.role && '');
  }, [row]);

  useEffect(() => {
    if (isDirty !== globalState.isDataChanged) {
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: isDirty,
      });
    }
  });

  return {
    register,
    control,
    watch,
    handleSubmit,
    handleReset,
    onSubmit,
    users,
    row,
    errors,
    isDirty,
    trigger,
    isUpdating,
    isAdmin,
    isEngineer,
    isRadiologist,
    isSurgeon,
    setValue,
    locationsData,
    locationListOptions,
    surgeryLocationListOptions,
    radiologyLocationListOptions,
    getValues,
    handleRoleChanged,
    selectedRole,
  };
};
