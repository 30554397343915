import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGlobalStateApiProperty } from '../../store/useGlobalStateApiProperty';
import * as authApi from '../../api/auth';
import { config } from '../../config/config';
import { emailValidation } from '../../models/validations';

export const useLogin = props => {
  const { showError, hideError } = props;
  const [data, setData] = useState();
  const { register, handleSubmit, formState } = useForm();
  const { errors } = formState;
  const [user, setUser, apiState] = useGlobalStateApiProperty({
    key: 'user',
    apiCallFn: () => authApi.login(data),
  });

  const usernameRegisterProps = register('username', emailValidation);
  const passwordRegisterProps = register('password');

  const onSubmit = handleSubmit(async newData => {
    hideError();
    setData(newData);
    setUser({}); // reload user from API with new credentials by resetting user object
  });

  useEffect(() => {
    if (apiState === 'error') {
      showError(user?.error);
    }
  }, [user]);

  return {
    errors,
    onSubmit,
    apiState,
    usernameRegisterProps,
    passwordRegisterProps,
    version: config.version,
  };
};
