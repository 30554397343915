export const encodeNumberData = value => {
  if (value === '0' || value === 0) return 0;
  if ((!value && String(value) !== '0') || value === '') {
    return null;
  }
  return Number(value);
};

export const decodeNumberData = value => {
  if (!value && String(value) !== '0') {
    return '';
  }
  return value;
};
