/* eslint-disable no-console */
import React from 'react';
import * as UI from 'UI';
import { RoleEnum } from 'models/constants';
import * as SC from './PatientRecord.sc';
import { PersonalData } from './PersonalData/PersonalData';
import { Procedures } from './Procedures/Procedures';
import { usePatientRecord } from './usePatientRecord';

export const PatientRecord = () => {
  const patientHookData = usePatientRecord();
  const {
    isUpdating,
    error,
    procedureList,
    id,
    isDirty,
    handleCreateNewProcedure,
    isNewProcedureAllowed,
    globalState,
    assignedSurgeons,
    row,
  } = patientHookData;

  if (
    globalState.user?.value?.role === RoleEnum.SURGICAL_ASSISTANT &&
    !assignedSurgeons.includes(row?.value?.creatorId) &&
    !isUpdating
  ) {
    return (
      <SC.Container>
        <UI.ErrorBox text={error} width="calc(100% - 32px)" />
        <SC.Header>
          <SC.FlexRow>
            <UI.BackToPrevPage isConfirmNeeded={isDirty} />
            <h3>Patient&apos;s Medical Record #{id}</h3>
          </SC.FlexRow>
        </SC.Header>
        {isUpdating && <UI.GlobalSpinner />}
        <SC.Wrapper>
          <UI.AccessDenied />
        </SC.Wrapper>
      </SC.Container>
    );
  }

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 32px)" />
      <SC.Header>
        <SC.FlexRow>
          <UI.BackToPrevPage isConfirmNeeded={isDirty} />
          <h3>Patient&apos;s Medical Record #{id}</h3>
        </SC.FlexRow>
      </SC.Header>
      {isUpdating && <UI.GlobalSpinner />}
      <SC.Grid>
        <SC.GridRow>
          <SC.GridItem>
            <PersonalData patient={patientHookData} />
          </SC.GridItem>
          <SC.GridItem>
            <Procedures
              procedureList={procedureList}
              handleCreateNewProcedure={handleCreateNewProcedure}
              isNewProcedureAllowed={isNewProcedureAllowed}
              isUpdating={isUpdating}
              isAllowedToCreateNew={
                globalState?.user?.value?.role === RoleEnum.SURGEON
              }
            />
          </SC.GridItem>
        </SC.GridRow>
      </SC.Grid>
    </SC.Container>
  );
};
