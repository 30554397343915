import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.red};
  height: ${({ height }) => height};
`;
