import usersIcon from 'images/icon_users.svg';
import usersIconActive from 'images/icon_users_active.svg';
import locationIcon from 'images/icon_locations.svg';
import locationIconActive from 'images/icon_locations_active.svg';
import { Procedures } from 'components/Procedures/Procedures';
// import { DeletedProcedures } from 'components/DeletedProcedures/DeletedProcedures';
import { Chats } from 'components/Chats/Chats';
import { SendMessage } from 'components/SendMessage/SendMessage';
import { PatientsManagement } from 'components/PatientsManagement/PatientsManagement';
import { Procedure } from 'components/Procedure/Procedure';
import { PatientRecord } from 'components/PatientRecord/PatientRecord';
import { PatientCreator } from './PatientCreator/PatientCreator';
import { AssistantAssignment } from './AssistantAssignment/AssistantAssignment';

export const menuConfig = [
  {
    path: '/procedures',
    label: 'Procedures',
    activeIcon: usersIconActive,
    icon: usersIcon,
    component: Procedures,
  },
  // {
  //   path: '/deleted_procedures',
  //   label: 'Deleted Procedures',
  //   activeIcon: usersIconActive,
  //   icon: usersIcon,
  //   component: DeletedProcedures,
  // },
  {
    path: '/patients',
    label: 'Patient Management',
    activeIcon: usersIconActive,
    icon: usersIcon,
    component: PatientsManagement,
  },
  {
    path: '/new_patient',
    label: 'Create New Patient',
    activeIcon: usersIconActive,
    icon: usersIcon,
    component: PatientCreator,
  },
  {
    path: '/assistants',
    label: 'Assistant Assignment',
    activeIcon: usersIconActive,
    icon: usersIcon,
    component: AssistantAssignment,
  },
  {
    path: '/messages',
    label: 'Messages',
    activeIcon: locationIconActive,
    icon: locationIcon,
    component: Chats,
  },
  {
    path: '/new_message',
    label: 'Send Message',
    activeIcon: locationIconActive,
    icon: locationIcon,
    component: SendMessage,
  },
];

export const privateRoute = [
  {
    path: '/procedure/:id',
    component: Procedure,
  },
  {
    path: '/patient/:id',
    component: PatientRecord,
  },
];
