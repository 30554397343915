const matched = x => ({
  on: () => matched(x),
  otherwise: () => x,
});

export const match = x => ({
  on: (pred, fn) => {
    const isFnFunction = typeof fn === 'function';

    if (typeof pred === 'function')
      return pred(x) ? matched(isFnFunction ? fn(x) : (() => fn)()) : match(x);

    if (Array.isArray(pred)) {
      return pred.includes(x)
        ? matched(isFnFunction ? fn(x) : (() => fn)())
        : match(x);
    }

    return x === pred ? matched(isFnFunction ? fn(x) : (() => fn)()) : match(x);
  },
  otherwise: fn => {
    if (typeof fn === 'function') return fn(x);
    return (() => fn)();
  },
});
