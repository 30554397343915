/* eslint-disable no-unused-vars */
import styled from '@emotion/styled';
import * as SC from '../../../../SC/App.sc';

export { FlexColumn, FlexRow, NewLabel } from '../../../../SC/App.sc';

export const Container = styled(SC.FlexColumn)`
  flex: 0 0 auto;
  width: 100%;
  cursor: ${({ isSuggested }) => (isSuggested ? 'pointer' : 'auto')};
`;

export const Sender = styled.div`
  align-self: flex-start;
  margin: 5px 20px 0 20px;
  font-size: 12px;
  color: gray;
`;

export const Message = styled(SC.FlexColumn)`
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  margin: 0 10px;
  padding: 20px;
  min-width: 150px;
  max-width: 70%;
  font-size: 12px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.palette.success.main};
  label: message;
`;

export const LinesHolder = styled(SC.FlexColumn)`
  width: 100%;
`;

export const Line = styled.div`
  width: 100%;
`;

export const Time = styled(Sender)`
  margin: 0 20px 10px 20px;
`;

export const MyMessage = styled(Message)`
  align-self: flex-end;
  background-color: #f9d5b4;
  text-align: right;
  label: message;
`;
export const AnswerTime = styled(Time)`
  align-self: flex-end;
`;

export const IsRead = styled.span`
  color: #006eff;
  font-size: 1.4em;
  line-height: 1em;
`;

export const IsNotRead = styled.span`
  font-size: 1.4em;
  line-height: 1em;
`;

export const IconHolder = styled(SC.FlexColumn)`
  position: relative;
  justify-content: flex-start;
  height: 100%;
`;

export const ProcedureTag = styled(SC.FlexColumn)`
  align-self: flex-start;
  position: relative;
  top: -10px;
  left: -10px;
  padding: 0 10px;
  min-width: 100px;
  background-color: #ffaaff;
  border-radius: 10px;
  text-decoration: underline;

  &:hover {
    background-color: #aaaaff;
    cursor: pointer;
    box-shadow: rgba(146, 106, 106, 0.35) 0px 5px 15px;
  }
`;
