import { useState } from 'react';
import { useGlobalState } from 'store/GlobalStateContext';
import { timePeriodsEnum, timePeriodDurationEnum } from 'models/constants';

const storage = window.localStorage;

export const usePeriodRange = ({ useCase = '' }) => {
  const [{ user }] = useGlobalState();

  const [period, setPeriod] = useState(
    storage.getItem(`${user.value.userId}-${useCase}-timePeriod`) ??
      timePeriodsEnum.LAST_MONTH
  );

  const setPeriodAndSave = value => {
    setPeriod(value);
    storage.setItem(`${user.value.userId}-${useCase}-timePeriod`, value);
  };

  const periodStartDate =
    period === timePeriodsEnum.ALL_TIME
      ? 0
      : Math.floor(Date.now() / 1000) - timePeriodDurationEnum[period];

  return {
    period,
    setPeriod,
    setPeriodAndSave,
    periodStartDate,
  };
};
