/**
 * Returns record with given search id.
 * @typedef {{ id: string; [x: string]: any; }} RecordWithId - { id: string; [x: string]: any; }
 * @param {string} searchId - id value for search
 * @param {RecordWithId[]} records - array of record objects with id field
 * @returns {undefined|RecordWithId}
 */
export const getRecordById = (searchId, records) => {
  let result;

  const getResultOfCompare = record => {
    if (String(record?.id) === String(searchId)) {
      result = record;
    }
  };

  if (searchId && records && records?.length)
    records.forEach(getResultOfCompare);

  return result;
};
