import styled from '@emotion/styled';
import * as SC from 'SC/App.sc';

export { Buttons, FlexRow, FlexColumn, Form } from 'SC/App.sc';

export const Overlay = styled(SC.Overlay)`
  background-color: rgba(0, 0, 0, 0.4);
`;

export const Container = styled.div`
  position: absolute;
  top: calc(50vh - 150px);
  right: calc(50vw - 200px);
  width: 400px;
  z-index: 1001;
`;

export const PanelTitle = styled(SC.FlexRow)`
  justify-content: flex-start;
`;

export const Circle = styled(SC.Circle)`
  position: relative;
  top: -6px;
  left: 0px;
  width: 26px;
  height: 26px;
  font-size: 1em;
  border-radius: 26px;
`;

export const SmallCircle = styled(SC.Circle)`
  position: relative;
  top: -3px;
  left: 3px;
  width: 14px;
  height: 14px;

  font-size: 0.7em;
  border-radius: 14px;
`;

export const Content = styled(SC.FlexColumn)`
  justify-content: space-between;
  align-items: stretch;
`;

export const Messages = styled(SC.FlexColumn)`
  justify-content: flex-start;
  align-items: stretch;
`;

export const Message = styled(SC.FlexRow)`
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: thin solid gray;
`;

export const Info = styled(SC.FlexColumn)`
  flex: 1 1 auto;
  margin-left: 10px;
  justify-content: center;
  align-items: flex-start;
  label: Info;
`;

export const Name = styled(SC.FlexRow)`
  align-items: flex-end;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
`;
export const Role = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

export const Text = styled.div`
  margin: 0;
  font-family: Poppins;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.859533px;
`;
