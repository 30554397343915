import React from 'react';
import { Stack, Button } from '@mui/material';
import * as UI from 'UI';
import {
  fullNameValidation,
  addressValidation,
  phoneNumberValidation,
  required,
} from 'models/validations';
import { useConfirmAction } from 'hooks/useConfirmAction';
import { LocationTypeEnum } from 'models/constants';
import * as SC from './LocationCreator.sc';
import { useLocationCreator } from './useLocationCreator';

export const LocationCreator = ({ returnToPreviousScreen }) => {
  const { confirmAction } = useConfirmAction();
  const {
    control,
    handleSubmit,
    onSubmit,
    isUpdating,
    error,
    isDirty,
    trigger,
  } = useLocationCreator({ returnToPreviousScreen });

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 32px)" />
      <SC.Header>
        <h3>Create New Location</h3>
      </SC.Header>
      {isUpdating && <UI.GlobalSpinner />}
      {!isUpdating && (
        <SC.Wrapper>
          <SC.Form>
            <SC.Paper elevation={5}>
              <Stack direction="column" width="100%" spacing={3}>
                <UI.FormTextField
                  label="Name"
                  name="name"
                  control={control}
                  rules={fullNameValidation}
                />
                <UI.FormTextField
                  label="Address"
                  name="address"
                  control={control}
                  rules={addressValidation}
                />
                <UI.FormPhoneNumber
                  label="Phone Number"
                  name="phoneNumber"
                  control={control}
                  rules={phoneNumberValidation}
                />
                <UI.FormSelectField
                  label="Location description"
                  name="location"
                  control={control}
                  options={LocationTypeEnum}
                  rules={{ required }}
                  disabled={isUpdating}
                />
                <UI.FormSelectField
                  label="State"
                  name="active"
                  control={control}
                  options={{
                    active: 'Active',
                    disabled: 'Disabled',
                  }}
                  rules={{ required }}
                  disabled={isUpdating}
                />
              </Stack>
            </SC.Paper>
            <SC.EmptySpace />
            <SC.Buttons>
              <Button
                disabled={!isDirty}
                type="button"
                variant="contained"
                color="success"
                onClick={async () => {
                  const isValid = await trigger();

                  if (isValid) {
                    confirmAction({
                      text: 'Do you confirm creation?',
                      callbackFn: handleSubmit(onSubmit),
                    });
                  }
                }}
              >
                Create New Location
              </Button>
            </SC.Buttons>
          </SC.Form>
        </SC.Wrapper>
      )}
    </SC.Container>
  );
};
