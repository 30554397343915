/* eslint-disable no-console */
import React from 'react';
import { AppBar, Typography } from '@mui/material';
import * as SC from './Header.sc';
import { NewMessageIcon } from '../NewMessageIcon/NewMessageIcon';
import { config } from '../../config/config';

export const Header = ({ children }) => {
  return (
    <AppBar position="static" color="secondary">
      <SC.Items>
        <SC.Toolbar>
          ArthroSight
          <Typography sx={{ verticalAlign: 'super', fontSize: '0.6em' }}>
            &nbsp;A.I.
          </Typography>
          &nbsp;- Hip Exchange&nbsp;&nbsp;
          <SC.Version>v{config.version}</SC.Version>
        </SC.Toolbar>
        {children}
        <NewMessageIcon />
      </SC.Items>
    </AppBar>
  );
};
