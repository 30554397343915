import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import * as UI from '../../../UI';
import * as SC from './Locations.sc';
import { useLocations } from './useLocations';
import { withGlobalStateUtils } from '../../../utils/withGlobalStateUtils';

export const Locations = withGlobalStateUtils(() => {
  const {
    columns,
    rows,
    isUpdating,
    search,
    handleSearchChange,
    handleSearchCancel,
    sortModel,
    setSortModel,
    error,
    openLocationEdit,
  } = useLocations();

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 40px)" />
      <SC.Header>
        <h3>Location Management</h3>
        <UI.GridSearchField
          search={search}
          handleSearchChange={handleSearchChange}
          handleSearchCancel={handleSearchCancel}
        />
      </SC.Header>
      <SC.Content elevation={3}>
        <DataGrid
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          rows={rows}
          columns={columns}
          // pageSize={25}
          rowsPerPageOptions={[25, 50, 100]}
          disableSelectionOnClick
          density="compact"
          // onSelectionModelChange={handleSelectionChange}
          disableColumnFilter
          disableColumnMenu
          className={SC.DataGridClass}
          loading={isUpdating}
          onRowClick={openLocationEdit}
        />
      </SC.Content>
    </SC.Container>
  );
});
