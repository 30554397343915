import React from 'react';
import { MenuItem } from '@mui/material';
/**
 * Returns array of JSX select options for given OptionEnum array
 * @param {{[key:string]: string}} optionsEnum
 * @returns {JSX.Element[]}
 */
export const getOptionsForSelect = optionsEnum => {
  const entries = Object.entries(optionsEnum);
  entries.sort((a, b) => {
    if (typeof a[1] === 'string') {
      return a[1] > b[1] ? 1 : -1;
    }
    return a[1].name > b[1].name ? 1 : -1;
  });
  const options = [
    <MenuItem key="empty" value="">
      None
    </MenuItem>,
  ];
  entries.forEach(([value, label], index) => {
    if (typeof label === 'string') {
      options.push(
        <MenuItem key={String(value) + String(index)} value={value}>
          {label}
        </MenuItem>
      );
    } else {
      options.push(
        <MenuItem
          disabled={label.disabled}
          key={String(value) + String(index)}
          value={value}
        >
          {label.name}
        </MenuItem>
      );
    }
  });
  return options;
};

export const getOptionsForStatusSelect = (optionsEnum, colorsEnum = {}) => {
  const entries = Object.entries(optionsEnum);
  const options = [];
  entries.forEach(([value, label], index) => {
    options.push(
      <MenuItem
        key={String(value) + String(index)}
        value={value}
        style={{
          color: 'white',
          backgroundColor: colorsEnum[value],
          borderBottom: '1px solid white',
        }}
      >
        {label}
      </MenuItem>
    );
  });
  return options;
};
