/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as SC from './ChatInfo.sc';
import { RoleLabelEnum } from '../../../models/constants';

export const ChatInfo = ({ data, selected, handleClick }) => {
  const MAX_LENGTH_OF_LAST_TEXT_MESSAGE = 30;
  const {
    senderId,
    name,
    role,
    creation,
    date,
    time,
    text,
    numberOfNewMessages,
  } = data;

  const shorterText =
    text?.length > 15
      ? `${text.substr(0, MAX_LENGTH_OF_LAST_TEXT_MESSAGE)}...`
      : text;

  return (
    <SC.Container
      selected={selected}
      onClick={() => {
        handleClick({ senderId, selected, numberOfNewMessages });
      }}
    >
      <AccountCircleIcon sx={{ fontSize: 55 }} />
      <SC.Info>
        <SC.Name>
          {name ? `${name}, ` : ''}
          <SC.Role>{RoleLabelEnum[role]}</SC.Role>
        </SC.Name>
        <SC.Text>{shorterText}</SC.Text>
      </SC.Info>
      <SC.Data>
        {!!numberOfNewMessages && <SC.Circle>{numberOfNewMessages}</SC.Circle>}
        {!numberOfNewMessages ? (
          <div>
            {date}
            <br />
            {time}
          </div>
        ) : (
          <div>{time}</div>
        )}
      </SC.Data>
    </SC.Container>
  );
};
