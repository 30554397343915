import React from 'react';
import { Global, ThemeProvider } from '@emotion/react';
import { StylesProvider } from '@mui/styles';
import { globalStyles } from './globalStyles';
import { theme } from './theme';

export const StylesAndThemeProvider = ({ children }) => (
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      {children}
    </ThemeProvider>
  </StylesProvider>
);
