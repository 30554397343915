import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Link = styled(Box)`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  font-size: 0.85em;

  color: ${({ theme }) => theme.color.white};

  &.active {
    color: ${({ theme }) => theme.color.orange};
  }
  &:hover {
    opacity: 0.8;
  }
`;
