import React from 'react';
import { useLocation } from 'react-router-dom';
import { TabLabel } from 'UI/TabLabel/TabLabel';
import { AppSidebar, Tabs, Tab } from 'components/AppLayout/AppLayout.sc';

export const SideBar = ({ config }) => {
  const location = useLocation();

  return (
    <AppSidebar style={{ width: '100%' }}>
      <Tabs>
        {config.map(({ path, label, activeIcon, icon }) => (
          <Tab key={path}>
            <TabLabel
              icon={location.pathname === path ? activeIcon : icon}
              label={label}
              path={path}
              isActive={location.pathname === path}
            />
          </Tab>
        ))}
      </Tabs>
    </AppSidebar>
  );
};
