/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable prefer-template */
import { config } from '../config/config';
import { localStorageNameEnum } from '../models/constants';

/*    
    This service returns only two variants of the result:
    
      1) In case of successful result: 
      {
        value: <returned value>,
      }

      2) When something goes wrong: 
      {
        error: <error message>,
      }
 */

export const fileUploadService = async (
  url,
  formData,
  token,
  setPercentage,
  cancelToken,
  axios
) => {
  const storedToken = localStorage.getItem(localStorageNameEnum.AUTH_TOKEN);
  const headers = {
    // 'Content-Type': 'application/json',
  };

  if (token !== undefined) {
    headers.Authorization = `Bearer ${token}`;
  } else if (storedToken) {
    headers.Authorization = `Bearer ${storedToken}`;
  }

  const axiosConfig = {
    headers,
    cancelToken,
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (!setPercentage) {
        return;
      }
      setPercentage(percentCompleted);
    },
  };

  try {
    const response = await axios.post(
      `${config.API_URL}${url}`,
      formData,
      axiosConfig
    );

    const data = response?.data;

    if (response.status !== 200) {
      if (data) {
        try {
          const json = await response.json();
          throw new Error(json?.message || '');
        } catch (error) {
          throw new Error(`${response.status ?? ''} ${error.message}`);
        }
      }
      throw new Error(JSON.stringify(response));
    }

    if (data?.error) {
      throw new Error(data.error.message);
    }
    return {
      value: data,
    };
  } catch (error) {
    return {
      error: String(error.message).replace(/Error:/g, ''),
      // error: 'Network error'
    };
  }
};
