/* eslint-disable no-shadow */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { useApi } from 'hooks/useApi';
import patientApi from 'api/patient';
import { useSearchInGrid } from 'hooks/useSearchInGrid';
import { useLocationList } from 'hooks/useLocationList';
import { useUsersList } from 'hooks/useUsersList';
import { useAssignedSurgeons } from 'hooks/useAssignedSurgeons';
import * as UI from 'UI';
import { useHistory } from 'react-router-dom';
import { getAgeFromDateOfBirth } from 'utils/getAgeFromDateOfBirth';
import { convertToLocaleDate } from 'utils/dateTimeUtils';
import { getRecordById } from 'utils/getRecordById';
import {
  GenderEnum,
  GenderLabelEnum,
  RoleEnum,
  SideLabelEnum,
  PATIENTS_REFRESH_INTERVAL_MS,
} from 'models/constants';
import { usePeriodRange } from 'hooks/usePeriodRange';

export const usePatientsManagement = () => {
  const { push } = useHistory();
  const { assignedSurgeons, reloadAssignedSurgeons } = useAssignedSurgeons();

  const {
    data,
    setData,
    row,
    setRow,
    handleReloadData,
    handleGetDataById,
    handleSaveData,
    handleCreateData,
    handleDeleteData,
    isUpdating,
    setIsUpdating,
    error,
    showError,
    forceReloadData,
    customData,
    handleCustomApiRequest,
    globalState: { user, users },
  } = useApi(patientApi, false);
  const { locationListOptions } = useLocationList();
  const { getUserNameById } = useUsersList(users);

  const [patients, setPatients] = useState([]);

  const { search, handleSearchChange, handleSearchCancel, filterRow } =
    useSearchInGrid(['id', 'nameFirst', 'nameSecond', 'name'], 'patients');
  const columns = [
    {
      field: 'id',
      align: 'center',
      headerAlign: 'center',
      headerName: '#',
      width: 90,
    },
    {
      field: 'medicalInsuranceId',
      headerName: 'Med. Insurance #',
      hide: true,
    },
    {
      field: 'nameFirst',
      headerName: 'First Name',
      sortable: true,
      minWidth: 70,
      flex: 1,
    },
    {
      field: 'nameSecond',
      headerName: 'Last Name',
      sortable: true,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      sortable: true,
      width: 85,
      align: 'center',
    },
    {
      field: 'age',
      headerName: 'Age',
      sortable: true,
      width: 60,
      align: 'center',
    },
    {
      field: 'procedure',
      headerName: 'Procedure',
      sortable: true,
      flex: 1,
      minWidth: 80,
      renderCell: UI.LongText,
    },
    {
      field: 'sideSurgeon',
      headerName: 'Side',
      sortable: true,
      width: 80,
      align: 'center',
    },
    {
      field: 'dateOfSurgery',
      headerName: 'Surgery Date',
      sortable: true,
      width: 120,
      align: 'center',
    },
    {
      field: 'surgeon',
      headerName: 'Surgeon',
      sortable: true,
      minWidth: 60,
      flex: 1,
      renderCell: UI.LongText,
    },
    {
      field: 'surgicalLocation',
      headerName: 'Location',
      sortable: true,
      minWidth: 60,
      flex: 1,
      renderCell: UI.LongText,
    },
    {
      field: 'deleted',
      headerName: 'State',
      headerAlign: 'center',
      align: 'center',
      width: 80,
      renderCell: currentProps =>
        currentProps?.row?.deleted ? <div>Deleted</div> : <div>Active</div>,
    },
    {
      field: 'action',
      headerName: '☰',
      headerAlign: 'center',
      width: 30,
      sortable: false,
      renderCell: currentProps =>
        UI.RowActionsPanel({
          ...currentProps,
          editorUrl: '/patient/',
          handleDeleteAction: handleDeleteData,
        }),
      disableClickEventBubbling: true,
    },
  ];

  const [sortModel, setSortModel] = useState([
    {
      field: 'nameSecond',
      sort: 'asc',
    },
  ]);

  const [isCustomDataUpdated, setIsCustomDataUpdated] = useState(false);

  const { period, periodStartDate, setPeriodAndSave } = usePeriodRange({
    useCase: 'Patients',
  });

  const filterOwnPatients = row =>
    user?.value?.userId === row?.creatorId ||
    assignedSurgeons.includes(row?.creatorId) ||
    user?.value?.role === RoleEnum.MASTER_ADMIN ||
    user?.value?.role === RoleEnum.ADMIN;

  const mapRow = currentRow => {
    const lastProcedure =
      Array.isArray(currentRow?.procedures) && currentRow.procedures.length > 0
        ? currentRow.procedures.sort((a, b) =>
            Number(a.id) > Number(b.id) ? 1 : -1
          )[currentRow.procedures.length - 1]
        : {};

    const fullName = `${
      getRecordById(currentRow?.surgeonId, users?.value)?.nameFirst
    } ${getRecordById(currentRow?.surgeonId, users?.value)?.nameSecond}`;

    const result = {
      ...currentRow,
      name: `${currentRow?.nameFirst} ${currentRow?.nameSecond}`,
      surgeonName: fullName,
      age: getAgeFromDateOfBirth(currentRow.dateOfBirth),
      gender: GenderLabelEnum[GenderEnum[currentRow?.gender]],
      procedure: lastProcedure?.name,
      side: SideLabelEnum[lastProcedure?.sideSurgeon],
      dateOfSurgery: lastProcedure?.dateOfSurgery
        ? convertToLocaleDate(lastProcedure?.dateOfSurgery)
        : '',
      surgicalLocation:
        locationListOptions[lastProcedure?.surgeryLocationId]?.name,
      surgeon: getUserNameById(lastProcedure?.surgeonId),
    };

    return result;
  };

  const openPatient = ({ row }) => push(`/patient/${row?.id}`);

  const reloadAllData = async () => {
    reloadAssignedSurgeons();
    setIsCustomDataUpdated(true);
    await handleReloadData();
    await handleCustomApiRequest({
      requestName: 'getDeletedAfterDate',
      requestData: {
        after: periodStartDate,
      },
    });
    setIsCustomDataUpdated(false);
  };

  useEffect(async () => {
    document.title = 'ArthroSight - Patients';
    await reloadAllData();
  }, []);

  useEffect(() => {
    reloadAllData();
  }, [period]);

  useEffect(() => {
    if (!data?.value || !customData?.value) return;

    const inWork = data?.value ?? [];
    const deleted = customData?.value ?? [];

    const filteredPatients = [...inWork, ...deleted]
      .filter(filterOwnPatients)
      .map(mapRow);

    setPatients(filteredPatients);
  }, [data, customData]);

  useEffect(() => {
    const refresh = setInterval(async () => {
      await reloadAllData();
    }, PATIENTS_REFRESH_INTERVAL_MS);
    return () => clearInterval(refresh);
  });

  return {
    data: patients.filter(filterRow),
    columns,
    setData,
    handleSaveData,
    handleCreateData,
    handleDeleteData,
    handleReloadData,
    handleGetDataById,
    isUpdating: isUpdating || isCustomDataUpdated,
    setIsUpdating,
    error,
    showError,
    search,
    handleSearchChange,
    handleSearchCancel,
    forceReloadData,
    sortModel,
    setSortModel,
    row,
    setRow,
    openPatient,
    period,
    setPeriod: setPeriodAndSave,
  };
};
