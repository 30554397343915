/* eslint-disable no-unused-vars */
import { createApiMethodsSet } from './createApiMethodsSet';
import { config } from '../config/config';
import { localStorageNameEnum } from '../models/constants';

const api = createApiMethodsSet();

api.upload = async data => {
  const storedToken = localStorage.getItem(localStorageNameEnum.AUTH_TOKEN);
  const headers = {};
  headers.Authorization = `Bearer ${storedToken}`;

  return fetch(`${config.API_URL}/csv/upload`, {
    method: 'POST',
    headers,
    body: data,
  });
};

export default api;
