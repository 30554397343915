/* eslint-disable no-console */

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
} from '@mui/material';
import * as SC from './Confirm.sc';
import { withGlobalStateUtils } from '../../utils/withGlobalStateUtils';

export const Confirm = withGlobalStateUtils(
  ({
    title = 'Title',
    text = 'content',
    yes = console.log,
    no = console.log,
    contentRender,
    isVisible = true,
    hideConfirm,
  }) => {
    const handleYes = () => {
      yes();
      hideConfirm();
    };

    const handleNo = () => {
      no();
      hideConfirm();
    };

    return (
      <SC.Container>
        <Dialog open={isVisible}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{text}</DialogContentText>
            <DialogContent>{contentRender || ''}</DialogContent>
          </DialogContent>
          <SC.DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNo}
              autoFocus
            >
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={handleYes}>
              Confirm
            </Button>
          </SC.DialogActions>
        </Dialog>
      </SC.Container>
    );
  }
);
