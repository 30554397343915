import styled from '@emotion/styled';
import { TextField as TextFieldMUI } from '@mui/material';
import { ProcedureStatusColorEnum } from 'models/constants';

export const TextField = styled(TextFieldMUI)`
  & .MuiSelect-select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 30px !important;
    font-size: 0.9em;

    color: white;
    background-color: ${({ status, disabled }) =>
      disabled ? 'white' : ProcedureStatusColorEnum[status]};
  }

  & .MuiInputLabel-root {
    padding: 0 10px;
    font-size: 0.9em;
    color: darkgray;
    background-color: white;
    border: solid thin gray;
    border-radius: 20px;
  }
`;
