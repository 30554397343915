import React from 'react';
import { Button, Alert } from '@mui/material';
import { Panel } from 'UI/Panel/Panel';
import * as SC from './InfoWindow.sc';

/** @param {{ title: string; message: string; handleClose: () => void; severity: "error" | "success"; color: "warning" | "success" }} */
export const InfoWindow = ({
  title,
  message,
  handleClose,
  severity,
  color,
}) => {
  return (
    <SC.Overlay>
      <SC.Container>
        <Panel title={title}>
          <Alert severity={severity}>{message}</Alert>
          <SC.Buttons>
            <Button
              type="button"
              variant="contained"
              color={color}
              onClick={handleClose}
            >
              Close
            </Button>
          </SC.Buttons>
        </Panel>
      </SC.Container>
    </SC.Overlay>
  );
};
