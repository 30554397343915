import * as R from './reducers';
import { defaultGlobalState } from './defaultGlobalState';

const combineReducers = reducers => {
  const keys = Object.keys(reducers);
  return (state, action) => {
    // console.log(`===== ACTION ====`, action.type);
    // console.log(`===== PAYLOAD ====`, action.payload);

    const newState = {};

    keys.forEach(key => {
      newState[key] = reducers[key](state[key], action);
    });

    return action.type === 'LOGOUT' ? defaultGlobalState : newState;
  };
};

export const globalStateReducer = combineReducers(R);
