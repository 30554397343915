/* eslint-disable no-console */
import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as SC from './UserInfo.sc';
import { useGlobalState } from '../../store/GlobalStateContext';
import { RoleLabelEnum } from '../../models/constants';

export const UserInfo = () => {
  const [globalState] = useGlobalState();
  const {
    user: {
      value: { role, nameFirst, nameSecond },
    },
  } = globalState;

  const fullName =
    !!nameFirst && !!nameSecond ? `${nameFirst} ${nameSecond}` : '';

  return (
    <SC.Container>
      <AccountCircleIcon sx={{ fontSize: 55 }} />
      <SC.Info>
        <SC.Name>{fullName || ''}</SC.Name>
        <SC.Role>{RoleLabelEnum[role]}</SC.Role>
      </SC.Info>
    </SC.Container>
  );
};
