import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Paper as PaperMUI } from '@mui/material';
import * as SC from 'SC/App.sc';

export {
  Container,
  FieldSet,
  Header,
  Wrapper,
  Form,
  Footer,
  FlexColumn,
  FlexRow,
  DataGridClass,
} from 'SC/App.sc';

const headerBorderRadius = 10;

export const PanelGrid = styled(SC.FlexColumn)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100%;
`;

export const GridItem = styled(SC.FlexRow)`
  align-items: stretch;
  padding: 24px;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
`;

export const SideClass = css`
  width: 100px;
`;

export const DateOfBirthClass = css`
  width: 175px;
`;

export const EmptySpace = styled.div`
  flex: 1 1 auto;
  margin: auto;
`;

export const ProcedureList = styled(PaperMUI)`
  border-top-left-radius: ${headerBorderRadius}px;
  border-top-right-radius: ${headerBorderRadius}px;
`;

export const ProcedureListHeader = styled.div`
  padding: 20px;
  border-top-left-radius: ${headerBorderRadius}px;
  border-top-right-radius: ${headerBorderRadius}px;
  background-color: #ffeecc;
`;
