import React, { useEffect } from 'react';
import { Button, LinearProgress } from '@mui/material';
import * as FP from 'utils/fp-js';
import { Panel } from 'UI/Panel/Panel';
import * as SC from '../CommonLoaderWindow.sc';
import { LinearProgressWithLabel } from '../LinearProgressWithLabel/LinearProgressWithLabel';

const TIME_STEP_FOR_1_PERCENT_IN_PROGRESS_BAR_SCALE_IN_MS = 100;
const TIMEOUT_FOR_CLOSING = 700;

export const StlLoaderWindow = ({
  loadingError,
  setLoadingError,
  isLoadingCanceled,
  setIsLoadingCanceled,
  progressValue,
  setProgressValue,
  onClose = () => null,
}) => {
  const isLoadingFinished = progressValue >= 100;

  const title = FP.match(true)
    .on(isLoadingCanceled, 'Download canceled by the user')
    .on(progressValue === 99 && !loadingError, 'Preparing model view')
    .on(!isLoadingFinished && !!loadingError, 'Download error')
    .on(!isLoadingFinished, 'Download progress')
    .on(isLoadingFinished, 'Download complete')
    .otherwise();

  useEffect(() => {
    if (progressValue > 98 || !!loadingError) return () => null;

    const timeout = setTimeout(
      () => setProgressValue(progressValue + 1),
      TIME_STEP_FOR_1_PERCENT_IN_PROGRESS_BAR_SCALE_IN_MS
    );

    return () => clearTimeout(timeout);
  }, [progressValue]);

  const isRefreshPageVisible =
    !isLoadingFinished && !!loadingError && !isLoadingCanceled;

  const isCloseWindowButtonVisible =
    isLoadingFinished || !!loadingError || isLoadingCanceled;

  return (
    <SC.Overlay>
      <SC.Container>
        <Panel title={title}>
          {progressValue === 99 && !loadingError ? (
            <LinearProgress
              sx={{
                height: '30px',
                borderRadius: '15px',
              }}
            />
          ) : (
            <LinearProgressWithLabel
              value={progressValue}
              loadingError={loadingError}
              isLoadingFinished={isLoadingFinished}
            />
          )}
          {isRefreshPageVisible && (
            <SC.DestinationInstruction>
              Try to refresh this page in the browser and view the model again
            </SC.DestinationInstruction>
          )}
          <SC.Buttons>
            {!isLoadingCanceled && (
              <Button
                type="button"
                variant="contained"
                color="warning"
                onClick={() => {
                  if (!loadingError && !isCloseWindowButtonVisible) {
                    setIsLoadingCanceled(true);
                    setLoadingError('Download canceled by the user');
                  }
                  if (progressValue < 100) {
                    setTimeout(() => onClose(), TIMEOUT_FOR_CLOSING);
                  }
                }}
              >
                {isCloseWindowButtonVisible ? 'Close Window' : 'Cancel loading'}
              </Button>
            )}
          </SC.Buttons>
        </Panel>
      </SC.Container>
    </SC.Overlay>
  );
};
