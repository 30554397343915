import React from 'react';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import * as UI from 'UI';
import { PasswordChanger } from 'components/PasswordChanger/PasswordChanger';
import { withGlobalStateUtils } from 'utils/withGlobalStateUtils';
import * as SC from './UserMangement.sc';
import { useUserManagement } from './useUserManagement';

export const UserManagement = withGlobalStateUtils(({ error }) => {
  const { push } = useHistory();
  const {
    columns,
    rows,
    selectedRows,
    search,
    handleSearchChange,
    handleSearchCancel,
    sortModel,
    setSortModel,
    handleSelectionChange,
    isUpdating,
    handleChangePassword,
    handleSaveNewPassword,
    handleCloseChangePassword,
    isChangePasswordVisible,
    openUserEdit,
    showSpinner,
    hideSpinner,
    showError,
    hideError,
  } = useUserManagement({ push });

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 40px)" />
      {isChangePasswordVisible && (
        <PasswordChanger
          userId={selectedRows[0]}
          closeWindow={handleCloseChangePassword}
          savePassword={handleSaveNewPassword}
          showSpinner={showSpinner}
          hideSpinner={hideSpinner}
          showError={showError}
          hideError={hideError}
        />
      )}
      <SC.Header>
        <h3>User Management</h3>
        <UI.GridSearchField
          search={search}
          handleSearchChange={handleSearchChange}
          handleSearchCancel={handleSearchCancel}
        />
      </SC.Header>
      <SC.Content elevation={3}>
        <DataGrid
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          rows={rows}
          columns={columns}
          // pageSize={25}
          rowsPerPageOptions={[25, 50, 100]}
          checkboxSelection
          disableSelectionOnClick
          density="compact"
          onSelectionModelChange={handleSelectionChange}
          disableColumnFilter
          disableColumnMenu
          className={SC.DataGridClass}
          loading={isUpdating}
          onRowClick={openUserEdit}
        />
      </SC.Content>
      <SC.Footer>
        <Button
          disabled={selectedRows?.length !== 1}
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleChangePassword}
        >
          Change password
        </Button>
      </SC.Footer>
    </SC.Container>
  );
});
