/* eslint-disable no-console */

import React from 'react';
import { useGlobalStateUtils } from '../hooks/useGlobalStateUtils';

export const withGlobalStateUtils = WrappedComponent => props => {
  const {
    globalState,
    dispatchGlobalState,
    error,
    spinner,
    confirm,
    showError,
    hideError,
    showAlert,
    hideAlert,
    showConfirm,
    hideConfirm,
    showSpinner,
    hideSpinner,
  } = useGlobalStateUtils();

  const { children } = props;

  return (
    <WrappedComponent
      globalState={globalState}
      dispatchGlobalState={dispatchGlobalState}
      error={error}
      spinner={spinner}
      confirm={confirm}
      showAlert={showAlert}
      hideAlert={hideAlert}
      showError={showError}
      hideError={hideError}
      showSpinner={showSpinner}
      hideSpinner={hideSpinner}
      showConfirm={showConfirm}
      hideConfirm={hideConfirm}
      {...props}
    >
      {children}
    </WrappedComponent>
  );
};
