import styled from '@emotion/styled';
import {
  AlertTitle as AlertTitleMUI,
  DialogActions as DialogActionsMUI,
} from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: 20px;
  right: 20px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 3500;
`;

export const AlertTitle = styled(AlertTitleMUI)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-flex-start;
  width: 100%;
`;

export const IconHolder = styled.div`
  color: gray;
  cursor: pointer;
  & :hover {
    color: black;
  }
`;

export const DialogActions = styled(DialogActionsMUI)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
`;
