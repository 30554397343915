import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useGlobalState } from 'store/GlobalStateContext';
import { useApi } from 'hooks/useApi';
import locationApi from 'api/userEvent';
import { useSearchInGrid } from 'hooks/useSearchInGrid';
import { usePeriodRange } from 'hooks/usePeriodRange';
import { useLocationList } from 'hooks/useLocationList';
import { useUsersList } from 'hooks/useUsersList';
import { getValueByActionType } from 'utils/getValueByActionType';

import {
  ADMIN_LOG_REFRESH_INTERVAL_MS,
  RoleLabelEnum,
  LogActionLabelType,
} from 'models/constants';

export const useAdminLog = () => {
  const { period, periodStartDate, setPeriodAndSave } = usePeriodRange({
    useCase: 'Admin-log',
  });
  const { getLocationById, locationsData } = useLocationList();
  const [{ users }] = useGlobalState();

  const { getUserNameById } = useUsersList(users);

  const { customData, handleCustomApiRequest, isUpdating } = useApi(
    locationApi,
    false
  );

  const { search, handleSearchChange, handleSearchCancel, filterRow } =
    useSearchInGrid([
      'creation',
      'name',
      'role',
      'type',
      'oldValue',
      'newValue',
    ]);

  const columns = useMemo(
    () => [
      {
        field: 'creation',
        align: 'left',
        headerAlign: 'center',
        headerName: 'Date and time',
        width: 180,
        renderCell: ({ row }) => {
          const creationDate = new Date(row?.creation);

          return (
            <>
              {`${creationDate.toLocaleDateString(
                'en-US'
              )} ${creationDate.toLocaleTimeString('en-US')}`}
            </>
          );
        },
      },
      {
        field: 'name',
        headerName: 'User Name',
        sortable: true,
        minWidth: 100,
        maxWidth: 180,
        flex: 1,
      },
      {
        field: 'role',
        headerName: 'User Role',
        sortable: true,
        minWidth: 80,
        maxWidth: 130,
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Action type',
        sortable: true,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        minWidth: 260,
        flex: 1,
      },
      {
        field: 'subject',
        headerName: 'Subject',
        sortable: true,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
      },
      {
        field: 'oldValue',
        headerName: 'Old Value',
        sortable: true,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
      },
      {
        field: 'newValue',
        headerName: 'New Value',
        sortable: true,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
      },
    ],
    []
  );

  const [sortModel, setSortModel] = useState([
    {
      field: 'creation',
      sort: 'desc',
    },
  ]);

  // const filterUserActions = row => !row?.type?.startsWith('PROCEDURE_');

  const mapRow = useCallback(
    row => {
      // const creationDate = new Date(row?.creation);

      const getValue = value =>
        getValueByActionType({
          type: row?.type,
          locationsData,
          getLocationById,
          getUserNameById,
        })(value);

      const oldValue = getValue(row?.oldValue);
      const newValue = getValue(row?.newValue);

      return {
        ...row,
        name: `${row?.initiator?.nameFirst} ${row?.initiator?.nameSecond}`,
        role: RoleLabelEnum[row?.initiator?.role] ?? '-',
        type: LogActionLabelType[row?.type] ?? row?.type,
        subject: row?.subject
          ? `${row?.subject?.nameFirst} ${row?.subject?.nameSecond}`
          : '-',
        oldValue,
        newValue,
      };
    },
    [customData]
  );

  const handleReloadData = () => {
    handleCustomApiRequest({
      requestName: 'getAll',
      requestData: {
        after: periodStartDate,
      },
    });
  };

  useEffect(() => {
    handleReloadData();
  }, [period]);

  useEffect(() => {
    const refresh = setInterval(async () => {
      await handleReloadData();
    }, ADMIN_LOG_REFRESH_INTERVAL_MS);
    return () => clearInterval(refresh);
  });

  return {
    rows: !customData.value
      ? []
      : customData.value.map(mapRow).filter(filterRow),
    isUpdating,
    search,
    handleSearchChange,
    handleSearchCancel,
    filterRow,
    columns,
    sortModel,
    setSortModel,
    period,
    periodStartDate,
    setPeriodAndSave,
  };
};
