/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import React from 'react';

const MAX_SPOT_LIGHT_OFFSET = 1000;
const secondaryLightDistance = 300;

const Lights = ({
  spotLightPosition,
  spotLightIntensity,
  ambientLightIntensity,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [offsetX, offsetY, offsetZ] = spotLightPosition;

  const getOffset = value => ((value - 50) / 100) * MAX_SPOT_LIGHT_OFFSET;

  return (
    <>
      <ambientLight intensity={ambientLightIntensity / 100} />
      <spotLight
        position={[getOffset(offsetX), -getOffset(offsetY), getOffset(offsetZ)]}
        intensity={spotLightIntensity / 100}
      />
      {[
        [
          -secondaryLightDistance / 3,
          -secondaryLightDistance / 2,
          -secondaryLightDistance / 1,
        ],
        [
          secondaryLightDistance / 3,
          secondaryLightDistance / 2,
          secondaryLightDistance / 1,
        ],
      ].map(position => (
        <spotLight
          key={JSON.stringify(position)}
          intensity={0.4}
          position={position}
        />
      ))}
    </>
  );
};

export default Lights;
