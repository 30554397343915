/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Box } from '@mui/material';
import { useConfirmAction } from 'hooks/useConfirmAction';
import { useGlobalState } from 'store/GlobalStateContext';
import * as UI from 'UI';
import { Link as SCLink } from 'UI/TabLabel/TabLabel.sc';
import logoutIcon from 'images/icon_logout.svg';
import * as SC from './AppLayout.sc';

/**
 * @param {{ tabLabels: string[]; tabContent: JSX[]; currentTab: number; handleChangeTab: Function }} labels - list of tabs labels
 */
export const AppLayout = ({ config, privateRoute }) => {
  const [globalState, dispatchGlobalState] = useGlobalState();
  const { confirmAction } = useConfirmAction();

  const logoutConfirm = () => {
    confirmAction({
      text: 'Please confirm the logout.',
      callbackFn: () => dispatchGlobalState({ type: 'LOGOUT' }),
    });
  };

  const logout = () => {
    if (globalState?.isDataChanged) {
      confirmAction({
        text: 'You have unsaved changes. Discard changed data?',
        callbackFn: () => {
          setTimeout(() => logoutConfirm());
        },
      });
      return;
    }

    logoutConfirm();
  };

  return (
    <SC.AppContainer>
      <SC.LeftPanel>
        <UI.UserInfo />
        <SC.Menu>
          <UI.SideBar config={config} />
          <Button
            color="inherit"
            onClick={logout}
            style={{
              marginLeft: '12px',
              marginBottom: '20px',
              justifySelf: 'flex-end',
            }}
          >
            <SCLink
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="1"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <img src={logoutIcon} alt="icon" />
                <span style={{ marginLeft: '12px' }}>Log Out</span>
              </Box>
            </SCLink>
          </Button>
        </SC.Menu>
      </SC.LeftPanel>
      <SC.RightPanel>
        <UI.Header />
        <UI.AppTabContent config={config} privateRoute={privateRoute} />
      </SC.RightPanel>
    </SC.AppContainer>
  );
};
