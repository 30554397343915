/* eslint-disable max-lines-per-function */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useApi } from 'hooks/useApi';
import userApi from 'api/user';

import { RoleLabelEnum, USERS_REFRESH_INTERVAL_MS } from 'models/constants';
import { useSearchInGrid } from 'hooks/useSearchInGrid';
// import * as UI from 'UI';
import { UserStatusCell } from './UserStatusCell/UserStatusCell';

export const useUserManagement = ({
  push = () => null,
  reloadDisabled = false,
}) => {
  const {
    data,
    setData,
    // handleDeleteData,
    globalState,
    isUpdating,
    handleReloadData,
    showSpinner,
    hideSpinner,
    showError,
    hideError,
  } = useApi(userApi, false);

  const { user } = globalState;

  const [selectedRows, setSelectedRows] = useState([]);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);

  const { search, handleSearchChange, handleSearchCancel, filterRow } =
    useSearchInGrid([
      // 'id',
      'nameFirst',
      'nameSecond',
      'fullName',
      'address',
      'phoneNumber',
      'email',
      'role',
    ]);

  const handleSelectionChange = model => setSelectedRows(model);

  const handleChangePassword = () => {
    setIsChangePasswordVisible(true);
  };

  const handleSaveNewPassword = async ({ userId, newPassword, token }) => {
    if (!token) {
      return userApi.save({ id: userId, password: newPassword });
    }

    return userApi.updatePasswordByToken({ userId, newPassword, token });
  };

  const handleCloseChangePassword = () => {
    setIsChangePasswordVisible(false);
  };

  const columns = useMemo(
    () => [
      // {
      //   field: 'id',
      //   align: 'center',
      //   headerAlign: 'center',
      //   headerName: 'ID',
      //   width: 90,
      // },
      {
        field: 'nameFirst',
        headerName: 'First Name',
        sortable: true,
        minWidth: 80,
        flex: 1,
      },
      {
        field: 'nameSecond',
        headerName: 'Last Name',
        sortable: true,
        minWidth: 80,
        flex: 1,
      },
      {
        field: 'address',
        headerName: 'Location',
        sortable: true,
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone Number',
        sortable: true,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        minWidth: 120,
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'E-mail',
        sortable: true,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'State',
        sortable: true,
        width: 100,
        align: 'center',
        headerAlign: 'center',
        renderCell: UserStatusCell,
        disableClickEventBubbling: true,
      },
      {
        field: 'role',
        headerName: 'Role',
        sortable: true,
        width: 140,
      },
      // {
      //   field: 'action',
      //   headerName: '☰',
      //   headerAlign: 'center',
      //   width: 30,
      //   sortable: false,
      //   renderCell: currentProps => (
      //     <UI.RowActionsPanel
      //       {...currentProps}
      //       editorUrl="/edit_user/"
      //       handleDeleteAction={handleDeleteData}
      //       isDeletable={currentProps.row.role !== RoleLabelEnum.MASTER_ADMIN}
      //     />
      //   ),
      //   disableClickEventBubbling: true,
      // },
    ],
    []
  );

  const [sortModel, setSortModel] = useState([
    {
      field: 'nameSecond',
      sort: 'asc',
    },
  ]);

  const mapRow = useCallback(
    row => ({
      id: row?.id,
      nameFirst: row?.nameFirst,
      nameSecond: row?.nameSecond,
      fullName: `${row?.nameFirst ?? ''} ${row?.nameSecond ?? ''}`,
      address: row?.location?.name,
      phoneNumber: row?.phoneNumber,
      email: row?.email,
      status: row?.status,
      role: row?.role ? RoleLabelEnum[row.role] : '',
    }),
    []
  );

  const openUserEdit = (row, e) => {
    e.stopPropagation();
    if (e?.target?.tagName?.toUpperCase() === 'INPUT') return;

    push(`/edit_user/${row?.id}`);
  };

  useEffect(async () => {
    setData({
      value: globalState.users.value,
    });
  }, [globalState.users]);

  useEffect(() => {
    document.title = 'ArthroSight - Users';
    if (user?.value?.userId) {
      handleReloadData();
    }
  }, []);

  useEffect(() => {
    const refresh = setInterval(async () => {
      if (!reloadDisabled) {
        await handleReloadData();
      }
    }, USERS_REFRESH_INTERVAL_MS);
    return () => clearInterval(refresh);
  }, []);

  return {
    columns,
    rows: !data.value ? [] : data.value.map(mapRow).filter(filterRow),
    selectedRows,
    setSelectedRows,
    isUpdating,
    search,
    handleSearchChange,
    handleSearchCancel,
    sortModel,
    setSortModel,
    handleSelectionChange,
    handleChangePassword,
    handleSaveNewPassword,
    handleCloseChangePassword,
    isChangePasswordVisible,
    setIsChangePasswordVisible,
    openUserEdit,
    showSpinner,
    hideSpinner,
    showError,
    hideError,
  };
};
