/* eslint-disable prettier/prettier */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useApi } from 'hooks/useApi';
import patientApi from 'api/patient';
import { useUsersList } from 'hooks/useUsersList';
import { recalcAndSetAge } from 'utils/recalcAndSetAge';
import { useLocationList } from 'hooks/useLocationList';
import { useAssignedSurgeons } from 'hooks/useAssignedSurgeons';
import { usePatientProcedureList } from 'hooks/usePatientProcedureList';
import { useConfirmExit } from 'hooks/useConfirmExit';
import { purgeNullProps } from 'utils/purgeNullProps';
import { ProcedureStatusEnum } from 'models/constants';
import { getRecordById } from 'utils/getRecordById';

export const usePatientRecord = () => {
  const { confirmExit } = useConfirmExit();
  const { assignedSurgeons, reloadAssignedSurgeons } = useAssignedSurgeons();
  const {
    row,
    setRow,
    handleReloadData,
    handleGetDataById,
    handleSaveData,
    isUpdating,
    setIsUpdating,
    error,
    showError,
    hideError,
    globalState,
    dispatchGlobalState,
    showConfirm,
    showAlert,
    showSpinner,
    hideSpinner,
  } = useApi(patientApi, false);
  const history = useHistory();
  const { id } = useParams();
  const { users, user } = globalState;
  const { isNewProcedureAllowed, createProcedure, reloadProcedureList } =
    usePatientProcedureList(id);
  const { locationsData, locationListOptions } = useLocationList();
  const [isCardEditable, setIsCardEditable] = useState(false);

  const { surgeonListOptions, radiologyListOptions, engineerListOptions } =
    useUsersList(users);
  const [age, setAge] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    trigger,
    values,
    getValues,
    reset,
    watch,
  } = useForm();

  const handleCreateNewProcedure = async () => {
    const preparedData = {
      patientId: Number(id),
      status: ProcedureStatusEnum.NEW,
      creatorId: user?.value?.userId,
      surgeonId: user?.value?.userId,
      surgeryLocationId: getRecordById(
        globalState?.user?.value?.userId,
        globalState?.users?.value
      )?.location?.id,
    };

    const result = await createProcedure(preparedData);

    if (result) {
      await handleReloadData();
      await reloadProcedureList();
      await handleGetDataById(id);
    }
  };

  const handleCancelChanges = () => {
    reset();
  };

  const onSubmit = async newData => {
    handleSaveData(
      purgeNullProps({
        id: newData.id,
        nameFirst: newData.nameFirst,
        nameSecond: newData.nameSecond,
        email: newData.email,
        phoneNumber: newData.phoneNumber,
        medicalInsuranceId: newData.medicalInsuranceId,
        gender: newData.gender,
        dateOfBirth: newData.dateOfBirth,
      })
    );
  };

  const isDeletionAllowedForSurgeon = () => {
    const files = Array.isArray(row?.value?.procedures)
      ? row.value.procedures.reduce((acc, next) => {
          if (Array.isArray(next?.files)) {
            return [...acc, ...next.files];
          }
          return acc;
        }, [])
      : [];
    return files?.length === 0;
  };

  const deleteCurrentPatient = async () => {
    hideError();
    showSpinner();

    const result = await patientApi.remove(id);

    hideSpinner();

    if (result?.error) {
      showError(result?.error);
      return;
    }

    showAlert({
      type: 'success',
      text: 'The patient was deleted successfully',
    });
    history.push('/patients');
  };

  const handleDeletePatient = () => {
    showConfirm({
      text: 'Please confirm patient deletion.',
      yes: deleteCurrentPatient,
    });
  };

  useEffect(async () => {
    if (row?.value?.id) {
      setRow({
        value: {},
      });
    }
    reloadAssignedSurgeons();
    handleGetDataById(id);
  }, []);

  useEffect(() => {
    // skip effect if locationList is not ready
    if (!Array.isArray(locationsData?.value)) {
      return;
    }

    if (row?.value) {
      const dateOfBirth = row?.value?.dateOfBirth
        ? new Date(row?.value?.dateOfBirth)
        : '';
      const formDataValues = {
        ...row?.value,
        dateOfBirth,
        phoneNumber: row?.value?.phoneNumber || '',
      };

      reset(formDataValues);
      recalcAndSetAge(dateOfBirth, setAge);
      setIsCardEditable(false);

      if (
        !!row?.value?.id &&
        (!row?.value?.dateOfBirth ||
          !row?.value?.nameFirst ||
          !row?.value?.nameSecond ||
          !row?.value?.medicalInsuranceId ||
          !row?.value?.email ||
          !row?.value?.phoneNumber)
      ) {
        setIsCardEditable(true);
      }
    }
  }, [row, locationsData]);

  useEffect(() => {
    if (isDirty !== globalState.isDataChanged) {
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: isDirty,
      });
    }
  });

  return {
    handleSubmit,
    onSubmit,
    errors,
    control,
    reset,
    isDirty,
    isValid,
    trigger,
    isUpdating,
    setIsUpdating,
    error,
    showError,
    values,
    getValues,
    watch,
    row,
    surgeonListOptions,
    radiologyListOptions,
    engineerListOptions,
    locationListOptions,
    age,
    recalcAge: date => recalcAndSetAge(date, setAge),
    isCardEditable,
    setIsCardEditable,
    procedureList: Array.isArray(row?.value?.procedures)
      ? row.value.procedures
      : [],
    handleCreateNewProcedure,
    isNewProcedureAllowed,
    id,
    handleCancelChanges,
    confirmExit,
    isDeletionAllowedForSurgeon,
    handleDeletePatient,
    globalState,
    dispatchGlobalState,
    assignedSurgeons,
  };
};
