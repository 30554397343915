/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React from 'react';
import { Button } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Panel } from 'UI/Panel/Panel';
import { EmptySpace } from 'UI/EmptySpace/EmptySpace.sc';
import { FormTextField } from 'UI/FormTextField/FormTextField';
import { usePasswordChanger } from './usePasswordChanger';
import * as SC from './PasswordChanger.sc';

export const PasswordChanger = props => {
  const { closeWindow, userId, user = false, cancelDisabled = false } = props;
  const {
    handleContainerClick,
    handleSubmit,
    onSubmit,
    control,
    isPasswordVisible,
    setIsPasswordVisible,
    password,
    isDirty,
  } = usePasswordChanger(props);

  return (
    <SC.Overlay>
      <SC.Container onClick={handleContainerClick}>
        <Panel
          title={<SC.PanelTitle>Change Password</SC.PanelTitle>}
          handleCloseIcon={closeWindow}
        >
          <SC.Content>
            Please, enter new password for user{' '}
            {user?.userId ? (
              <>
                :
                <br />
                {user.nameFirst} {user.nameSecond}, {user.email}
              </>
            ) : (
              <>#{userId}</>
            )}
            <hr />
            <SC.FlexRow style={{ width: '100%', alignItems: 'flex-start' }}>
              <SC.Form onSubmit={handleSubmit(onSubmit)}>
                <SC.FlexColumn>
                  <FormTextField
                    label="Password"
                    name="password"
                    control={control}
                    autoComplete="off"
                    type={isPasswordVisible ? 'text' : 'password'}
                  />
                  <EmptySpace />
                  <FormTextField
                    label="Repeat Password"
                    name="password_repeat"
                    control={control}
                    autoComplete="off"
                    type={isPasswordVisible ? 'text' : 'password'}
                  />
                </SC.FlexColumn>
              </SC.Form>
              <Button onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                {isPasswordVisible && <VisibilityOffIcon />}
                {!isPasswordVisible && <VisibilityIcon />}
              </Button>
            </SC.FlexRow>
            <SC.Tips>
              The password must be from 8 to 12 characters long and must include
              at least:
              <ul>
                <li>one number character</li>
                <li>one lowercase letter</li>
                <li>one uppercase letter </li>
                <li>one special character: !@#$%^&*(),.?&quot;:{}|&lt;\&gt;</li>
              </ul>
            </SC.Tips>
            <SC.Buttons
              id="control"
              style={{ justifyContent: 'space-between' }}
            >
              {!cancelDisabled && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={closeWindow}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty}
              >
                Update password
              </Button>
            </SC.Buttons>
          </SC.Content>
        </Panel>
      </SC.Container>
    </SC.Overlay>
  );
};
