/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React from 'react';
import { Stack, TextField, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as UI from 'UI';
import { RoleLabelEnum } from 'models/constants';
import * as SC from './Chat.sc';
import { Message } from './Message/Message';
import { useChat } from './useChat';

export const Chat = ({ sender, receiver, messages }) => {
  const {
    chatMessages,
    scrollAreaRef,
    messageTextRef,
    sendNewMessage,
    isUpdating,
    suggestedMessageIds,
    resetSuggested,
    handleChangeText,
  } = useChat({
    sender,
    receiver,
    messages,
  });

  return (
    <SC.Container onClick={resetSuggested}>
      <SC.Header>
        <AccountCircleIcon sx={{ fontSize: 55 }} />
        <SC.Info>
          <SC.Name>
            {sender?.nameFirst} {sender?.nameSecond}
          </SC.Name>
          <SC.Role>
            {' '}
            {RoleLabelEnum[sender?.role]}
            {sender?.location?.name ? `, ${sender?.location?.name}` : ''}
          </SC.Role>
        </SC.Info>
        {suggestedMessageIds.length > 0 && (
          <SC.IconHolder>
            <SC.NewLabelForChat>
              {suggestedMessageIds.length}
            </SC.NewLabelForChat>
          </SC.IconHolder>
        )}
      </SC.Header>
      <SC.Messages ref={scrollAreaRef}>
        {chatMessages.reverse().map(row => (
          <Message
            key={row?.id}
            senderName={`${sender?.nameFirst} ${sender?.nameSecond}`}
            isAnswer={receiver?.id === row.senderId}
            {...row}
            isSuggested={suggestedMessageIds.includes(row.id)}
          />
        ))}
      </SC.Messages>
      <UI.EmptySpace />
      <Stack
        direction="column"
        width="100%"
        spacing={1}
        sx={{ flex: '0 0 auto', justifySelf: 'flex-end' }}
      >
        <TextField
          ref={messageTextRef}
          defaultValue=""
          className={SC.MessageClass}
          autoFocus
          multiline
          inputProps={{
            maxLength: 250,
          }}
          disabled={isUpdating}
          onChange={handleChangeText}
        />
        <Button
          disabled={isUpdating}
          variant="contained"
          color="secondary"
          onClick={sendNewMessage}
        >
          Send Message
        </Button>
      </Stack>
    </SC.Container>
  );
};
