/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable max-lines-per-function */
import React, { useState, useMemo, useEffect } from 'react';
import { useApi } from 'hooks/useApi';
import assistantApi from 'api/assistant';
import { RoleEnum, UserStatusEnum } from 'models/constants';

export const useAssistantAssignment = () => {
  const {
    globalState,
    dispatchGlobalState,
    error,
    isUpdating,
    showError,
    hideError,
    showSpinner,
    hideSpinner,
  } = useApi(assistantApi, false);
  const { user, users } = globalState;
  const [assigned, setAssigned] = useState([]);

  const available = users?.value
    ? users?.value?.filter(
        ({ id, role, status, location }) =>
          !assigned?.find(user => user?.id === id) &&
          role === RoleEnum.SURGICAL_ASSISTANT &&
          status === UserStatusEnum.ACTIVE &&
          location?.id === user?.value?.locationId
      )
    : [];

  const [availableSelected, setAvailableSelected] = useState([]);
  const [assignedSelected, setAssignedSelected] = useState([]);

  const handleToggleSelection = (id, selected, setSelected) => {
    if (selected.includes(id)) {
      const newValue = selected.filter(item => item !== id);
      setSelected(newValue);
      return;
    }
    const newValue = [...selected];
    newValue.push(id);
    setSelected(newValue);
  };

  const handleClickAvailable = id =>
    handleToggleSelection(id, availableSelected, setAvailableSelected);

  const handleClickAssigned = id =>
    handleToggleSelection(id, assignedSelected, setAssignedSelected);

  const loadAssigned = async () => {
    const newAssigned = await assistantApi.getAllAssigned({
      id: user?.value?.userId,
    });
    if (newAssigned?.value?.assistants) {
      setAssigned(newAssigned.value.assistants);
    }
  };

  const handleUnassign = async () => {
    hideError();
    assignedSelected.forEach(async assistantId => {
      showSpinner();
      const result = await assistantApi.deleteUnassigned({
        surgeonId: user.value.userId,
        assistantId,
      });
      if (result?.error) {
        showError(result?.error);
      } else {
        handleToggleSelection(
          assistantId,
          assignedSelected,
          setAssignedSelected
        );
      }
      dispatchGlobalState({
        type: 'SET_users',
        payload: [],
      });
      await loadAssigned();
      dispatchGlobalState({
        type: 'SET_users',
        payload: [],
      });
      hideSpinner();
    });
  };

  const handleAssign = () => {
    hideError();
    availableSelected.forEach(async assistantId => {
      showSpinner();
      const result = await assistantApi.assign({
        surgeonId: user.value.userId,
        surgeonAssistantId: assistantId,
      });
      if (result?.error) {
        showError(result?.error);
      } else {
        handleToggleSelection(
          assistantId,
          availableSelected,
          setAvailableSelected
        );
      }
      await loadAssigned();
      dispatchGlobalState({
        type: 'SET_users',
        payload: [],
      });
      hideSpinner();
    });
  };

  useEffect(() => {
    dispatchGlobalState({
      type: 'SET_users',
      payload: [],
    });
    loadAssigned();
  }, []);

  return {
    assigned,
    assignedSelected,
    available,
    availableSelected,
    error,
    isUpdating,
    handleClickAvailable,
    handleClickAssigned,
    handleAssign,
    handleUnassign,
  };
};
