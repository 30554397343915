import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import * as UI from '../../UI';
import * as SC from './Chats.sc';
import { useChats } from './useChats';
import { ChatInfo } from './ChatInfo/ChatInfo';
import { Chat } from './Chat/Chat';

export const Chats = () => {
  const { push } = useHistory();
  const {
    currentChats,
    allMessages,
    isUpdating,
    error,
    selectedChatUser,
    receiver,
    selectedChatUserId,
    handleClickChatInfo,
    chatListRef,
  } = useChats();

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 32px)" />
      <SC.Header>
        <h3>Messages</h3>
      </SC.Header>
      {isUpdating && <UI.GlobalSpinner />}
      <SC.Wrapper>
        <SC.PanelHolder>
          <UI.Panel title="List of chats">
            <SC.ChatsHolder ref={chatListRef}>
              <SC.Chats>
                {currentChats.map(row => (
                  <ChatInfo
                    key={row?.senderId}
                    data={row}
                    selected={selectedChatUserId === row?.senderId}
                    handleClick={handleClickChatInfo}
                  />
                ))}
                {currentChats.length === 0 && <SC.NoChats>No chats</SC.NoChats>}
              </SC.Chats>
            </SC.ChatsHolder>
            <Button
              type="button"
              variant="contained"
              color="success"
              onClick={() => push('/new_message')}
            >
              Create New Chat
            </Button>
          </UI.Panel>
          <UI.EmptySpace />
          <UI.EmptySpace />
          {selectedChatUser?.id > 0 && (
            <UI.Panel>
              <Chat
                sender={selectedChatUser}
                receiver={receiver}
                messages={allMessages}
              />
            </UI.Panel>
          )}
        </SC.PanelHolder>
      </SC.Wrapper>
    </SC.Container>
  );
};
