import React from 'react';
import { Stack } from '@mui/material';
import * as UI from 'UI';
import {
  ProcedureStatusEnum,
  MIN_INCLINATION_IN_DEGREE,
  MAX_INCLINATION_IN_DEGREE,
  MIN_ANTEVERSION_IN_DEGREE,
  MAX_ANTEVERSION_IN_DEGREE,
  MIN_DIFFERENCE,
  MAX_DIFFERENCE,
} from 'models/constants';
import { getNumberRangeValidator } from 'models/validations';
import * as SC from './Surgery.sc';

export const Surgery = ({ hookData }) => {
  const { isUpdating, control, watch } = hookData;
  const isDisabled =
    watch()?.status !== ProcedureStatusEnum.SCHEDULED ||
    isUpdating ||
    watch()?.deleted;

  return (
    <SC.FlexColumn style={{ width: '100%', gap: '15px' }}>
      <Stack
        direction="row"
        width="100%"
        maxWidth="100%"
        spacing={2}
        justifyContent="space-between"
      >
        <UI.FormDateSelector
          label="Date of Surgery"
          name="dateOfSurgery"
          control={control}
          className={SC.DateOfBirthClass}
          disabled={isUpdating || watch()?.deleted}
        />
      </Stack>
      <SC.FlexRow style={{ width: '100%', gap: '15px' }}>
        <UI.LabeledArea label="Postoperative Angles" width="100%">
          <Stack direction="column" width="100%" spacing={1}>
            <UI.FormNumberField
              label="Inclination,°"
              name="operativeInclination"
              control={control}
              disabled={isDisabled}
              min={MIN_INCLINATION_IN_DEGREE}
              max={MAX_INCLINATION_IN_DEGREE}
              rules={getNumberRangeValidator(
                MIN_INCLINATION_IN_DEGREE,
                MAX_INCLINATION_IN_DEGREE
              )}
              className={SC.NumberClass}
            />
            <UI.FormNumberField
              label="Anteversion,°"
              name="operativeAnteVersion"
              control={control}
              min={MIN_ANTEVERSION_IN_DEGREE}
              max={MAX_ANTEVERSION_IN_DEGREE}
              rules={getNumberRangeValidator(
                MIN_ANTEVERSION_IN_DEGREE,
                MAX_ANTEVERSION_IN_DEGREE
              )}
              disabled={isDisabled}
            />
          </Stack>
        </UI.LabeledArea>
        <UI.LabeledArea label="Difference Measurements" width="100%">
          <Stack direction="column" width="100%" spacing={1}>
            <UI.FormNumberField
              label="Leg Length, mm"
              name="legLengthDifference"
              control={control}
              min={MIN_DIFFERENCE}
              max={MAX_DIFFERENCE}
              disabled={isDisabled}
              rules={getNumberRangeValidator(MIN_DIFFERENCE, MAX_DIFFERENCE)}
            />
            <UI.FormNumberField
              label="Femoral Offset, mm"
              name="femoralOffsetDifference"
              control={control}
              min={MIN_DIFFERENCE}
              max={MAX_DIFFERENCE}
              disabled={isDisabled}
              rules={getNumberRangeValidator(MIN_DIFFERENCE, MAX_DIFFERENCE)}
            />
          </Stack>
        </UI.LabeledArea>
      </SC.FlexRow>
    </SC.FlexColumn>
  );
};
