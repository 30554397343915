/* eslint-disable no-console */
/* DO NOT FORGET to add export of this file to /src/store/reducers/index.js */
import * as FP from '../../utils/fp-js';

const defaultYesCallback = () => console.log('Set callback for YES answer.');
const defaultNoCallback = () => console.log('Set callback for NO answer.');

export const confirm = (state, action) =>
  FP.match(action.type)
    .on('SHOW_CONFIRM', {
      title: action.payload?.title,
      text: action.payload?.text,
      contentRender: action.payload?.contentRender,
      yes: action.payload?.yes || defaultYesCallback,
      no: action.payload?.no || defaultNoCallback,
      isVisible: true,
    })
    .on('HIDE_CONFIRM', {
      ...state,
      yes: defaultYesCallback,
      no: defaultNoCallback,
      isVisible: false,
    })
    .otherwise(state);
