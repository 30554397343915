/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { message: '' };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      this.state.message === '' &&
      nextState.message !== '' &&
      nextProps.onError != null
    ) {
      nextProps.onError(new Error(nextState.message));
    }
    return true;
  }

  static getDerivedStateFromError(error) {
    return { message: error.message };
  }

  componentDidCatch() {}

  render() {
    if (this.state.message !== '') return null;
    return this.props.children;
  }
}

export default ErrorBoundary;
