/* eslint-disable no-unused-vars */
import {
  RoleEnum,
  RadiologistProcedureStatusEnum,
  EngineerProcedureStatusEnum,
} from 'models/constants';
import * as FP from 'utils/fp-js';

export const getPermissionToProcedureAccess = ({ userRole, procedureStatus }) =>
  FP.match(userRole)
    .on(
      RoleEnum.RADIOLOGIST,
      () => !!RadiologistProcedureStatusEnum[procedureStatus]
    )
    .on(
      RoleEnum.IMAGE_ENGINEER,
      () => !!EngineerProcedureStatusEnum[procedureStatus]
    )
    .otherwise(true);
