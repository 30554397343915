import { createContext, useContext } from 'react';
import { defaultGlobalState } from './defaultGlobalState';

const initialGlobalStateContextValue = [
  defaultGlobalState, // state object
  () => undefined, // dispatch function
];

export const GlobalStateContext = createContext(initialGlobalStateContextValue);
export const useGlobalState = () => useContext(GlobalStateContext);
