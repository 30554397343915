import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import * as UI from 'UI';
import { withGlobalStateUtils } from 'utils/withGlobalStateUtils';
import * as SC from './PatientsManagement.sc';
import { usePatientsManagement } from './usePatientsManagement';

export const PatientsManagement = withGlobalStateUtils(() => {
  const hookData = usePatientsManagement();
  const {
    data,
    columns,
    isUpdating,
    error,
    search,
    handleSearchChange,
    handleSearchCancel,
    sortModel,
    setSortModel,
    openPatient,
    period,
    setPeriod,
  } = hookData;

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 40px)" />
      <SC.Header>
        <h3>Patients Management</h3>
        <UI.GridSearchField
          search={search}
          handleSearchChange={handleSearchChange}
          handleSearchCancel={handleSearchCancel}
        />
      </SC.Header>
      <SC.Paper elevation={5}>
        <DataGrid
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          rows={data}
          columns={columns}
          // pageSize={25}
          rowsPerPageOptions={[25, 50, 100]}
          disableSelectionOnClick
          density="compact"
          // onSelectionModelChange={handleSelectionChange}
          disableColumnFilter
          disableColumnMenu
          className={SC.DataGridClass}
          loading={isUpdating}
          onRowClick={openPatient}
          components={{
            Footer: () => (
              <UI.FooterWithPeriodSelector
                period={period}
                setPeriod={setPeriod}
                itemsName="Show deleted patients"
              />
            ),
          }}
        />
      </SC.Paper>
    </SC.Container>
  );
});
