/**
 * Returns formated date
 * @param {number} timestamp - unix time
 * @returns {string}
 */
export const convertToLocaleDate = timestamp => {
  const result = new Date(timestamp).toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return result === 'Invalid Date' ? '' : result;
};

/**
 * Returns formated time
 * @param {number} timestamp - unix time
 * @returns {string}
 */
export const convertToLocaleTime = timestamp => {
  const result = new Date(timestamp).toLocaleTimeString('en-US');
  return result === 'Invalid Date' ? '' : result;
};
