import styled from '@emotion/styled';
import { FlexRow, FlexColumn } from 'SC/App.sc';

export const Container = styled(FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
`;

export const LeftPanel = styled(FlexColumn)`
  justify-content: flex-start;
  align-items: stretch;

  margin-right: 15px;
  width: 33%;
  max-width: 170px;
`;

export const RightPanel = styled(FlexColumn)`
  flex: 1;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
`;

export const Parameters = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 15px;
`;

export const AnatomicAngles = styled(FlexColumn)`
  justify-content: flex-start;
  align-items: stretch;
`;
