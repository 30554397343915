/* eslint-disable no-restricted-globals */
import React from 'react';
import { useHistory } from 'react-router-dom';
// import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import * as SC from './RowActionsPanel.sc';
import { withGlobalStateUtils } from '../../utils/withGlobalStateUtils';

export const RowActionsPanel = withGlobalStateUtils(
  ({
    row,
    hideError,
    showConfirm,
    editorUrl,
    handleDeleteAction,
    isDeletable = true,
    disableEdit = true,
    callbackFn,
  }) => {
    if (!editorUrl) {
      throw Error('Set editorUrl prop for RowActionPanel component usage');
    }
    const history = useHistory();

    const handleEdit = e => {
      e.stopPropagation();
      history.push(`${editorUrl}${row.id}`);
    };

    const handleDelete = async e => {
      e.stopPropagation();
      hideError();
      const confirmationText = `Delete "${row.name || 'row'}" with id #${
        row.id
      } ?`;
      showConfirm({
        title: 'Confirmation',
        text: confirmationText,
        yes: async () => {
          const result = await handleDeleteAction(row.id);
          if (result && callbackFn) {
            callbackFn();
          }
        },
      });
    };

    return (
      <SC.Container>
        {!disableEdit && (
          <SC.EditButton type="button" variant="text" onClick={handleEdit}>
            <MoreVertIcon />
          </SC.EditButton>
        )}
        {isDeletable && (
          <SC.DeleteButton type="button" variant="text" onClick={handleDelete}>
            <DeleteForeverIcon />
          </SC.DeleteButton>
        )}
      </SC.Container>
    );
  }
);
