import { useState } from 'react';
import { useGlobalState } from 'store/GlobalStateContext';
import { containsSearchInRowByKeys } from '../utils/containsSearchInRowByKeys';

const storage = window.localStorage;

/**
 * @param {string[]} searchFieldNames - string representation of Date
 */
export const useSearchInGrid = (searchFieldNames, tableName) => {
  const [globalState] = useGlobalState();
  const { user } = globalState;

  const [search, setSearch] = useState(
    tableName
      ? storage.getItem(`${user.value.userId}-${tableName}-search`) ?? ''
      : ''
  );

  const saveAndSetSearch = value => {
    if (tableName) {
      storage.setItem(`${user.value.userId}-${tableName}-search`, value);
    }
    setSearch(value);
  };

  const handleSearchChange = event => {
    saveAndSetSearch(event.target.value);
  };
  const handleSearchCancel = () => {
    saveAndSetSearch('');
  };

  const filterRow = row =>
    containsSearchInRowByKeys({
      row,
      search,
      keys: searchFieldNames,
    });

  return {
    search,
    handleSearchChange,
    handleSearchCancel,
    filterRow,
  };
};
