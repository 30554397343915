import styled from '@emotion/styled';
import * as SC from '../../SC/App.sc';

export * from '../../SC/App.sc';

export const PanelHolder = styled(SC.FlexRow)`
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100%;
`;

export const ChatsHolder = styled(SC.FlexColumn)`
  flex: 1 1 auto;
  justify-content: flex-start;
  overflow-y: scroll;
  label: ChatHolder;
`;

export const Chats = styled(SC.FlexColumn)`
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  label: Chats;
`;

export const NoChats = styled(SC.FlexColumn)`
  color: darkgray;
  font-size: 0.9em;
`;
