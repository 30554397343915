import { createApiMethodsSet } from './createApiMethodsSet';
import { httpService } from './httpService';

const api = createApiMethodsSet();

api.getIncomingList = async ({ receiverId }) =>
  httpService({
    url: `/user/message/receive/${receiverId}/all/`,
    options: {
      method: 'GET',
    },
  });

api.getSentList = async ({ senderId }) =>
  httpService({
    url: `/user/message/send/${senderId}/all/`,
    options: {
      method: 'GET',
    },
  });

api.setAsRead = async messageId =>
  httpService({
    url: `/user/message/read/${messageId}`,
    options: {
      method: 'GET',
    },
  });

/* TODO - switch from receiverId to receivers array completely */
api.create = async ({
  procedureId,
  receiverId,
  receivers,
  replyToId,
  senderId,
  text,
}) =>
  httpService({
    url: '/user/message/send',
    options: {
      method: 'POST',
      data: { procedureId, receiverId, receivers, replyToId, senderId, text },
    },
  });

export default api;
