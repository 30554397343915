import React from 'react';
import {
  ProcedureStatusLabelEnum,
  ProcedureStatusColorEnum,
} from 'models/constants';
import * as UI from 'UI';
import * as SC from './Procedures.sc';

export const getSurgeonColumns = () => [
  {
    field: 'id',
    align: 'center',
    headerAlign: 'center',
    headerName: '#',
    width: 90,
  },
  {
    field: 'medicalInsuranceId',
    headerName: 'Med. Insurance #',
    hide: true,
  },
  {
    field: 'created',
    headerName: 'Created',
    headerAlign: 'center',
    sortable: true,
    width: 100,
    align: 'center',
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'center',
    renderCell: ({ value }) => {
      return (
        <SC.FlexColumn style={{ width: '100%', height: 'calc(100% - 8px)' }}>
          <SC.StatusString color={ProcedureStatusColorEnum[value]}>
            {ProcedureStatusLabelEnum[value]}
          </SC.StatusString>
        </SC.FlexColumn>
      );
    },
    width: 130,
  },
  {
    field: 'procedure',
    headerName: 'Description',
    sortable: true,
    width: 160,
    renderCell: UI.LongText,
  },
  {
    field: 'sideSurgeon',
    headerName: 'Side',
    headerAlign: 'center',
    sortable: true,
    width: 80,
    align: 'center',
  },
  {
    field: 'patientName',
    headerName: 'Patient Name',
    sortable: true,
    minWidth: 100,
    flex: 1,
    renderCell: UI.LongText,
  },
  {
    field: 'gender',
    headerName: 'Gender',
    headerAlign: 'center',
    sortable: true,
    width: 90,
    align: 'center',
  },
  {
    field: 'age',
    headerName: 'Age',
    headerAlign: 'center',
    sortable: true,
    width: 60,
    align: 'center',
  },
  {
    field: 'dateOfSurgery',
    headerName: 'DoS',
    headerAlign: 'center',
    sortable: true,
    width: 100,
    align: 'center',
  },
  {
    field: 'radiologyLocation',
    headerName: 'Radiology',
    sortable: true,
    minWidth: 60,
    flex: 1,
    renderCell: UI.LongText,
  },
];
