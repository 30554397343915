/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

import { Stack, Button, TextField } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import * as UI from 'UI';
import { FileTypeEnum, RoleEnum } from 'models/constants';
import * as SC from 'components/Procedure/Procedure.sc';
import { Information } from 'components/Procedure/Information/Information';
import { Pelvis } from 'components/Procedure/Pelvis/Pelvis';
import { Surgery } from 'components/Procedure/Surgery/Surgery';
import { Notes } from 'components/Procedure/Notes/Notes';
import { ListOfFiles } from 'components/Procedure/ListOfFiles/ListOfFiles';

export const SurgeonProcedure = ({ hookData }) => {
  const { push } = useHistory();
  const {
    isUpdating,
    id,
    control,
    engineerListOptions,
    getUserNameById,
    handleSubmit,
    onSubmit,
    reloadProcedureFiles,
    dispatchGlobalState,
    watch,
    isNotCurrentEngineer,
    handleUpdateUserIdOnUpload,
    row,
    columns,
    rows,
  } = hookData;

  return (
    <SC.Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" width="100%" spacing={4} paddingBottom={2}>
        <Stack direction="row" width="100%" spacing={4}>
          <Stack direction="column" width="100%" spacing={2}>
            <UI.Panel title="Procedure Information">
              <Information hookData={hookData} userRole={RoleEnum.SURGEON} />
            </UI.Panel>
            <UI.Panel title="Send Message">
              <Button
                variant="contained"
                color="success"
                title="Send message to other user"
                onClick={() => {
                  dispatchGlobalState({
                    type: 'SET_newMessageReceiverId',
                    payload: '',
                  });
                  dispatchGlobalState({
                    type: 'SET_newMessageRole',
                    payload: '',
                  });
                  dispatchGlobalState({
                    type: 'SET_newMessageProcedureId',
                    payload: watch()?.id ?? '',
                  });
                  push('/new_message');
                }}
                type="button"
              >
                Send Message &nbsp; <MailOutlineIcon />
              </Button>
            </UI.Panel>
          </Stack>
          <UI.Panel title="Pelvis Registration Parameters">
            <Stack direction="column" width="100%" spacing={4}>
              <Stack direction="column" width="100%" spacing={2}>
                {isNotCurrentEngineer && (
                  <Stack direction="row" width="100%" spacing={2}>
                    {isNotCurrentEngineer ? (
                      <TextField
                        size="small"
                        label="Assigned Imaging Engineer"
                        readOnly
                        value={getUserNameById(watch()?.engineerId) ?? ''}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ flex: '1 1 auto' }}
                        variant="filled"
                        disabled
                      />
                    ) : (
                      <UI.FormSelectField
                        label="Assigned Imaging Engineer"
                        name="engineerId"
                        control={control}
                        options={engineerListOptions}
                        disabled={isUpdating}
                      />
                    )}
                    {/* <Button
                      variant="outlined"
                      title="Send message to Engineer"
                      onClick={() => {
                        dispatchGlobalState({
                          type: 'SET_newMessageReceiverId',
                          payload: watch()?.engineerId ?? '',
                        });
                        dispatchGlobalState({
                          type: 'SET_newMessageLocationId',
                          payload: '',
                        });
                        dispatchGlobalState({
                          type: 'SET_newMessageRole',
                          payload: RoleEnum.IMAGE_ENGINEER,
                        });
                        dispatchGlobalState({
                          type: 'SET_newMessageProcedureId',
                          payload: watch()?.id ?? '',
                        });
                        push('/new_message');
                      }}
                      type="button"
                    >
                      <MailOutlineIcon />
                    </Button> */}
                  </Stack>
                )}
                <Pelvis hookData={hookData} />
                <ListOfFiles
                  handleReload={reloadProcedureFiles}
                  fileType={FileTypeEnum.MODEL}
                  procedureId={id}
                  handleUpdateUserIdOnUpload={handleUpdateUserIdOnUpload}
                  readOnly={isNotCurrentEngineer}
                  procedure={row?.value}
                />
              </Stack>
            </Stack>
          </UI.Panel>
        </Stack>
        <Stack direction="row" width="100%" spacing={4}>
          <UI.Panel title="Surgery">
            <Surgery hookData={hookData} />
          </UI.Panel>
          <Stack direction="column" width="100%" spacing={4}>
            <UI.Panel title="Notes">
              <Notes procedureId={id} />
            </UI.Panel>
          </Stack>
        </Stack>
        <UI.Panel title="Procedure History" sx={{ height: '420px' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            // pageSize={25}
            rowsPerPageOptions={[25, 50, 100]}
            disableSelectionOnClick
            density="compact"
            // onSelectionModelChange={handleSelectionChange}
            disableColumnFilter
            disableColumnMenu
            className={SC.DataGridClass}
            loading={isUpdating}
          />
        </UI.Panel>
      </Stack>
    </SC.Form>
  );
};
