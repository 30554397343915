/* eslint-disable no-unused-vars */
import axios from 'axios';
import { createApiMethodsSet } from './createApiMethodsSet';
import { httpService } from './httpService';
import { fileUploadService } from './fileUploadService';
import { config } from '../config/config';

const api = createApiMethodsSet();

api.getAll = async () =>
  httpService({
    url: `/file/all`,
    options: {
      method: 'GET',
    },
  });

api.getAllByProcedureId = async ({ id = '' }) =>
  httpService({
    url: `/file/list-by-procedure-id/${id}`,
    options: {
      method: 'GET',
    },
  });

api.getById = async id =>
  httpService({
    url: `/file/download/${id}`,
    options: {
      method: 'GET',
    },
  });

api.upload = async (formData, setPercentage, cancelToken, axiosForUpload) => {
  return fileUploadService(
    '/file/upload-many',
    formData,
    undefined,
    setPercentage,
    cancelToken,
    axiosForUpload
  );
};

api.download = (id, headers, setPercentage, signal) => {
  const axiosConfig = {
    headers,
    responseType: 'blob',
    signal,
    onDownloadProgress: progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (!setPercentage) {
        // eslint-disable-next-line no-console
        console.log('Downloaded: ', percentCompleted);
        return;
      }
      setPercentage(percentCompleted);
    },
  };

  return axios.get(`${config.API_URL}/file/download/${id}`, axiosConfig);
};

api.downloadFromS3 = (S3FileId, headers) => {
  return fetch(`${config.API_URL}/file/download-as-streamable/id/${S3FileId}`, {
    method: 'GET',
    headers,
  });
};

api.getS3Url = (S3FileId, headers) => {
  return fetch(`${config.API_URL}/file/get-s3-url/${S3FileId}`, {
    method: 'GET',
    headers,
  });
};

api.delete = async ids => {
  // return fileUploadService('/file/upload', formData);
  return httpService({
    url: '/file/delete-by-ids',
    options: {
      method: 'DELETE',
      data: {
        ids,
      },
    },
  });
};

export default api;
