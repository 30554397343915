/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { useMessagesApi } from 'hooks/useMessagesApi';
import { useLocationList } from 'hooks/useLocationList';
import { useUsersList } from 'hooks/useUsersList';
import { usePatientAndProceduresList } from 'hooks/usePatientAndProceduresList';
import { sortRowsByIdDesc } from 'utils/sortRowsByIdDesc';

export const useSendMessage = () => {
  const userSelectorRef = useRef(null);
  const { goBack } = useHistory();
  const {
    globalState,
    dispatchGlobalState,
    isUpdating,
    error,
    handleCreateData,
    handleSetAsRead,
  } = useMessagesApi();
  const { locationListOptions } = useLocationList();
  const { getUserListOptionsByRoleAndLocation } = useUsersList();
  const { procedureListOptions } = usePatientAndProceduresList();

  const [role, setRole] = useState(globalState.newMessageRole);
  const [location, setLocation] = useState(globalState.newMessageLocationId);
  const [procedureId, setProcedureId] = useState(
    globalState.newMessageProcedureId
  );
  const [message, setMessage] = useState('');

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    values,
    getValues,
    setValue,
    resetField,
    reset,
  } = useForm();

  const resetForm = () => {
    reset({
      role: globalState.newMessageRole ?? '',
      location: globalState.newMessageLocationId ?? '',
      receiverId: globalState.newMessageReceiverId ?? '',
      procedureId: globalState.newMessageProcedureId ?? '',
      text: '',
    });
    setRole(globalState.newMessageRole ?? '');
    setLocation(globalState.newMessageLocationId ?? '');
    setProcedureId(globalState.newMessageProcedureId ?? '');
  };

  const getUserListOptions = () => {
    const userList = (
      Array.isArray(globalState?.users?.value) ? globalState.users.value : []
    ).filter(user => user.id !== globalState?.user?.value?.userId);

    return getUserListOptionsByRoleAndLocation(
      location,
      locationListOptions,
      role,
      userList
    );
  };

  const handleChangeRole = e => {
    const value = e?.target?.value;
    setRole(value);
    setValue('role', value);
  };

  const handleChangeLocation = e => {
    const value = e?.target?.value;
    setLocation(value);
    setValue('location', value);
  };

  const handleSelectReceiver = e => {
    const value = e?.target?.id;
    setValue('receiverId', value);
  };

  const onSubmit = async ({ procedureId, receiverId, text }) => {
    const requestData = {
      procedureId,
      receiverId,
      text,
      senderId: globalState?.user?.value?.userId,
    };

    const result = await handleCreateData(requestData);

    if (result) {
      reset();
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: false,
      });
      goBack();
    }
  };

  const resetUserFilters = () => {
    setValue('role', '');
    setRole('');
    setValue('location');
    setLocation('');
  };

  const handleChangeProcedure = e => {
    const value = e?.target?.value;
    setValue('procedureId', value);
    setProcedureId(value);
  };

  const resetProcedure = () => {
    setValue('procedureId', '');
    setProcedureId('');
  };

  const userListOptions = getUserListOptions();

  useEffect(() => {
    resetForm();
    return () => {
      dispatchGlobalState({ type: 'SET_newMessageRole', payload: '' });
      dispatchGlobalState({ type: 'SET_newMessageReceiverId', payload: '' });
      dispatchGlobalState({ type: 'SET_newMessageLocationId', payload: '' });
      dispatchGlobalState({ type: 'SET_newMessageProcedureId', payload: '' });
    };
  }, [globalState.newMessageProcedureId, globalState.newMessageReceiverId]);

  useEffect(() => {
    document.title = 'Send New Message';

    return () => {
      dispatchGlobalState({
        type: 'SET_newMessageRecieverId',
        payload: '',
      });
      dispatchGlobalState({
        type: 'SET_newMessageProcedureId',
        payload: '',
      });
      dispatchGlobalState({
        type: 'SET_newMessageLocationId',
        payload: '',
      });
      dispatchGlobalState({
        type: 'SET_newMessageRole',
        payload: '',
      });
    };
  }, []);

  useEffect(() => {
    if (isDirty !== globalState.isDataChanged) {
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: isDirty,
      });
    }
  }, [isDirty]);

  return {
    messageList: sortRowsByIdDesc(globalState.messages?.value),
    sentMessageList: sortRowsByIdDesc(globalState.sentMessages?.value),
    isUpdating,
    error,
    handleSubmit,
    onSubmit,
    control,
    register,
    errors,
    isDirty,
    values,
    userListOptions,
    role,
    handleChangeRole,
    location,
    handleChangeLocation,
    locationListOptions,
    message,
    setMessage,
    getValues,
    users: globalState?.users?.value ?? [],
    procedureId,
    setProcedureId,
    handleSelectReceiver,
    handleSetAsRead,
    goBack,
    resetForm,
    userSelectorRef,
    resetUserFilters,
    resetProcedure,
    procedureListOptions,
    handleChangeProcedure,
  };
};
