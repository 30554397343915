import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Button } from '@mui/material';
import * as UI from 'UI';
import {
  fullNameValidation,
  addressValidation,
  phoneNumberValidation,
  required,
} from 'models/validations';
import { useConfirmAction } from 'hooks/useConfirmAction';
import { LocationTypeEnum } from 'models/constants';
import * as SC from './LocationEditor.sc';
import { useLocationEditor } from './useLocationEditor';

export const LocationEditor = () => {
  const { confirmAction } = useConfirmAction();
  const { goBack } = useHistory();
  const {
    handleSubmit,
    onSubmit,
    control,
    isDirty,
    trigger,
    isUpdating,
    error,
    handleCancelChanges,
  } = useLocationEditor();

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 32px)" />
      <SC.Header>
        <h3>Surgery Location Details</h3>
      </SC.Header>
      {isUpdating && <UI.GlobalSpinner />}

      <SC.Wrapper>
        <SC.Form>
          {!error && (
            <SC.Paper elevation={5}>
              <Stack direction="column" width="100%" spacing={3}>
                <UI.FormTextField
                  label="ID"
                  name="id"
                  control={control}
                  disabled
                />
                <UI.FormTextField
                  label="Name"
                  name="name"
                  control={control}
                  rules={fullNameValidation}
                  disabled={isUpdating}
                />
                <UI.FormTextField
                  label="Address"
                  name="address"
                  control={control}
                  rules={addressValidation}
                  disabled={isUpdating}
                />
                <UI.FormPhoneNumber
                  label="Phone Number"
                  name="phoneNumber"
                  control={control}
                  rules={phoneNumberValidation}
                  disabled={isUpdating}
                />
                <UI.FormSelectField
                  label="Location description"
                  name="location"
                  control={control}
                  options={LocationTypeEnum}
                  rules={{ required }}
                  disabled={isUpdating}
                />
                <UI.FormSelectField
                  label="State"
                  name="active"
                  control={control}
                  options={{
                    active: 'Active',
                    disabled: 'Disabled',
                  }}
                  rules={{ required }}
                  disabled={isUpdating}
                />
              </Stack>
            </SC.Paper>
          )}
          <SC.EmptySpace />
          <SC.Buttons>
            <Button
              onClick={isDirty ? handleCancelChanges : goBack}
              type="button"
              variant="contained"
              color="primary"
              disabled={isUpdating}
            >
              {isDirty ? 'Cancel changes' : 'Back to list'}
            </Button>
            &nbsp;
            <Button
              // disabled={!isDirty}
              type="button"
              variant="contained"
              color="secondary"
              disabled={!isDirty || isUpdating}
              onClick={async () => {
                const isValid = await trigger();

                if (isValid) {
                  confirmAction({
                    text: 'Do you confirm saving?',
                    callbackFn: handleSubmit(onSubmit),
                  });
                }
              }}
            >
              Save Location
            </Button>
          </SC.Buttons>
        </SC.Form>
      </SC.Wrapper>
    </SC.Container>
  );
};
