import React from 'react';
import { Alert } from '@mui/material';
import * as SC from './ErrorBox.sc';

export const ErrorBox = ({ text, width = '100%' }) => {
  return text ? (
    <Alert variant="filled" severity="error" style={{ width, margin: '0' }}>
      <SC.Container>{text}</SC.Container>
    </Alert>
  ) : null;
};
