/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { ProcedureStatusEnum } from 'models/constants';
import { useMessagesApi } from 'hooks/useMessagesApi';
import { useUsersList } from 'hooks/useUsersList';
import * as FP from 'utils/fp-js';

export const useSendNotification = () => {
  const { globalState, isUpdating, error, handleCreateData } = useMessagesApi();
  const { users } = globalState;
  const { getRadiologyListOptionsByLocationId, engineerListOptions } =
    useUsersList(users);

  // await handleCreateData(preparedData);

  const sendNotification = ({ formState, watch }) => {
    const { dirtyFields } = formState;
    const state = watch();

    const radiologistList = Object.keys(
      getRadiologyListOptionsByLocationId(state?.radiologyLocationId)
    );
    const engineerList = Object.keys(engineerListOptions);

    const preparedData = {
      procedureId: state?.id,
      senderId: globalState.user?.value?.userId,
      showSuccess: false,
    };

    FP.match(true)
      .on(
        dirtyFields?.status &&
          state?.status === ProcedureStatusEnum.SCAN &&
          !!state?.radiologyLocationId,
        () => {
          preparedData.receivers = radiologistList;
          preparedData.text = 'Scan ordered';
          handleCreateData(preparedData);
        }
      )
      .on(
        dirtyFields?.status && state?.status === ProcedureStatusEnum.SCAN_READY,
        () => {
          preparedData.receivers = engineerList;
          preparedData.text = 'Scan Ready';
          handleCreateData(preparedData);
        }
      )
      .on(
        dirtyFields?.status &&
          state?.status === ProcedureStatusEnum.MODEL_IN_WORK,
        () => {
          preparedData.receiverId = state?.surgeonId;
          preparedData.text = 'Model in work';
          handleCreateData(preparedData);
        }
      )
      .on(
        dirtyFields?.status &&
          state?.status === ProcedureStatusEnum.MODEL_READY,
        () => {
          preparedData.receiverId = state?.surgeonId;
          preparedData.text = 'Model ready';
          handleCreateData(preparedData);
        }
      )
      .otherwise(() => null);
  };

  return {
    sendNotification,
  };
};
