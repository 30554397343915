/* eslint-disable max-lines */
/* eslint-disable no-shadow */
/* eslint-disable max-lines-per-function */
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useApi } from 'hooks/useApi';
// import patientApi from '/api/patient';
import procedureApi from 'api/procedure';
import deletedProcedureApi from 'api/deletedProcedure';

import { useSearchInGrid } from 'hooks/useSearchInGrid';
import { useLocationList } from 'hooks/useLocationList';
import { useUsersList } from 'hooks/useUsersList';
import * as FP from 'utils/fp-js';
import * as UI from 'UI';
import {
  RoleEnum,
  GenderEnum,
  GenderLabelEnum,
  SideLabelEnum,
  ProcedureStatusEnum,
  RadiologistProcedureStatusEnum,
  EngineerProcedureStatusEnum,
  ProcedureStatusLabelEnum,
  PROCEDURES_REFRESH_INTERVAL_MS,
} from 'models/constants';
import { getAgeFromDateOfBirth } from 'utils/getAgeFromDateOfBirth';
import { convertToLocaleDate } from 'utils/dateTimeUtils';
import { usePeriodRange } from 'hooks/usePeriodRange';
import { useAssignedSurgeons } from 'hooks/useAssignedSurgeons';
import { getSurgeonColumns } from './getSurgeonColumns';
import { getRadiologistColumns } from './getRadiologistColumns';
import { getEngineerColumns } from './getEngineerColumns';
import { getAdminColumns } from './getAdminColumns';

const storage = window.localStorage;

export const useProcedures = ({ onlyMyProcedures }) => {
  const { locationListOptions, handleReloadLocationList } =
    useLocationList(false);
  const { assignedSurgeons, reloadAssignedSurgeons } = useAssignedSurgeons();

  const { push } = useHistory();

  const [procedures, setProcedures] = useState([]);

  const {
    data,
    setData,
    row,
    setRow,
    handleReloadData,
    handleGetDataById,
    handleSaveData,
    handleCreateData,
    handleDeleteData,
    isUpdating,
    setIsUpdating,
    error,
    showError,
    globalState: { user, users },
  } = useApi(procedureApi, false);

  const {
    customData,
    handleCustomApiRequest,
    isUpdating: isDeletedUpdating,
  } = useApi(deletedProcedureApi, false);

  const { getUserNameById } = useUsersList(users);

  const { period, periodStartDate, setPeriodAndSave } = usePeriodRange({
    useCase: 'Procedures',
  });

  const [statusSet, setStatusSet] = useState(
    storage.getItem(`${user.value.userId}-statusSet`) ?? []
  );

  const setStatusSetAndSave = value => {
    setStatusSet(value);
    storage.setItem(`${user.value.userId}-statusSet`, value);
  };

  const { search, handleSearchChange, handleSearchCancel, filterRow } =
    useSearchInGrid(
      [
        'id',
        'created',
        'procedure',
        'side',
        'patientName',
        'gender',
        'age',
        'dateOfSurgery',
        'surgicalLocation',
        'surgeon',
        'medicalInsurance',
      ],
      'procedures'
    );

  const columns = FP.match(user?.value?.role)
    .on(RoleEnum.SURGEON, getSurgeonColumns())
    .on(RoleEnum.RADIOLOGIST, getRadiologistColumns())
    .on(RoleEnum.IMAGE_ENGINEER, getEngineerColumns())
    .otherwise([
      ...getAdminColumns(),
      {
        field: 'action',
        headerName: '☰',
        headerAlign: 'center',
        width: 30,
        sortable: false,
        renderCell: currentProps =>
          UI.RowActionsPanel({
            ...currentProps,
            editorUrl: '/procedure/',
            handleDeleteAction: handleDeleteData,
            callbackFn: handleReloadData,
          }),
        disableClickEventBubbling: true,
      },
    ]);

  const [sortModel, setSortModel] = useState([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  const handleReloadDeletedData = () => {
    handleCustomApiRequest({
      requestName: 'getAllAfterUnixDate',
      requestData: {
        after: periodStartDate,
      },
    });
  };

  const mapRow = currentRow => {
    return {
      id: currentRow?.id,
      creation: currentRow?.creation ?? '',
      created: convertToLocaleDate(currentRow?.creation),
      status: currentRow?.deleted ? 'Deleted' : currentRow?.status ?? '',
      procedure: currentRow?.name ?? '',
      sideSurgeon: SideLabelEnum[currentRow?.sideSurgeon] ?? '',
      patientName: `${currentRow?.patient?.nameFirst ?? ''} ${
        currentRow?.patient?.nameSecond ?? ''
      }`,
      gender: GenderLabelEnum[GenderEnum[currentRow?.patient?.gender]] ?? '',
      age: getAgeFromDateOfBirth(currentRow?.patient?.dateOfBirth) ?? '',
      dateOfSurgery: currentRow?.dateOfSurgery
        ? convertToLocaleDate(currentRow?.dateOfSurgery)
        : '',
      surgicalLocation:
        locationListOptions[currentRow?.surgeryLocationId]?.name ?? '',
      surgeon: getUserNameById(currentRow?.surgeonId) ?? '',
      radiologyLocation:
        locationListOptions[currentRow?.radiologyLocationId]?.name ?? '',
      radiologist: getUserNameById(currentRow?.radiologiostId) ?? '',
      medicalInsurance: currentRow?.patient?.medicalInsuranceId,
      deleted: currentRow?.deleted,
    };
  };

  const statusList = useMemo(
    () =>
      FP.match(user?.value?.role)
        .on(RoleEnum.RADIOLOGIST, RadiologistProcedureStatusEnum)
        .on(RoleEnum.IMAGE_ENGINEER, EngineerProcedureStatusEnum)
        .otherwise(ProcedureStatusEnum),
    [user]
  );

  const fliterAllowedStatuses = useCallback(
    row => statusList[row?.status] || row?.status === 'Deleted',
    [user]
  );

  /* TODO - move to the separate hook with all use cases */
  const filterOwnProcedures = row =>
    row?.creatorId === user?.value?.userId ||
    row?.surgeonId === user?.value?.userId ||
    assignedSurgeons.includes(row?.surgeonId) ||
    (row?.radiologyLocationId === user?.value?.locationId &&
      user?.value?.role === RoleEnum.RADIOLOGIST) ||
    user?.value?.role === RoleEnum.IMAGE_ENGINEER ||
    user?.value?.role === RoleEnum.MASTER_ADMIN ||
    user?.value?.role === RoleEnum.ADMIN;

  const isOnlyMyProcedureSwitchsVisible =
    user?.value?.role === RoleEnum.RADIOLOGIST ||
    user?.value?.role === RoleEnum.IMAGE_ENGINEER;

  const myOnlyProceduresFilter = procedure => {
    if (!onlyMyProcedures) return true;

    const { engineerId, radiologistId } = procedure;
    switch (user?.value?.role) {
      case RoleEnum.IMAGE_ENGINEER:
        return engineerId === user?.value?.userId || !engineerId;
      case RoleEnum.RADIOLOGIST:
        return radiologistId === user?.value?.userId || !radiologistId;
      default:
        return false;
    }
  };
  /* =========================================================== */

  const showAllStatuses = useCallback(() => {
    const availableStatuses = Object.keys(statusList).map(
      status => ProcedureStatusLabelEnum[status]
    );

    if (statusSet.length === 0) {
      // set all statuses to ON
      setStatusSetAndSave(availableStatuses);
      return;
    }

    setStatusSetAndSave([]);
  }, [statusSet, statusList]);

  const handleChangeStatus = useCallback(
    status => {
      if (statusSet.includes(status)) {
        setStatusSetAndSave(statusSet.filter(value => value !== status));
        return;
      }
      setStatusSetAndSave([...statusSet, status]);
    },
    [statusSet]
  );

  const statusFilter = row =>
    statusSet.length === 0 ||
    statusSet.includes(ProcedureStatusLabelEnum[row.status]);

  const openProcedure = ({ row }) => push(`/procedure/${row?.id}`);

  const isUserAllowedToWatchDeleted =
    user?.value?.role !== RoleEnum.RADIOLOGIST &&
    user?.value?.role !== RoleEnum.IMAGE_ENGINEER;

  const isDeletedRangeSelectorVisible =
    !statusSet?.length && isUserAllowedToWatchDeleted;

  const reloadAllData = async () => {
    reloadAssignedSurgeons();
    handleReloadLocationList();
    await handleReloadData();
    await handleReloadDeletedData();
  };

  useEffect(() => {
    document.title = 'ArthroSight - Procedures';
    reloadAllData();
  }, []);

  useEffect(() => {
    reloadAllData();
  }, [period]);

  useEffect(() => {
    const refresh = setInterval(async () => {
      await reloadAllData();
    }, PROCEDURES_REFRESH_INTERVAL_MS);
    return () => clearInterval(refresh);
  }, []);

  useEffect(() => {
    if (!data?.value || !customData?.value) return;

    const filteredProcedures = data.value.filter(myOnlyProceduresFilter);

    const allProcedures = (
      isUserAllowedToWatchDeleted
        ? [...customData.value, ...filteredProcedures]
        : filteredProcedures
    )
      .filter(filterOwnProcedures)
      .map(mapRow)
      .filter(fliterAllowedStatuses);

    setProcedures(allProcedures);
  }, [data, customData]);

  return {
    data: procedures.filter(statusFilter).filter(filterRow),
    columns,
    setData,
    handleSaveData,
    handleCreateData,
    handleDeleteData,
    handleReloadData,
    handleGetDataById,
    isUpdating: isUpdating || isDeletedUpdating,
    setIsUpdating,
    error,
    showError,
    search,
    handleSearchChange,
    handleSearchCancel,
    sortModel,
    setSortModel,
    row,
    setRow,
    statusList,
    statusSet,
    handleChangeStatus,
    showAllStatuses,
    user,
    openProcedure,
    isOnlyMyProcedureSwitchsVisible,
    period,
    setPeriod: setPeriodAndSave,
    isDeletedRangeSelectorVisible,
  };
};
