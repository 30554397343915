/* eslint-disable no-unused-vars */
import { useHistory } from 'react-router-dom';
import { useConfirmAction } from 'hooks/useConfirmAction';

export const useConfirmExit = () => {
  const { goBack } = useHistory();
  const { confirmAction, globalState, dispatchGlobalState } =
    useConfirmAction();

  /**
   * Opens confirmation window to discard unsaved data on the change url try.
   * @param {Function} reset - callback for the refresh API data
   */
  const confirmExit = (reset = () => null) => {
    confirmAction({
      text: 'You have unsaved changes. Discard changed data?',
      callbackFn: () => {
        reset();
        dispatchGlobalState({ type: 'SET_isDataChanged', payload: false });
        dispatchGlobalState({ type: 'HIDE_CONFIRM' });
        goBack();
      },
    });
  };
  return {
    confirmExit,
    globalState,
    dispatchGlobalState,
  };
};
