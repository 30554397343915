import React from 'react';
import { Typography, Box } from '@mui/material';
import * as SC from './LinearProgressWithLabel.sc';

export const LinearProgressWithLabel = props => {
  // eslint-disable-next-line no-unused-vars
  const { value, loadingError, isLoadingFinished } = props;

  const progressBarClassList = [];
  if (loadingError) {
    progressBarClassList.push('error');
  }
  if (isLoadingFinished) {
    progressBarClassList.push('success');
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <SC.LinearProgress
          variant="determinate"
          {...props}
          className={progressBarClassList.join(' ')}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};
