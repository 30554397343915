/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Stack, Button } from '@mui/material';
import { useConfirmExit } from 'hooks/useConfirmExit';
import { useConfirmAction } from 'hooks/useConfirmAction';
import * as CSC from 'SC/App.sc';
import * as FP from 'utils/fp-js';
import * as UI from 'UI';
import { RoleLabelEnum, UserStatusLabelEnum } from 'models/constants';
import { withGlobalStateUtils } from 'utils/withGlobalStateUtils';
import {
  roleValidation,
  fullNameValidation,
  locationListValidation,
  phoneNumberValidation,
  emailValidation,
  statusValidation,
} from 'models/validations';
import * as SC from './UserEditor.sc';
import { useUserEditor } from './useUserEditor';

export const UserEditor = withGlobalStateUtils(props => {
  const { confirmAction } = useConfirmAction();
  const { goBack } = useHistory();
  const [selectedRole, setSelectedRole] = useState('');
  const { error } = props;
  const {
    handleSubmit,
    handleReset,
    onSubmit,
    users,
    row,
    isDirty,
    trigger,
    isUpdating,
    isAdmin,
    isEngineer,
    isRadiologist,
    isSurgeon,
    control,
    locationListOptions,
    surgeryLocationListOptions,
    radiologyLocationListOptions,
    handleRoleChanged,
    watch,
    setValue,
  } = useUserEditor(props);

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 40px)" />
      <SC.Header>
        <h3>User Details</h3>
      </SC.Header>
      {(!row?.value?.id || isUpdating) && <UI.GlobalSpinner />}
      {users && row?.value?.id && (
        <SC.Wrapper>
          <SC.Form>
            <SC.Paper elevation={5}>
              <SC.FieldSet>
                <Stack direction="column" width="100%" spacing={3}>
                  <UI.FormTextField
                    label="ID"
                    name="id"
                    control={control}
                    disabled
                  />
                  <UI.FormSelectField
                    label="Role"
                    name="role"
                    control={control}
                    rules={roleValidation}
                    options={RoleLabelEnum}
                    disabled
                    onChange={handleRoleChanged}
                  />
                  <UI.FormTextField
                    label="First name"
                    name="nameFirst"
                    control={control}
                    rules={fullNameValidation}
                    disabled={isUpdating}
                  />
                  <UI.FormTextField
                    label="Last name"
                    name="nameSecond"
                    control={control}
                    rules={fullNameValidation}
                    disabled={isUpdating}
                  />
                  {!isAdmin() && (
                    <>
                      <UI.FormPhoneNumber
                        label="Phone Number"
                        name="phoneNumber"
                        control={control}
                        rules={phoneNumberValidation}
                        disabled={isUpdating}
                      />
                      {!isEngineer() && (
                        <UI.FormSelectField
                          label="Location"
                          name="locationId"
                          control={control}
                          options={FP.match(true)
                            .on(isRadiologist(), radiologyLocationListOptions)
                            .on(isSurgeon(), surgeryLocationListOptions)
                            .otherwise(locationListOptions)}
                          rules={locationListValidation}
                        />
                      )}
                    </>
                  )}
                  <UI.FormTextField
                    label="Email"
                    name="email"
                    control={control}
                    rules={emailValidation}
                    disabled={isUpdating}
                  />
                  <UI.FormSelectField
                    label="Status"
                    name="status"
                    control={control}
                    rules={statusValidation}
                    options={UserStatusLabelEnum}
                    disabled={isUpdating}
                  />
                </Stack>
              </SC.FieldSet>
            </SC.Paper>
            <SC.Buttons>
              {!isDirty && (
                <CSC.BackIconHolder onClick={goBack}>
                  <Button type="button" variant="contained" color="primary">
                    Back to list
                  </Button>
                </CSC.BackIconHolder>
              )}
              {isDirty && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleReset}
                >
                  Cancel changes
                </Button>
              )}
              &nbsp;
              <Button
                disabled={!isDirty || isUpdating}
                type="button"
                variant="contained"
                color="secondary"
                onClick={async () => {
                  const isValid = await trigger();

                  if (isValid) {
                    confirmAction({
                      text: 'Do you confirm saving?',
                      callbackFn: handleSubmit(onSubmit),
                    });
                  }
                }}
              >
                Save Changes
              </Button>
            </SC.Buttons>
          </SC.Form>
        </SC.Wrapper>
      )}
    </SC.Container>
  );
});
