import { useEffect, useState } from 'react';
import { useApi } from './useApi';
import patientApi from '../api/patient';
import procedureApi from '../api/procedure';
import { ProcedureStatusEnum } from '../models/constants';

export const usePatientProcedureList = patientId => {
  const [procedureList, setProcedureList] = useState([]);
  const { row, setRow, handleGetDataById } = useApi(patientApi, false);
  const { handleCreateData } = useApi(procedureApi, false);

  const isNewProcedureAllowed = list => {
    if (!Array.isArray(list)) {
      return true;
    }
    if (list.length === 0) {
      return true;
    }

    const finishedProcedures = list.filter(
      ({ status }) =>
        status === ProcedureStatusEnum.CANCELED ||
        status === ProcedureStatusEnum.DONE
    );

    return list?.length === finishedProcedures?.length;
  };

  const reloadProcedureList = () => {
    setRow({});
  };

  useEffect(() => {
    handleGetDataById(patientId);
  }, []);

  useEffect(() => {
    if (row?.value) {
      setProcedureList(row?.value?.procedures);

      procedureList.sort((prev, next) =>
        Number(prev.id) > Number(next.id) ? -1 : 1
      );
    }
  }, [row]);

  return {
    procedureList,
    createProcedure: handleCreateData,
    reloadProcedureList,
    isNewProcedureAllowed,
  };
};
