/* DO NOT FORGET to add export of this file to /src/store/reducers/index.js */
import * as FP from '../../utils/fp-js';
import { localStorageNameEnum } from '../../models/constants';

export const user = (state, action) =>
  FP.match(action.type)
    .on('SET_user', () => {
      if (
        action?.payload?.value?.access?.token &&
        action?.payload?.value?.access?.expiresAt &&
        action?.payload?.value?.refresh?.token &&
        action?.payload?.value?.refresh?.expiresAt
      ) {
        localStorage.setItem(
          localStorageNameEnum.AUTH_TOKEN,
          action.payload.value.access.token
        );
        localStorage.setItem(
          localStorageNameEnum.AUTH_TOKEN_EXPIRES,
          action.payload.value.access.expiresAt
        );
        localStorage.setItem(
          localStorageNameEnum.REFRESH_AUTH_TOKEN,
          action.payload.value.refresh.token
        );
        localStorage.setItem(
          localStorageNameEnum.REFRESH_TOKEN_EXPIRES,
          action.payload.value.refresh.expiresAt
        );
      }
      return action.payload;
    })
    .on('LOGOUT', () => {
      localStorage.removeItem(localStorageNameEnum.REFRESH_AUTH_TOKEN);
      localStorage.removeItem(localStorageNameEnum.REFRESH_TOKEN_EXPIRES);
      localStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
      localStorage.removeItem(localStorageNameEnum.AUTH_TOKEN_EXPIRES);

      if (state?.value?.role) {
        clearTimeout(window?.refreshTokensTimer);
        return { value: {} };
      }
      return state;
    })
    .otherwise(state);
