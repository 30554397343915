/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useRef, useEffect, useState, useMemo } from 'react';
import { useMessagesApi } from 'hooks/useMessagesApi';

export const useChat = ({ sender, receiver, messages }) => {
  const {
    globalState,
    dispatchGlobalState,
    handleCreateData,
    handleSetAsRead,
    isUpdating,
  } = useMessagesApi();
  const [suggestedMessageIds, setSuggestedMessageIds] = useState([]);
  const scrollAreaRef = useRef();
  const messageTextRef = useRef();

  const scrollToBottom = ref => {
    ref?.current?.scrollTo({
      top: ref?.current?.scrollHeight,
    });
  };

  const handleChangeText = e => {
    const value = e?.target?.value;

    if (!!value !== globalState?.isDataChanged) {
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: !!value,
      });
    }
  };

  const getChatMessages = ({ sender, receiver, messages }) => {
    return messages.filter(
      row =>
        !!row.text &&
        ((row.senderId === sender?.id && row.receiverId === receiver?.id) ||
          (row.receiverId === sender?.id && row.senderId === receiver?.id))
    );
  };

  const sendNewMessage = async () => {
    const textarea =
      messageTextRef?.current?.getElementsByTagName('textarea')[0];

    if (!textarea.value) {
      return;
    }

    const requestData = {
      receiverId: sender.id,
      text: textarea.value,
      senderId: receiver.id,
    };

    const result = await handleCreateData(requestData);
    if (result) {
      textarea.value = '';
      dispatchGlobalState({
        type: 'SET_isDataChanged',
        payload: false,
      });
    }
  };

  const newMessageIds = useMemo(
    () =>
      messages
        .filter(
          row =>
            !row.isRead &&
            row.receiverId === receiver?.id &&
            row.senderId === sender?.id &&
            row.text
        )
        .map(row => row.id),
    [sender, messages]
  );

  const resetSuggested = () => setSuggestedMessageIds([]);

  useEffect(() => {
    setSuggestedMessageIds([...newMessageIds]);
  }, [sender]);

  useEffect(() => {
    scrollToBottom(scrollAreaRef);
  }, [sender, receiver, messages]);

  useEffect(() => {
    const textarea =
      messageTextRef?.current?.getElementsByTagName('textarea')[0];
    textarea?.focus();
  });

  useEffect(() => {
    if (newMessageIds?.length)
      newMessageIds.forEach(id => {
        if (!suggestedMessageIds.includes(id))
          setSuggestedMessageIds([...suggestedMessageIds, id]);
        handleSetAsRead(id);
      });
  });

  return {
    scrollToBottom,
    chatMessages: getChatMessages({ sender, receiver, messages }),
    scrollAreaRef,
    messageTextRef,
    sendNewMessage,
    isUpdating,
    suggestedMessageIds,
    resetSuggested,
    handleChangeText,
  };
};
