import React, { useReducer, useMemo } from 'react';
import { defaultGlobalState } from './defaultGlobalState';
import { GlobalStateContext } from './GlobalStateContext';
import { globalStateReducer } from './globalStateReducer';

export const GlobalStateContextProvider = ({ children }) => {
  const [globalState, dispatchGlobalState] = useReducer(
    globalStateReducer,
    defaultGlobalState
  );

  const contextValue = useMemo(
    () => [globalState, dispatchGlobalState],
    [globalState]
  );

  return (
    <GlobalStateContext.Provider value={contextValue}>
      {children}
    </GlobalStateContext.Provider>
  );
};
