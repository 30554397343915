import { grey } from '@mui/material/colors';

export const AUTOLOGOUT_DELAY_MIN = 15;
export const AUTOLOGOUT_FOR_MODEL_VIEW_DELAY_MIN = 120;

export const REFRESH_TIME_BEFORE_TOKEN_EXPIRED_IN_SEC = 20;
export const MESSAGES_REFRESH_INTERVAL_MS = 25000;
export const PROCEDURES_REFRESH_INTERVAL_MS = 45000;
export const PATIENTS_REFRESH_INTERVAL_MS = 60000;
export const LOCATIONS_REFRESH_INTERVAL_MS = 55000;
export const USERS_REFRESH_INTERVAL_MS = 55000;
export const ADMIN_LOG_REFRESH_INTERVAL_MS = 30000;

export const DEBOUNCE_TIME_FOR_ALERT_MS = 2000;

export const USERNAME_MAX_LENGTH = 250;
export const ADDRESS_MAX_LENGTH = 250;
export const PHONE_MAX_LENGTH = 100;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 12;

export const MIN_INCLINATION_IN_DEGREE = 35;
export const MAX_INCLINATION_IN_DEGREE = 50;

export const MIN_ANTEVERSION_IN_DEGREE = 5;
export const MAX_ANTEVERSION_IN_DEGREE = 45;

export const MIN_DIFFERENCE = -20;
export const MAX_DIFFERENCE = 20;

export const RoleEnum = {
  MASTER_ADMIN: 'MASTER_ADMIN',
  ADMIN: 'ADMIN',
  SURGEON: 'SURGEON',
  SURGICAL_ASSISTANT: 'SURGICAL_ASSISTANT',
  RADIOLOGIST: 'RADIOLOGIST',
  IMAGE_ENGINEER: 'IMAGE_ENGINEER',
  PASSWORD_CHANGE: 'CHANGE_PASSWORD',
};

export const RoleLabelEnum = {
  MASTER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  SURGEON: 'Surgeon',
  SURGICAL_ASSISTANT: 'Surgeon Assistant',
  RADIOLOGIST: 'Radiologist',
  IMAGE_ENGINEER: 'Imaging Engineer',
};

export const AdminRoleLabelEnum = {
  SURGEON: 'Surgeon',
  SURGICAL_ASSISTANT: 'Surgeon Assistant',
  RADIOLOGIST: 'Radiologist',
  IMAGE_ENGINEER: 'Engineer',
};

export const UserStatusEnum = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
  PENDING: 'PENDING',
};

export const UserStatusLabelEnum = {
  ACTIVE: 'Active',
  BLOCKED: 'Inactive',
  PENDING: 'Pending',
};

export const GenderEnum = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const GenderLabelEnum = {
  MALE: 'Male',
  FEMALE: 'Female',
};

export const SideEnum = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const SideLabelEnum = {
  LEFT: 'Left',
  RIGHT: 'Right',
};

export const ProcedureEnum = {
  'Total Hip Arthroplasty': 'Total Hip Arthroplasty',
};

export const RadiologistProcedureStatusEnum = {
  SCAN: 'SCAN',
  SCAN_READY: 'SCAN_READY',
};

export const EngineerProcedureStatusEnum = {
  SCAN_READY: 'SCAN_READY',
  MODEL_IN_WORK: 'MODEL_IN_WORK',
  MODEL_READY: 'MODEL_READY',
};

export const ProcedureStatusEnum = {
  NEW: 'NEW',
  ...RadiologistProcedureStatusEnum,
  ...EngineerProcedureStatusEnum,
  READY: 'READY',
  SCHEDULED: 'SCHEDULED',
  CANCELED: 'CANCELED',
  DONE: 'DONE',
};

export const ProcedureStatusFlowEnum = {
  NEW: 'NEW',
  ...RadiologistProcedureStatusEnum,
  ...EngineerProcedureStatusEnum,
  READY: 'READY',
  SCHEDULED: 'SCHEDULED',
  DONE: 'DONE',
};

export const ProcedureStatusLabelEnum = {
  NEW: 'New Patient',
  SCAN: 'Scan Request',
  SCAN_READY: 'Scan Ready',
  MODEL_IN_WORK: 'Model in Progress',
  MODEL_READY: 'Model Complete',
  READY: 'Ready for Scheduling',
  SCHEDULED: 'Surgery Scheduled',
  CANCELED: 'Procedure Canceled',
  DONE: 'Procedure Complete',
  Deleted: 'Deleted',
};

export const ProcedureStatusColorEnum = {
  NEW: 'red',
  SCAN: '#ba68c8',
  RESCAN: '#9575cd',
  SCAN_READY: '#4a148c',
  // MODEL_ORDERED: '#29B6F6',
  MODEL_IN_WORK: '#01579b',
  MODEL_READY: '#002171',
  READY: '#f57c00',
  SCHEDULED: '#00b200',
  CANCELED: '#78909c',
  DONE: '#37474f',
  Deleted: grey[400],
};

export const localStorageNameEnum = {
  REFRESH_AUTH_TOKEN: 'REFRESH_AUTH_TOKEN',
  REFRESH_TOKEN_EXPIRES: 'REFRESH_TOKEN_EXPIRES',
  AUTH_TOKEN: 'AUTH_TOKEN',
  AUTH_TOKEN_EXPIRES: 'TOKEN_EXPIRES',
  LIGHTS_DATA: 'LIGHTS_DATA',
};

export const FileTypeEnum = {
  MODEL: 'MODEL',
  SCAN: 'SCAN',
};

export const fileTypeLabelEnum = {
  MODEL: '3D Model',
  SCAN: 'CT Scan',
};

export const LocationTypeEnum = {
  surgery: 'Surgery',
  radiology: 'Radiology',
  both: 'Surgery/Radiology',
};

export const timePeriodsEnum = {
  LAST_MONTH: 'LAST_MONTH',
  LAST_YEAR: 'LAST_YEAR',
  ALL_TIME: 'ALL_TIME',
};

export const timePeriodsLabelEnum = {
  LAST_MONTH: 'Last Month',
  LAST_YEAR: 'Last Year',
  ALL_TIME: 'All Time',
};

export const timePeriodDurationEnum = {
  // time in secs
  LAST_MONTH: 2678400,
  LAST_YEAR: 31536000,
  ALL_TIME: undefined,
};

export const LogActionType = {
  USER_CREATED: 'USER_CREATED',
  USER_DELETED: 'USER_DELETED',
  USER_UPDATED: 'USER_UPDATED',
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_ASSIGN: 'USER_ASSIGN',
  PATIENT_CREATED: 'PATIENT_CREATED',
  PATIENT_DELETED: 'PATIENT_DELETED',
  PATIENT_UPDATED: 'PATIENT_UPDATED',
  PROCEDURE_CREATED: 'PROCEDURE_CREATED',
  PROCEDURE_DELETED: 'PROCEDURE_DELETED',
  PROCEDURE_UPDATED_NAME: 'PROCEDURE_UPDATED_NAME',
  PROCEDURE_UPDATED_SIDE: 'PROCEDURE_UPDATED_SIDE',
  PROCEDURE_UPDATED_STATUS: 'PROCEDURE_UPDATED_STATUS',
  PROCEDURE_UPDATED_DATE_OF_SURGERY: 'PROCEDURE_UPDATED_DATE_OF_SURGERY',
  PROCEDURE_UPDATED_SURGERY_LOCATION: 'PROCEDURE_UPDATED_SURGERY_LOCATION',
  PROCEDURE_UPDATED_RADIOLOGY_LOCATION: 'PROCEDURE_UPDATED_RADIOLOGY_LOCATION',
  PROCEDURE_UPDATED_SURGEON: 'PROCEDURE_UPDATED_SURGEON',
  PROCEDURE_UPDATED_RADIOLOGIST: 'PROCEDURE_UPDATED_RADIOLOGIST',
  PROCEDURE_UPDATED_ENGINEER: 'PROCEDURE_UPDATED_ENGINEER',
  PROCEDURE_UPDATED_NOTES: 'PROCEDURE_UPDATED_NOTES',
  LOCATION_CREATED: 'LOCATION_CREATED',
  LOCATION_DELETED: 'LOCATION_DELETED',
  MESSAGE_SEND: 'MESSAGE_SEND',
  MESSAGE_READ: 'MESSAGE_READ',
  CT_SCAN_UPLOAD: 'CT_SCAN_UPLOAD',
  CT_SCAN_DOWNLOAD: 'CT_SCAN_DOWNLOAD',
  CT_SCAN_DELETED: 'CT_SCAN_DELETED',
  MODEL_UPLOAD: 'MODEL_UPLOAD',
  MODEL_VIEW: 'MODEL_VIEW',
  MODEL_DELETED: 'MODEL_DELETED',
};

export const LogActionLabelType = {
  USER_CREATED: 'User created',
  USER_DELETED: 'User deleted',
  USER_UPDATED: 'User updated',
  USER_LOGIN: 'User login',
  USER_LOGOUT: 'User logout',
  USER_ASSIGN: 'User assigned',
  PATIENT_CREATED: 'Patient created',
  PATIENT_DELETED: 'Patient deleted',
  PATIENT_UPDATED: 'Patient updated',
  PROCEDURE_CREATED: 'Procedure created',
  PROCEDURE_DELETED: 'Procedure deleted',
  PROCEDURE_UPDATED_NAME: 'Procedure name updated',
  PROCEDURE_UPDATED_SIDE: 'Procedure side updated',
  PROCEDURE_UPDATED_STATUS: 'Procedure status updated',
  PROCEDURE_UPDATED_DATE_OF_SURGERY: 'Procedure date of surgery updated',
  PROCEDURE_UPDATED_SURGERY_LOCATION: 'Procedure surgery location updated',
  PROCEDURE_UPDATED_RADIOLOGY_LOCATION: 'Procedure radiology location updated',
  PROCEDURE_UPDATED_SURGEON: 'Procedure surgeon updated',
  PROCEDURE_UPDATED_RADIOLOGIST: 'Procedure radiologist updated',
  PROCEDURE_UPDATED_ENGINEER: 'Procedure engineer updated',
  PROCEDURE_UPDATED_NOTES: 'Procedure notes updated',
  LOCATION_CREATED: 'Location created',
  LOCATION_DELETED: 'Location deleted',
  MESSAGE_SEND: 'Message sent',
  MESSAGE_READ: 'Message read',
  CT_SCAN_UPLOAD: 'CT Scan uploaded',
  CT_SCAN_DOWNLOAD: 'CT Scan downloaded',
  CT_SCAN_DELETED: 'CT Scan deleted',
  MODEL_UPLOAD: 'Model uploaded',
  MODEL_VIEW: 'Model viewed',
  MODEL_DELETED: 'Model deleted',
};
