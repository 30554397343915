import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const Container = styled(Stack)`
  margin-top: 10px;
  padding: 17px 7px 7px 7px;
  border: thin solid lightgray;
  border-radius: 5px;
  width: calc(100% - 16px);
`;

export const LabelHolder = styled.div`
  position: relative;
  margin-bottom: 0;
  height: 0;
  overflow: visible;
`;

export const Label = styled.div`
  display: inline;
  position: relative;
  padding: 0 2px;
  top: -31px;
  left: 0;

  font-size: 0.9em;
  letter-spacing: -0.03em;
  color: gray;
  background-color: white;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
