/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import * as UI from 'UI';
import { localStorageNameEnum, RoleEnum } from 'models/constants';
import * as FP from 'utils/fp-js';
import Login from 'components/Login/Login';

import PasswordRecovery from 'components/PasswordRecovery/PasswordRecovery';
import MasterAdmin from 'components/MasterAdmin/MasterAdmin';
// import Admin from '../Admin/Admin';
import Surgeon from 'components/Surgeon/Surgeon';
import SurgeonAssistant from 'components/SurgeonAssistant/SurgeonAssistant';
import Radiologist from 'components/Radiologist/Radiologist';
import ImageEngineer from 'components/ImageEngineer/ImageEngineer';
import { PasswordChanger } from 'components/PasswordChanger/PasswordChanger';
import { useUserManagement } from 'components/MasterAdmin/UserManagement/useUserManagement';
import { TokenAuth } from 'components/TokenAuth/TokenAuth';
import { useAuthGate } from './useAuthGate';

const AuthGate = () => {
  const { user } = useAuthGate();
  const {
    handleSaveNewPassword,
    isUpdating,
    showSpinner,
    hideSpinner,
    showError,
    hideError,
  } = useUserManagement({ user, reloadDisabled: true });

  return (
    <BrowserRouter>
      {FP.match(user?.value?.role)
        .on(
          RoleEnum.PASSWORD_CHANGE,
          <PasswordChanger
            userId={user?.value?.userId}
            closeWindow={() => null}
            savePassword={handleSaveNewPassword}
            showSpinner={showSpinner}
            hideSpinner={hideSpinner}
            showError={showError}
            hideError={hideError}
            resetSelected={() => null}
            user={user?.value}
            cancelDisabled
          />
        )
        .on(RoleEnum.MASTER_ADMIN, <MasterAdmin />)
        .on(RoleEnum.ADMIN, <MasterAdmin />)
        .on(RoleEnum.SURGEON, <Surgeon />)
        .on(RoleEnum.SURGICAL_ASSISTANT, <SurgeonAssistant />)
        .on(RoleEnum.RADIOLOGIST, <Radiologist />)
        .on(RoleEnum.IMAGE_ENGINEER, <ImageEngineer />)
        .on(
          () => localStorage.getItem(localStorageNameEnum.AUTH_TOKEN),
          <div className="App-header">
            <UI.Spinner />
          </div>
        )
        .otherwise(
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/forgot_password" component={TokenAuth} />
            <Route path="/password_recovery" component={PasswordRecovery} />
            <Redirect to="/" />
          </Switch>
        )}
    </BrowserRouter>
  );
};

export default AuthGate;
