import { httpService } from './httpService';

export const createApiMethodsSet = () => ({
  create: async data =>
    httpService({
      url: 'create',
      options: {
        method: 'POST',
        data,
      },
    }),

  getAll: async () =>
    httpService({ url: 'get_all', options: { method: 'GET' } }),

  getById: async id =>
    httpService({ url: `get_by_id/${id}`, options: { method: 'GET' } }),

  save: async data =>
    httpService({
      url: 'save',
      options: {
        method: 'POST',
        data,
      },
    }),

  remove: async id =>
    httpService({
      url: 'remove',
      options: {
        method: 'DELETE',
        data: {
          id,
        },
      },
    }),
});
