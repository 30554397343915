/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useApi } from 'hooks/useApi';
import locationApi from 'api/location';
import { useSearchInGrid } from 'hooks/useSearchInGrid';
import * as UI from 'UI';
import { LOCATIONS_REFRESH_INTERVAL_MS } from 'models/constants';
import { ActiveStatus } from './ActiveStatus/ActiveStatus';

export const useLocations = () => {
  const { push } = useHistory();
  const {
    data,
    setData,
    handleReloadData,
    handleSaveData,
    handleCreateData,
    handleDeleteData,
    isUpdating,
    setIsUpdating,
    error,
    showError,
    forceReloadData,
  } = useApi(locationApi);

  const { search, handleSearchChange, handleSearchCancel, filterRow } =
    useSearchInGrid(['id', 'name', 'address', 'phoneNumber', 'location']);

  const columns = useMemo(
    () => [
      {
        field: 'id',
        align: 'center',
        headerAlign: 'center',
        headerName: '#',
        width: 90,
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'address',
        headerName: 'Address',
        sortable: true,
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone number',
        sortable: true,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'location',
        headerName: 'Description',
        sortable: true,
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
      },
      {
        field: 'active',
        headerName: 'State',
        sortable: true,
        type: 'boolean',
        align: 'center',
        headerAlign: 'center',
        renderCell: ActiveStatus,
        flex: 1,
      },
      {
        field: 'action',
        headerName: '☰',
        headerAlign: 'center',
        width: 30,
        sortable: false,
        renderCell: currentProps =>
          UI.RowActionsPanel({
            ...currentProps,
            editorUrl: '/edit_location/',
            handleDeleteAction: handleDeleteData,
          }),
        disableClickEventBubbling: true,
      },
    ],
    []
  );

  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);

  const mapRow = useCallback(
    row => ({
      ...row,
      hasSurgery: row?.hasSurgery ? 'Yes' : 'No',
      hasRadiology: row?.hasRadiology ? 'Yes' : 'No',
      location: `${row.hasSurgery ? 'Surgery' : ''}${
        row.hasSurgery && row.hasRadiology ? ' / ' : ''
      }${row.hasRadiology ? 'Radiology' : ''}`,
    }),
    [data]
  );

  const openLocationEdit = row => push(`/edit_location/${row?.id}`);

  useEffect(() => {
    document.title = 'ArthroSight - Locations';
  }, []);

  useEffect(() => {
    const refresh = setInterval(async () => {
      await handleReloadData();
    }, LOCATIONS_REFRESH_INTERVAL_MS);
    return () => clearInterval(refresh);
  }, []);

  return {
    columns,
    rows: !data.value ? [] : data.value.map(mapRow).filter(filterRow),
    setData,
    handleReloadData,
    handleSaveData,
    handleCreateData,
    handleDeleteData,
    isUpdating,
    setIsUpdating,
    error,
    showError,
    search,
    handleSearchChange,
    handleSearchCancel,
    forceReloadData,
    sortModel,
    setSortModel,
    openLocationEdit,
  };
};
