/* eslint-disable no-unused-vars */

/**
 * Returns obj without props with undefined or null value
 * @param {{ [key]: any|undefined|null }} obj - string representation of Date
 * @returns {{ [key]: any }}
 */
export const purgeNullProps = obj => {
  return Object.entries(obj)
    .filter(([key, value]) => value === '' || value === 0 || !!value)
    .reduce((acc, curr) => {
      const [key, value] = curr;
      return { ...acc, [key]: value };
    }, {});
};
