import React from 'react';
import { FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';
import { getOptionsForStatusSelect } from 'utils/getOptionsForSelect';
import { ProcedureStatusColorEnum } from 'models/constants';
import * as SC from './FormStatusSelector.sc';

export const FormStatusSelector = ({
  label,
  name,
  control,
  rules = {},
  size = 'small',
  options,
  disabled,
  handleChange = () => null,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { ref, value, onChange, ...inputProps },
        fieldState: { error },
      }) => (
        <FormControl fullWidth error={!!error}>
          <SC.TextField
            select
            label={label}
            value={value ?? ''}
            size={size}
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            disabled={disabled}
            onChange={e => {
              handleChange(e);
              onChange(e);
            }}
            {...inputProps}
            {...props}
            status={value}
          >
            {getOptionsForStatusSelect(options, ProcedureStatusColorEnum)}
          </SC.TextField>
        </FormControl>
      )}
    />
  );
};
