import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useConfirmExit } from 'hooks/useConfirmExit';
import * as SC from './BackToPrevPage.sc';

export const BackToPrevPage = ({
  isConfirmNeeded = false,
  reset = () => null,
}) => {
  const { goBack } = useHistory();
  const { confirmExit } = useConfirmExit();

  const handleClick = isConfirmNeeded ? () => confirmExit(reset) : goBack;

  return (
    <SC.Container title="Back to the previous page" onClick={handleClick}>
      <ArrowBackIosNewIcon fontSize="large" />
      Back
    </SC.Container>
  );
};
