import styled from '@emotion/styled';
import PlayArrowIconMUI from '@mui/icons-material/PlayArrow';
import * as SC from 'SC/App.sc';

export * from 'SC/App.sc';

export const Item = styled(SC.FlexRow)`
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dadada;
`;

export const Id = styled.div`
  flex: 0 0 auto;
  text-align: left;
  width: 60px;
  font-size: 14px;
  color: #343a40;
`;

export const Name = styled.div`
  flex: 1 1 auto;
  text-align: left;
  width: 60%;
`;

export const Date = styled.div`
  flex: 1 1 auto;
  text-align: center;
  width: 40%;
  min-width: 80px;
  font-size: 14px;
  color: #343a40;
`;

export const PlayArrowIcon = styled(PlayArrowIconMUI)`
  margin-left: 30px;
  & :hover {
    color: red;
  }
`;

export const ButtonHolder = styled(SC.FlexColumn)`
  flex: 1 0 auto;
  padding: 20px;
  justify-content: space-between;
  width: calc(100% - 40px);
`;
