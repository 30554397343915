/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useGlobalState } from 'store/GlobalStateContext';
import { Panel } from 'UI/Panel/Panel';
import { StlViewer } from 'UI/StlViewer/StlViewer';
import {
  GenderLabelEnum,
  SideLabelEnum,
  localStorageNameEnum,
} from 'models/constants';
import { getAgeFromDateOfBirth } from 'utils/getAgeFromDateOfBirth';
import { Slider, Stack, Box } from '@mui/material';
import * as SC from './ModelViewer.sc';

const DEFAULT_AMBIENT_LIGHT_INTENSITY = 40;
const DEFAULT_SPOT_LIGHT_INTENSITY = 90;
const DEFAULT_SPOT_X_POSITION = 90;
const DEFAULT_SPOT_Y_POSITION = 90;
const DEFAULT_SPOT_Z_POSITION = 90;

const DEFAULT_MODEL_COLOR_NUMBER = 10;

export const ModelViewer = ({
  url,
  extraHeaders,
  handleClose,
  procedure,
  onFinishLoading,
  onErrorLoading,
}) => {
  const [, dispatchGlobalState] = useGlobalState();

  const colorKeys = Object.keys(SC.colors);

  const [color, setColor] = useState(DEFAULT_MODEL_COLOR_NUMBER);
  const [resetCamera, setResetCamera] = useState(false);

  const [ambientLightIntensity, setAmbientLightIntensity] = useState(
    DEFAULT_AMBIENT_LIGHT_INTENSITY
  );
  const handleChangeAmbientLightIntensity = (event, newValue) => {
    setAmbientLightIntensity(newValue);
  };

  const [spotLightIntensity, setSpotLightIntensity] = useState(
    DEFAULT_SPOT_LIGHT_INTENSITY
  );
  const handleChangeSpotLightIntensity = (event, newValue) => {
    setSpotLightIntensity(newValue);
  };

  const [spotX, setSpotX] = useState(DEFAULT_SPOT_X_POSITION);
  const [spotY, setSpotY] = useState(DEFAULT_SPOT_Y_POSITION);
  const [spotZ, setSpotZ] = useState(DEFAULT_SPOT_Z_POSITION);
  const getChangeSpotLightPositionHandler = setterFn => (event, newValue) => {
    setterFn(newValue);
  };

  const persistLights = () => {
    window.localStorage.setItem(
      localStorageNameEnum.LIGHTS_DATA,
      JSON.stringify({
        spotLightIntensity,
        spotX,
        spotY,
        spotZ,
      })
    );
  };

  const resetLights = () => {
    setSpotLightIntensity(DEFAULT_SPOT_LIGHT_INTENSITY);
    setSpotX(DEFAULT_SPOT_X_POSITION);
    setSpotY(DEFAULT_SPOT_Y_POSITION);
    setSpotZ(DEFAULT_SPOT_Z_POSITION);
    persistLights();
  };

  const restoreLights = () => {
    const lightsData = window.localStorage.getItem(
      localStorageNameEnum.LIGHTS_DATA
    );

    if (lightsData) {
      // eslint-disable-next-line no-shadow
      const { spotLightIntensity, spotX, spotY, spotZ } =
        JSON.parse(lightsData);

      setSpotLightIntensity(spotLightIntensity);
      setSpotX(spotX);
      setSpotY(spotY);
      setSpotZ(spotZ);
    }
  };

  const assingColorName = value => {
    setColor(value);
    localStorage.setItem('model-color', value);
  };

  const style = {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  const title = (
    <>
      {procedure?.patient?.nameFirst} {procedure?.patient?.nameSecond}, &nbsp;
      {GenderLabelEnum[procedure?.patient?.gender]}, &nbsp;
      {getAgeFromDateOfBirth(procedure?.patient?.dateOfBirth)} yo, &nbsp;
      {procedure?.name}, &nbsp;
      {SideLabelEnum[procedure?.sideSurgeon]}
    </>
  );

  useEffect(() => {
    dispatchGlobalState({ type: 'SET_isModelViewOpened', payload: true });
    setAmbientLightIntensity(DEFAULT_AMBIENT_LIGHT_INTENSITY);
    restoreLights();

    return () => {
      dispatchGlobalState({ type: 'SET_isModelViewOpened', payload: false });
    };
  }, []);

  useEffect(() => {
    persistLights();
  }, [ambientLightIntensity, spotLightIntensity, spotX, spotY, spotZ]);

  return (
    <SC.Overlay>
      <SC.Container>
        <Panel title={title} handleCloseIcon={handleClose}>
          <SC.ModelView>
            <Slider
              orientation="vertical"
              value={ambientLightIntensity}
              onChange={handleChangeAmbientLightIntensity}
              sx={{ maxHeight: '350px' }}
              title="Set ambient light intensity"
            />
            <SC.Palette>
              {colorKeys.map(index => (
                <SC.ColorSample
                  key={index}
                  sx={{
                    backgroundColor: SC.colors[index],
                    opacity: 0.8,
                    border:
                      String(index) === String(color)
                        ? `2px solid red`
                        : '2px solid transparent',
                    // '&:hover': {
                    //   backgroundColor: colors[colorName][700],
                    // },
                  }}
                  onClick={() => assingColorName(index)}
                  title="Set model color"
                />
              ))}
            </SC.Palette>
            <SC.Holder
              style={{
                background: '#000',
              }}
            >
              <StlViewer
                url={url}
                style={style}
                modelProps={{
                  color: SC.colors[color],
                }}
                onFinishLoading={() => onFinishLoading()}
                onError={() => onErrorLoading()}
                orbitControls
                ambientLightIntensity={ambientLightIntensity}
                spotLightIntensity={spotLightIntensity}
                spotLightPosition={[spotX, spotY, spotZ]}
                resetCamera={resetCamera}
                extraHeaders={extraHeaders}
              />
            </SC.Holder>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              gap={0.5}
              sx={{ height: '100%', maxHeight: '500px' }}
            >
              <Stack flex={1} justifyContent="center" alignItems="center">
                <Box textAlign="center">Spot Light Intensity</Box>
                <SC.SpotSlider
                  orientation="vertical"
                  value={spotLightIntensity}
                  onChange={handleChangeSpotLightIntensity}
                  title="Set spot light intensity"
                />
              </Stack>
              <Stack flex={1}>
                <Box
                  textAlign="center"
                  sx={{ paddingTop: 0.5, borderTop: 'thin solid lightgray' }}
                >
                  Position
                </Box>
                <Stack
                  flex={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  title="Set spot light position"
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <SC.SpotSlider
                      orientation="vertical"
                      value={spotX}
                      onChange={getChangeSpotLightPositionHandler(setSpotX)}
                    />
                    X
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <SC.SpotSlider
                      orientation="vertical"
                      value={spotY}
                      onChange={getChangeSpotLightPositionHandler(setSpotY)}
                    />
                    Y
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <SC.SpotSlider
                      orientation="vertical"
                      value={spotZ}
                      onChange={getChangeSpotLightPositionHandler(setSpotZ)}
                    />
                    Z
                  </Stack>
                </Stack>
              </Stack>
              <SC.ResetButton variant="outlined" onClick={resetLights}>
                Reset Spot Light
              </SC.ResetButton>
              <SC.ResetButton
                variant="outlined"
                onClick={() => setResetCamera(!resetCamera)}
                labelStyle={{ fontSize: '10px' }}
              >
                Reset Rotation
              </SC.ResetButton>
            </Stack>
          </SC.ModelView>
          <SC.Info>
            <SC.Section>
              <SC.Header>Anatomic Angles</SC.Header>
              <SC.Inclination>
                <div>Inclination:</div>
                <div>{procedure?.anatomicInclination ?? '-- '}°</div>
              </SC.Inclination>
              <SC.Anteversion>
                <div>Anteversion:</div>
                <div>{procedure?.anatomicAnteVersion ?? '-- '}°</div>
              </SC.Anteversion>
            </SC.Section>
            <SC.Section>
              <SC.Header>Planned Angles</SC.Header>
              <SC.Inclination>
                <div>Inclination:</div>
                <div>{procedure?.plannedInclination ?? '-- '}°</div>
              </SC.Inclination>
              <SC.Anteversion>
                <div>Anteversion:</div>
                <div>{procedure?.plannedAnteVersion ?? '-- '}°</div>
              </SC.Anteversion>
            </SC.Section>
            <SC.Section>
              <SC.Header>PRI</SC.Header>
              <SC.Position>
                <div>Size:</div>
                <div>{procedure?.size ?? '-- '} mm</div>
              </SC.Position>
              <SC.Position>
                <div>Position (S,A,P): </div>
                <div>
                  {procedure?.superior ?? '--'},&nbsp;
                  {procedure?.anterior ?? '--'},&nbsp;
                  {procedure?.posterior ?? '--'}
                </div>
              </SC.Position>
            </SC.Section>
          </SC.Info>
        </Panel>
      </SC.Container>
    </SC.Overlay>
  );
};
