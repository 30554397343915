/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useGlobalState } from 'store/GlobalStateContext';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordChangeForAdmin } from 'models/yupSchemas';
import * as FP from 'utils/fp-js';

export const usePasswordChanger = ({
  closeWindow,
  userId,
  savePassword,
  showSpinner,
  hideSpinner,
  showError,
  hideError,
}) => {
  const history = useHistory();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [globalState, dispatchGlobalState] = useGlobalState();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    watch,
  } = useForm({
    resolver: yupResolver(passwordChangeForAdmin),
  });

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');

  const password = useRef();
  password.current = watch('password', '');

  const handleContainerClick = e => {
    const elem = e?.target;
    FP.match(elem?.tagName?.toLowerCase())
      .on(['div', 'path', 'svg', 'input'], () => e.stopPropagation())
      .on('button', () => {
        if (elem?.parentNode?.id === 'control') return;
        e.stopPropagation();
      })
      .otherwise(() => null);
  };

  const onSubmit = async ({ password }) => {
    hideError();
    showSpinner();

    if (token) {
      const preparedData = {
        userId: userId ?? globalState?.user?.value?.userId,
        newPassword: password,
        token,
      };

      const result = await savePassword(preparedData);

      if (result?.error) {
        showError(`Change password failed. ${result.error}`);
      }

      closeWindow();
      history.push('/');
      dispatchGlobalState({ type: 'LOGOUT' });

      hideSpinner();
      return;
    }

    closeWindow();

    const preparedData = {
      userId,
      newPassword: password,
    };

    const result = await savePassword(preparedData);

    if (result?.error) {
      showError(`Change password failed. ${result.error}`);
    }

    hideSpinner();
  };

  return {
    isPasswordVisible,
    setIsPasswordVisible,
    handleContainerClick,
    handleSubmit,
    onSubmit,
    control,
    password,
    isDirty,
  };
};
