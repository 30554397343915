import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { TabContent } from './AppTabContent.sc';

const NotImplemented = () => <>Not Implemented</>;

export const AppTabContent = ({ config, privateRoute }) => {
  return (
    <TabContent>
      <Switch>
        {config.map(({ path, component }) => (
          <Route path={path} component={component ?? NotImplemented} key="1" />
        ))}
        {privateRoute.map(({ path, component }) => (
          <Route path={path} component={component ?? NotImplemented} key="1" />
        ))}
        <Redirect to="/procedures" />
      </Switch>
    </TabContent>
  );
};
