/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, TextField } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

export const FormDateSelector = ({
  label,
  name,
  control,
  rules = {},
  defaultValue,
  size = 'small',
  inputFormat = 'MM/dd/yyyy',
  onSelect = () => undefined,
  disabled = false,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue ?? ''}
      render={({
        field: { onChange, onBlur, ref, value, ...inputProps },
        fieldState: { error },
      }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label={label}
            value={value ?? ''}
            onChange={e => {
              onSelect(e);
              onChange(e);
            }}
            onBlur={onBlur}
            inputRef={ref}
            inputFormat={inputFormat}
            renderInput={params => (
              <FormControl fullWidth error={!!error}>
                <TextField
                  value={value ?? ''}
                  {...params}
                  size={size}
                  error={!!error}
                  helperText={error?.message}
                  {...props}
                />
              </FormControl>
            )}
            error={error}
            {...inputProps}
            disableOpenPicker={disabled}
            disabled={disabled}
          />
        </LocalizationProvider>
      )}
    />
  );
};
