/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React from 'react';
import { Link } from 'react-router-dom';
import * as UI from 'UI';
import { convertToLocaleDate, convertToLocaleTime } from 'utils/dateTimeUtils';
import * as SC from './Message.sc';

export const Message = ({
  senderName,
  text,
  creation,
  isAnswer,
  isRead,
  isSuggested,
  procedure,
}) => {
  return (
    <SC.Container isSuggested={isSuggested}>
      {isAnswer ? (
        <>
          <SC.MyMessage>
            {procedure?.id && (
              <Link to={`/procedure/${procedure?.id}`}>
                <SC.ProcedureTag>
                  {procedure?.patient?.nameFirst}
                  &nbsp;
                  {procedure?.patient?.nameSecond}
                  <br />
                  Proc #{procedure?.id}
                </SC.ProcedureTag>
              </Link>
            )}
            <SC.LinesHolder>
              {text.split('\n').map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SC.Line key={`${row} ${index}`}>{row}</SC.Line>
              ))}
            </SC.LinesHolder>
          </SC.MyMessage>
          <SC.AnswerTime>
            {convertToLocaleTime(creation)} {convertToLocaleDate(creation)}
            {isRead ? (
              <SC.IsRead>&nbsp;&#10004;</SC.IsRead>
            ) : (
              <SC.IsNotRead> ...</SC.IsNotRead>
            )}
          </SC.AnswerTime>
        </>
      ) : (
        <>
          <SC.Sender>{senderName}</SC.Sender>
          <SC.Message>
            <SC.FlexRow style={{ justifyContent: 'flex-start' }}>
              {isSuggested && (
                <>
                  <SC.IconHolder>
                    <SC.NewLabel>New</SC.NewLabel>
                  </SC.IconHolder>
                  <UI.EmptySpace />
                </>
              )}
              {procedure?.id && (
                <Link to={`/procedure/${procedure?.id}`}>
                  <SC.ProcedureTag>
                    {procedure?.patient?.nameFirst}
                    &nbsp;
                    {procedure?.patient?.nameSecond}
                    <br />
                    Proc #{procedure?.id}
                  </SC.ProcedureTag>
                </Link>
              )}
            </SC.FlexRow>
            <SC.LinesHolder>
              {text.split('\n').map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SC.Line key={`${row} ${index}`}>{row}</SC.Line>
              ))}
            </SC.LinesHolder>
          </SC.Message>
          <SC.Time>
            {convertToLocaleTime(creation)} {convertToLocaleDate(creation)}
          </SC.Time>
        </>
      )}
    </SC.Container>
  );
};
