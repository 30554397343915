/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Button, TextField, Box } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import * as UI from 'UI';
import {
  SideLabelEnum,
  FileTypeEnum,
  ProcedureEnum,
  ProcedureStatusEnum,
  RoleEnum,
} from 'models/constants';
import { required } from 'models/validations';
import { useSurgery } from 'components/Procedure/Surgery/useSurgery';
import { ListOfFiles } from '../../ListOfFiles/ListOfFiles';

export const SurgeonInfo = ({ hookData }) => {
  const { handleSurgeonStatusChange } = useSurgery({ hookData });
  const { push } = useHistory();
  const {
    id,
    getUserNameById,
    activeRadiologyLocationListOptions,
    reloadProcedureFiles,
    watch,
    control,
    isUpdating,
    dispatchGlobalState,
    handleUpdateUserIdOnUpload,
    isNorCurrenSurgeon,
    getAllowedStatusListOptions,
    globalState,
  } = hookData;

  return (
    <Stack direction="column" width="100%" spacing={3}>
      <Stack direction="column" width="100%" spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          width="100%"
          spacing={2}
        >
          <Box style={{ width: '200px' }}>
            <UI.FormStatusSelector
              label="Status"
              name="status"
              control={control}
              rules={{ required }}
              options={getAllowedStatusListOptions(ProcedureStatusEnum)}
              disabled={isUpdating || isNorCurrenSurgeon || watch()?.deleted}
              style={{ width: '200px' }}
              handleChange={e =>
                handleSurgeonStatusChange(
                  e,
                  watch,
                  RoleEnum.MASTER_ADMIN === globalState.user.value.role
                )
              }
            />
          </Box>
          <TextField
            label="Created"
            size="small"
            InputProps={{
              value: watch()?.creation ?? '',
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            disabled
          />
        </Stack>
        <Stack direction="row" width="100%" spacing={2}>
          <UI.FormSelectField
            label="Procedure"
            name="name"
            control={control}
            options={ProcedureEnum}
            disabled={isUpdating}
          />
          <Box style={{ width: '100px' }}>
            <UI.FormSelectField
              label="Side"
              name="sideSurgeon"
              control={control}
              rules={{ required }}
              options={SideLabelEnum}
              disabled={isUpdating}
              style={{ width: '100px' }}
            />
          </Box>
        </Stack>
      </Stack>
      <UI.CtProtocolInfo />
      <Stack direction="column" width="100%" spacing={2}>
        <Stack direction="row" alignItems="center" width="100%" spacing={2}>
          <UI.FormSelectField
            label="Radiology Location"
            name="radiologyLocationId"
            control={control}
            options={activeRadiologyLocationListOptions}
            disabled={isUpdating}
          />
          {watch()?.radiologistId && (
            <>
              <TextField
                size="small"
                label="Radiologist"
                readOnly
                value={getUserNameById(watch()?.radiologistId)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ flex: '1 1 auto' }}
                variant="filled"
                disabled
              />
              {/* <Button
                variant="outlined"
                title="Send message to Radiologist"
                onClick={() => {
                  dispatchGlobalState({
                    type: 'SET_newMessageReceiverId',
                    payload: watch()?.radiologistId ?? '',
                  });
                  dispatchGlobalState({
                    type: 'SET_newMessageLocationId',
                    payload: watch()?.radiologyLocationId ?? '',
                  });
                  dispatchGlobalState({
                    type: 'SET_newMessageRole',
                    payload: RoleEnum.RADIOLOGIST,
                  });
                  dispatchGlobalState({
                    type: 'SET_newMessageProcedureId',
                    payload: watch()?.id ?? '',
                  });
                  push('/new_message');
                }}
                type="button"
              >
                <MailOutlineIcon />
              </Button> */}
            </>
          )}
        </Stack>
        <ListOfFiles
          handleReload={reloadProcedureFiles}
          fileType={FileTypeEnum.SCAN}
          procedureId={id}
          readOnly
          showOnlyLastDate
          isUpdating={isUpdating}
          handleUpdateUserIdOnUpload={handleUpdateUserIdOnUpload}
        />
      </Stack>
    </Stack>
  );
};
