/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useGlobalStateUtils } from 'hooks/useGlobalStateUtils';
import { ProcedureStatusEnum } from 'models/constants';
import { useStatusFlow } from 'components/Procedure/StatusFlow/useStatusFlow';

export const useSurgery = ({ hookData }) => {
  const { showAlert } = useGlobalStateUtils();
  const { getStatusChangePermission } = useStatusFlow({ hookData });

  const handleSurgeonStatusChange = (e, watch, isAdmin) => {
    const { status } = watch();
    const newStatus = e.target.value;

    if (newStatus === ProcedureStatusEnum.CANCELED) {
      return;
    }

    const permission = getStatusChangePermission(newStatus);
    if (!permission.enabled) {
      showAlert({
        type: 'warning',
        text: permission.reason,
      });
      e.target.value = status;
    }
  };

  return {
    handleSurgeonStatusChange,
  };
};
