import styled from '@emotion/styled';
import { css } from '@emotion/css';
import * as SC from '../../../SC/App.sc';

export { NewLabel, FlexColumn, FlexRow, EmptySpace } from '../../../SC/App.sc';

export const Container = styled(SC.FlexColumn)`
  flex: 1 1 auto;
  position: relative;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
  label: Сontainer;
`;

export const Header = styled(SC.FlexRow)`
  flex: 0 0 auto;
  margin-bottom: 10px;
  justify-content: flex-start;
  border-bottom: thin solid lightgray;
`;

export const Messages = styled(SC.FlexColumn)`
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
`;

export const ButtonHolder = styled(SC.FlexColumn)`
  flex: 0 0 auto;
  align-items: stretch;
`;

export const Info = styled(SC.FlexColumn)`
  flex: 0 0 auto;
  margin-left: 10px;
  justify-content: center;
  align-items: flex-start;
`;

export const Name = styled(SC.FlexRow)`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
`;

export const Role = styled.div`
  margin: 0;
  font-family: Poppins;

  font-size: 12px;
  line-height: 23px;
`;
export const Data = styled(SC.FlexColumn)`
  flex: 0 0 auto;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  width: 80px;
  height: 100%;
  font-size: 12px;
  label: Data;
`;

export const MessageClass = css`
  background-color: #eee;
`;

export const IconHolder = styled(SC.FlexColumn)`
  justify-content: flex-start;
  margin-left: 30px;
  height: 30px;
`;

export const NewLabelForChat = styled(SC.NewLabel)`
  top: -10px;
  left: -10px;
  width: 26px;
  height: 26px;
  font-size: 1.3em;
`;
