/* eslint-disable no-console */
/* eslint-disable no-shadow */
import { useState, useEffect, useRef, useMemo } from 'react';

import { useMessagesApi } from 'hooks/useMessagesApi';
import { purgeNullProps } from 'utils/purgeNullProps';
import { sortRowsByIdDesc } from 'utils/sortRowsByIdDesc';
import { getRecordById } from 'utils/getRecordById';
import { convertToLocaleTime, convertToLocaleDate } from 'utils/dateTimeUtils';

export const useChats = () => {
  const chatListRef = useRef();
  const {
    globalState,
    isUpdating,
    error,
    handleCreateData,
    reloadSentMessages,
  } = useMessagesApi();
  const [selectedChatUserId, setSelectedChatUserId] = useState(0);
  const [message, setMessage] = useState('');
  const { user, users, messages, sentMessages } = globalState;

  const messageList = useMemo(
    () => sortRowsByIdDesc(messages?.value),
    [messages]
  );
  const sentMessageList = useMemo(
    () => sortRowsByIdDesc(sentMessages?.value),
    [sentMessages]
  );

  const allMessages = useMemo(
    () => sortRowsByIdDesc([...messageList, ...sentMessageList]),
    [messages, sentMessages]
  );

  const selectedChatUser = useMemo(
    () => getRecordById(selectedChatUserId, users?.value),
    [selectedChatUserId, users]
  );

  const receiver = useMemo(
    () => getRecordById(user?.value?.userId, users?.value),
    [user, users]
  );

  const currentChats = useMemo(
    () =>
      allMessages
        .reduce((acc, currentMessage) => {
          if (currentMessage.senderId === globalState?.user?.value?.userId) {
            // sent message
            return acc.includes(currentMessage.receiverId)
              ? acc
              : [...acc, currentMessage.receiverId];
          }
          if (currentMessage.receiverId === globalState?.user?.value?.userId) {
            // received message
            return acc.includes(currentMessage.senderId)
              ? acc
              : [...acc, currentMessage.senderId];
          }
          return acc;
        }, [])
        .map(id => {
          const currentUser = getRecordById(id, users?.value);
          const senderMessages = sortRowsByIdDesc([
            ...messageList,
            ...sentMessageList,
          ]).filter(row => row.senderId === id || row.receiverId === id);
          const newestMessage = senderMessages[0];
          const numberOfNewMessages = senderMessages.reduce(
            (acc, next) =>
              !next.isRead && next.senderId === id ? acc + 1 : acc,
            0
          );

          return {
            senderId: id,
            name: `${currentUser?.nameFirst} ${currentUser?.nameSecond}`,
            role: currentUser?.role,
            creation: newestMessage?.creation,
            date: convertToLocaleDate(newestMessage?.creation),
            time: convertToLocaleTime(newestMessage?.creation),
            text: newestMessage?.text,
            numberOfNewMessages,
          };
        })
        .filter(chat => chat.senderId !== user?.value?.userId) // user can't create a chat with herself
        .sort((a, b) => (a.creation > b.creation ? -1 : 1))
        .sort((a, b) =>
          a.numberOfNewMessages > b.numberOfNewMessages ? -1 : 1
        ),
    [user, users, messages, sentMessages]
  );

  const onSubmit = async ({ procedureId, receiverId, text }) => {
    const requestData = purgeNullProps({
      procedureId,
      receiverId,
      text,
      senderId: globalState?.user?.value?.userId,
    });
    await handleCreateData(requestData);
  };

  const handleClickChatInfo = ({ senderId, selected }) => {
    if (!selected) {
      setSelectedChatUserId(senderId);
    }
  };

  useEffect(() => {
    if (currentChats?.length > 0 && selectedChatUserId === 0) {
      // open newest chat
      setSelectedChatUserId(currentChats[0].senderId);
    }
    return () => {
      setSelectedChatUserId(0);
    };
  }, []);

  useEffect(() => {
    if (
      currentChats?.length &&
      currentChats[0]?.senderId === selectedChatUserId
    ) {
      // scroll chat list to top
      chatListRef?.current?.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    document.title = 'ArthroSight - Chats';
  }, []);

  return {
    messageList,
    sentMessageList,
    allMessages,
    reloadSentMessages,
    currentChats,
    isUpdating,
    error,
    onSubmit,
    message,
    setMessage,
    selectedChatUserId,
    selectedChatUser,
    receiver,
    handleClickChatInfo,
    chatListRef,
  };
};
