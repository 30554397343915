import React from 'react';
import { Stack, Button, IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import * as UI from 'UI';
import { RoleLabelEnum } from 'models/constants';
import { required } from 'models/validations';
import { useSendMessage } from './useSendMessage';
import * as SC from './SendMessage.sc';

export const SendMessage = () => {
  const {
    isUpdating,
    error,
    control,
    handleSubmit,
    resetForm,
    onSubmit,
    userListOptions,
    handleChangeRole,
    locationListOptions,
    handleChangeLocation,
    getValues,
    goBack,
    isDirty,
    userSelectorRef,
    resetUserFilters,
    handleChangeProcedure,
    resetProcedure,
    procedureListOptions,
  } = useSendMessage();

  const isResetFilterDisabled = !getValues()?.role && !getValues()?.location;

  return (
    <SC.Container>
      <UI.ErrorBox text={error} width="calc(100% - 32px)" />
      {isUpdating && <UI.GlobalSpinner />}
      <SC.Wrapper>
        <UI.Panel title="Compose new message">
          <SC.Form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction="column"
              justifyContent="space-between"
              width="100%"
              height="100%"
              spacing={3}
            >
              <Stack
                direction="column"
                alignItems="center"
                width="100%"
                spacing={1}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  spacing={2}
                >
                  <SC.Label alignSelf="flex-end">To:</SC.Label>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="70%"
                    spacing={2}
                    className={
                      isResetFilterDisabled ? 'filter' : 'filter active'
                    }
                  >
                    <SC.FilterLabel>Filter users:</SC.FilterLabel>
                    <UI.FormSelectField
                      label="by role"
                      name="role"
                      control={control}
                      options={RoleLabelEnum}
                      disabled={isUpdating}
                      onChange={handleChangeRole}
                    />
                    <UI.FormSelectField
                      label="by location"
                      name="location"
                      control={control}
                      options={locationListOptions}
                      onChange={handleChangeLocation}
                    />
                    <IconButton
                      disabled={isResetFilterDisabled}
                      color={isResetFilterDisabled ? 'primary' : 'warning'}
                      title="Reset filters"
                      onClick={resetUserFilters}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Stack>
                </Stack>
                <Stack direction="row" width="100%" spacing={1}>
                  <UI.FormSelectField
                    inputRef={userSelectorRef}
                    label="Recipient"
                    name="receiverId"
                    control={control}
                    options={userListOptions}
                    disabled={isUpdating}
                    rules={{ required }}
                    size="large"
                  />
                </Stack>
              </Stack>
              <Stack
                margin={0}
                direction="column"
                width="100%"
                spacing={4}
                flex="1"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  width="100%"
                  spacing={2}
                  className={
                    getValues()?.procedureId
                      ? 'highlighted active'
                      : 'highlighted'
                  }
                  flex="0"
                >
                  <SC.ProcedureTitle>Concerning:</SC.ProcedureTitle>
                  <UI.FormSelectField
                    label="Patient, Procedure"
                    name="procedureId"
                    control={control}
                    options={procedureListOptions}
                    disabled={isUpdating}
                    onChange={handleChangeProcedure}
                  />
                  <IconButton
                    disabled={!getValues()?.procedureId}
                    color={!getValues()?.procedureId ? 'primary' : 'warning'}
                    title="Remove procedure reference"
                    onClick={resetProcedure}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Stack>
                <UI.FormTextField
                  label="New message text"
                  name="text"
                  control={control}
                  multiline
                  disabled={isUpdating}
                  rules={{ required }}
                  inputProps={{
                    maxLength: 255,
                    style: {
                      height: '130px',
                    },
                  }}
                  sx={{ flex: 1 }}
                />
                <SC.Buttons>
                  {!isDirty && (
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={goBack}
                    >
                      Back to previous page
                    </Button>
                  )}
                  {isDirty && (
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => resetForm()}
                    >
                      Discard Message
                    </Button>
                  )}
                  &nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isUpdating || getValues()?.text === ''}
                  >
                    Send message
                  </Button>
                </SC.Buttons>
              </Stack>
            </Stack>
          </SC.Form>
        </UI.Panel>
      </SC.Wrapper>
    </SC.Container>
  );
};
