/* eslint-disable no-nested-ternary */
import React from 'react';
import * as FP from 'utils/fp-js';
import { Stepper, Step } from '@mui/material';
import {
  ProcedureStatusLabelEnum,
  ProcedureStatusEnum,
} from 'models/constants';
import { useStatusFlow } from './useStatusFlow';

import * as SC from './StatusFlow.sc';

export const StatusFlow = ({ hookData }) => {
  const {
    steps,
    isProcedureDeleted,
    currentIndex,
    readOnlyStatusFlow,
    enabledStatuses,
    handleClickStatus,
    statuses,
    currentStatus,
    helperText,
    isConfirmVisible,
  } = useStatusFlow({ hookData });

  return (
    <SC.Container elevation={5}>
      {FP.match(true)
        .on(
          ProcedureStatusEnum.CANCELED === currentStatus,
          <Stepper alternativeLabel>
            <Step>
              <SC.CanceledStepLabel StepIconComponent={SC.CancelIcon}>
                <SC.CanceledLabel>
                  {ProcedureStatusLabelEnum[currentStatus]}
                </SC.CanceledLabel>
              </SC.CanceledStepLabel>
            </Step>
          </Stepper>
        )
        .on(
          isProcedureDeleted,
          <Stepper alternativeLabel>
            <Step>
              <SC.CanceledStepLabel StepIconComponent={SC.CancelIcon}>
                <SC.CanceledLabel>Procedure Deleted</SC.CanceledLabel>
              </SC.CanceledStepLabel>
            </Step>
          </Stepper>
        )
        .otherwise(
          <Stepper activeStep={currentIndex + 1} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                {FP.match(true)
                  .on(
                    index < currentIndex,
                    FP.match(true)
                      .on(
                        enabledStatuses.includes(statuses[index]) &&
                          !readOnlyStatusFlow,
                        <SC.DoneStepLabel
                          StepIconComponent={SC.CheckCircleIcon}
                          title="Click to select status"
                          onClick={() => handleClickStatus(statuses[index])}
                          className="selectable"
                        >
                          <SC.DoneLabel className="selectable">
                            {label}
                          </SC.DoneLabel>
                        </SC.DoneStepLabel>
                      )
                      .otherwise(
                        <SC.DisabledStepLabel
                          StepIconComponent={SC.CheckCircleIcon}
                          title="Status disabled"
                        >
                          <SC.DisabledLabel>{label}</SC.DisabledLabel>
                        </SC.DisabledStepLabel>
                      )
                  )
                  .on(index && index === currentIndex + 1, () =>
                    helperText && !readOnlyStatusFlow ? (
                      <SC.NextStepLabel
                        StepIconComponent={SC.ArrowCircleRightIcon}
                        onClick={() => handleClickStatus(statuses[index])}
                      >
                        <SC.StatusTooltip
                          open={!!helperText && !isConfirmVisible}
                          title={helperText}
                          arrow
                          placement="bottom"
                        >
                          <SC.NextLabel>{label}</SC.NextLabel>
                        </SC.StatusTooltip>
                      </SC.NextStepLabel>
                    ) : !enabledStatuses.includes(statuses[index]) ||
                      readOnlyStatusFlow ? (
                      <SC.DisabledStepLabel
                        StepIconComponent={SC.NextIcon}
                        title="Status disabled"
                      >
                        <SC.DisabledLabel>{label}</SC.DisabledLabel>
                      </SC.DisabledStepLabel>
                    ) : (
                      <SC.NextStepLabel
                        StepIconComponent={SC.NextIcon}
                        title="Click to select status"
                        onClick={() => handleClickStatus(statuses[index])}
                      >
                        <SC.NextLabel>{label}</SC.NextLabel>
                      </SC.NextStepLabel>
                    )
                  )
                  .on(
                    index > currentIndex + 1,
                    FP.match(true)
                      .on(
                        enabledStatuses.includes(statuses[index]) &&
                          readOnlyStatusFlow,
                        <SC.DisabledStepLabel
                          StepIconComponent={SC.NextIcon}
                          title="Status disabled"
                        >
                          <SC.DisabledLabel>{label}</SC.DisabledLabel>
                        </SC.DisabledStepLabel>
                      )
                      .otherwise(
                        <SC.DisabledStepLabel
                          StepIconComponent={SC.NextIcon}
                          title="Status disabled"
                        >
                          <SC.DisabledLabel>{label}</SC.DisabledLabel>
                        </SC.DisabledStepLabel>
                      )
                  )
                  .otherwise(
                    currentStatus ? (
                      <SC.ActiveStepLabel
                        status={currentStatus}
                        StepIconComponent={SC.PendingIcon}
                      >
                        <SC.Label status={currentStatus}>{label}</SC.Label>
                      </SC.ActiveStepLabel>
                    ) : (
                      <SC.DisabledStepLabel
                        StepIconComponent={SC.NextIcon}
                        title="Status disabled"
                      >
                        <SC.DisabledLabel>{label}</SC.DisabledLabel>
                      </SC.DisabledStepLabel>
                    )
                  )}
              </Step>
            ))}
          </Stepper>
        )}
    </SC.Container>
  );
};
