export const encodeIdData = value => {
  if (!value || value === '') {
    return -1;
  }
  return Number(value);
};

export const decodeIdData = value => {
  if (!value || String(value) === '-1') {
    return '';
  }
  return value;
};
