import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  min-width: 1024px;
  height: 100%;
  overflow: hidden;
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: ${({ theme }) => theme.sidebar.width};
  height: 100%;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.sidebar.backgroundColor};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Menu = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const RightPanel = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100vw - ${({ theme }) => theme.sidebar.width});
  overflow: hidden;
`;

export const AppSidebar = styled(Box)`
  flex: 0 0 auto;
  width: ${({ theme }) => theme.sidebar.width};

  & .MuiButtonBase-root {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.white} !important;
    background-color: transparent !important;
    height: 42px !important;
  }
  & .Mui-selected {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.orange} !important;
    background-color: transparent !important;
    height: 42px !important;
  }
`;

export const Tabs = styled(Box)`
  margin: 15px;
  width: calc(100% -30px);
  height: 100%;
`;

export const Tab = styled(Box)`
  margin-bottom: 15px;
  align-items: flex-start;
  text-transform: unset;
`;
