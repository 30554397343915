/* eslint-disable max-lines-per-function */
import React, { useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { Input } from '@mui/material';
import { useGlobalState } from 'store/GlobalStateContext';
import { useGlobalStateUtils } from 'hooks/useGlobalStateUtils';
import filesApi from 'api/S3Files';
import { localStorageNameEnum, fileTypeLabelEnum } from 'models/constants';

export const useListOfFiles = (
  files,
  handleReload,
  fileType,
  procedureId,
  handleUpdateUserIdOnUpload
) => {
  const cancelSignal = useRef(null);
  const cancelAxiosToken = useRef(null);
  const cancelAxiosSource = useRef(null);

  const ref = useRef();
  const [globalState] = useGlobalState();
  const { user } = globalState;
  const list = Array.isArray(files)
    ? files.filter(file => file?.type === fileType && !file?.deleted)
    : [];
  const {
    showAlert,
    showError,
    hideError,
    showSpinner,
    hideSpinner,
    showConfirm,
  } = useGlobalStateUtils();
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const [isStlLoaderVisible, setIsStlLoaderVisible] = useState(false);
  const [isStlLoadingCanceled, setIsStlLoadingCanceled] = useState(false);
  const [stlLoadingProgress, setStlLoadingProgress] = useState(0);

  const [loadingError, setLoadingError] = useState('');
  const [isUpload, setIsUpload] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState({});

  const [selected, setSelected] = useState([]);
  const [isModelViewerVisible, setIsModelViewerVisible] = useState(false);
  const [modelUrl, setModelUrl] = useState('');
  const [description, setDescription] = useState(fileTypeLabelEnum[fileType]);

  const getFileNameById = id => files.filter(file => file.id === id);

  const handleClickFileItem = useCallback(
    id => {
      if (selected.includes(id)) {
        setSelected([...selected].filter(value => value !== id));
      } else {
        setSelected([...selected, id]);
      }
    },
    [selected]
  );

  const openModelViewer = () => {
    setStlLoadingProgress(0);
    setLoadingError('');
    setIsStlLoadingCanceled(false);

    if (selected.length !== 1) {
      showAlert({
        type: 'warning',
        text: 'Please select only one 3D model file for view',
      });
    } else {
      const id = selected[0];
      setModelUrl(`https://api.arthrosight.net/api/v1/file/download/${id}`);
      setIsModelViewerVisible(true);
      setIsStlLoaderVisible(true);
    }
  };

  const closeModelViewer = () => {
    setIsModelViewerVisible(false);
    setModelUrl();
  };

  const setIndexedLoadingComplete = (value, index, filesNumber) => {
    setLoadingComplete(prevState => {
      const newState = { ...prevState };
      newState[index] = value / filesNumber;
      return newState;
    });
  };

  const handleUpload = event => {
    const input = event?.target;
    const filesForUpload = Object.values(input?.files);
    if (filesForUpload?.length === 0) return;

    hideError();

    showConfirm({
      title: null,
      text: `Add description for ${filesForUpload[0]?.name}:`,
      contentRender: (
        <Input
          type="text"
          defaultValue={description}
          onChange={() => setDescription(ref.current.value)}
          inputRef={ref}
        />
      ),
      yes: async () => {
        setIsUpload(true);
        setLoadingError('');
        setLoadingComplete({ 0: 0 });
        setIsLoaderVisible(true);
        const formData = new FormData();
        formData.append('creatorId', globalState?.user?.value?.userId);
        formData.append('description', ref.current.value);
        formData.append('fileType', fileType);
        filesForUpload.forEach(file => {
          formData.append('files', file, file.name);
        });
        formData.append('procedureId', procedureId);

        cancelAxiosToken.current = axios.CancelToken;
        cancelAxiosSource.current = cancelAxiosToken.current.source();

        const response = await filesApi.upload(
          formData,
          value => setIndexedLoadingComplete(value, 0, 1),
          cancelAxiosSource.current.token,
          axios
        );

        input.value = '';
        setDescription(fileTypeLabelEnum[fileType]);

        if (response?.error) {
          // showError(response.error);
          setLoadingError('Upload error');
          return;
        }

        handleReload();
        handleUpdateUserIdOnUpload();
      },
      no: () => {
        input.value = '';
        setDescription(fileTypeLabelEnum[fileType]);
        handleReload();
        setIsLoaderVisible(false);
      },
    });
  };

  const handleDownload = () => {
    setIsUpload(false);
    setLoadingError('');
    setLoadingComplete({});
    setIsLoaderVisible(true);

    const promises = [];
    const fileNamesWithError = [];
    const idsWithErrors = [];

    const filesNumber = selected.length;

    cancelSignal.current = [];

    selected.forEach((id, index) => {
      const { fileName } = getFileNameById(id)[0];

      cancelSignal.current.push(new AbortController());

      promises.push(
        filesApi
          .download(
            id,
            {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem(
                localStorageNameEnum.AUTH_TOKEN
              )}`,
            },
            value => setIndexedLoadingComplete(value, index, filesNumber),
            cancelSignal.current[index].signal
          )
          .then(blob => {
            const url = window.URL.createObjectURL(blob?.data);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            return Promise.resolve(true);
          })
          .catch(() => {
            fileNamesWithError.push(fileName);
            idsWithErrors.push(id);
            return Promise.reject();
          })
      );
    });

    Promise.all(promises)
      .catch(() => {
        setLoadingError('Download error');
      })
      .finally(() => {
        setSelected(idsWithErrors);
      });
  };

  const handleDelete = useCallback(async () => {
    showSpinner();
    hideError();

    showConfirm({
      title: null,
      text: `Please confirm deletion of ${selected.length} ${
        selected.length > 1 ? `files` : 'file'
      }.`,
      yes: async () => {
        filesApi
          .delete(selected)
          .then(result => {
            if (result?.error) {
              showError(result.error);
              showAlert({
                type: 'error',
                text: 'Something went wrong',
              });
              return;
            }
            showAlert({
              type: 'success',
              text:
                selected.length > 1
                  ? `${selected.length} files were deleted successfully`
                  : 'File was deleted successfully',
            });
            setSelected([]);
          })
          .catch(error => {
            showAlert({
              type: 'error',
              text: error,
            });
          })
          .finally(() => {
            handleReload();
            hideSpinner();
          });
      },
      no: () => {
        hideSpinner();
      },
    });
  }, [selected]);

  return {
    list,
    selected,
    setSelected,
    handleUpload,
    handleDownload,
    handleDelete,
    handleClickFileItem,
    openModelViewer,
    closeModelViewer,
    isModelViewerVisible,
    modelUrl,
    role: user?.value?.role,
    isLoaderVisible,
    setIsLoaderVisible,
    isStlLoaderVisible,
    setIsStlLoaderVisible,
    isStlLoadingCanceled,
    setIsStlLoadingCanceled,
    stlLoadingProgress,
    setStlLoadingProgress,
    loadingComplete,
    loadingError,
    setLoadingError,
    isUpload,
    handleCancelLoading: () => {
      cancelAxiosSource.current?.cancel('Upload canceled by user');

      if (Array.isArray(cancelSignal.current)) {
        cancelSignal.current?.forEach(item => item?.abort());
      }

      setTimeout(() => hideError());
    },
  };
};
