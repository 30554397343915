import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { OrbitControls as StdOrbitControls } from 'three-stdlib';

const DEFAULT_AZIMUTHAL_ANGLE_IN_RADIANS = -0.39269908169872414;
const DEFAULT_POLAR_ANGLE_IN_RADIANS = 1.1780972450961726;

const OrbitControls = props => {
  const { shouldReset } = props;
  const [resetCamera, setResetCamera] = useState(false);
  const camera = useThree(state => state.camera);
  const controls = React.useMemo(() => new StdOrbitControls(camera), [camera]);

  const gl = useThree(state => state.gl);
  const events = useThree(state => state.events);
  const domElement = events.connected ?? gl.domElement;

  useEffect(() => {
    controls.connect(domElement);
    return () => controls.dispose();
  });

  useEffect(() => {
    if (resetCamera !== shouldReset) {
      controls.setAzimuthalAngle(DEFAULT_AZIMUTHAL_ANGLE_IN_RADIANS);
      controls.setPolarAngle(DEFAULT_POLAR_ANGLE_IN_RADIANS);
      setResetCamera(shouldReset);
    }
  }, [shouldReset]);

  useFrame(() => {
    controls.update();
  }, -1);

  // eslint-disable-next-line react/no-unknown-property
  return <primitive object={controls} {...props} />;
};

export default OrbitControls;
