import styled from '@emotion/styled';
import { FlexRow, Container as ContainerCSC } from 'SC/App.sc';

export { Wrapper, Header } from 'SC/App.sc';

export const Container = styled(ContainerCSC)`
  & .user-row {
    cursor: pointer;
  }

  & .user-row:hover {
    background-color: lightgray;
  }
`;

export const UserHolder = styled(FlexRow)`
  gap: 10px;
`;
